import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import PatientHeader from '../../components/Headers/PatientHeader';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import StyledTabs from '../../components/StyledTabs';
import useCanAccess from '../../hooks/useCanAccess';
import History from './History';
import EventHistory from './CareHistory/';
import Reports from './Reports';
import Shift from './Shift';
import { ButtonContainer, Container, StyledTabItem } from './styles';
import { CareHistoryFilterProvider } from '../../contexts/CareHistoryFilter';

const Follow: React.FC<RouteComponentProps<{ tabId?: string }>> = (props) => {
  const currentTab = props.match.params.tabId;
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.push(`/acompanhar/${newValue}`);
  };
  const { isAllowedToRead: isAllowedToReadShift } = useCanAccess('care/shift');
  const { isAllowedToRead: isAllowedToReadShiftTab } = useCanAccess(
    'care/shift/event'
  );
  const {
    isAllowedToRead: isAllowedToReadReport,
    isAllowedToCreate: isAllowedToCreateReport,
  } = useCanAccess('report/shift');

  const { isAllowedToRead: isAllowedToReadEvolutionTab } = useCanAccess(
    'care/shift/event'
  );

  const { isAllowedToRead: isAllowedToReadPatientReport } = useCanAccess(
    'report/patient'
  );
  const { isAllowedToRead: isAllowedToReadProfessionalReport } = useCanAccess(
    'report/professional'
  );

  const isHistoricTabDisabled =
    !isAllowedToReadShift && !isAllowedToReadReport && !isAllowedToCreateReport;

  const isReportTabDisabled =
    !isAllowedToReadPatientReport && !isAllowedToReadProfessionalReport;

  const tabOptions = [
    { key: 'plantao', label: 'Plantão', disabled: !isAllowedToReadShiftTab },
    { key: 'historico', label: 'Histórico', disabled: isHistoricTabDisabled },
    {
      key: 'evolucao',
      label: 'Evolução',
      disabled: !isAllowedToReadEvolutionTab,
    },
    { key: 'relatorios', label: 'Relatórios', disabled: isReportTabDisabled },
  ];

  if (!currentTab) {
    const firstTab = tabOptions.find((tab) => !tab.disabled);
    return <Redirect to={`/acompanhar/${firstTab?.key}`} />;
  }

  return (
    <>
      <PatientHeader />
      <PendingRegistrationBanner />
      <Container>
        <ButtonContainer>
          <StyledTabs
            variant="scrollable"
            value={currentTab}
            onChange={handleChange}
          >
            {tabOptions.map(({ key, label, disabled }) =>
              disabled ? null : (
                <StyledTabItem key={key} value={key} label={label} />
              )
            )}
          </StyledTabs>
        </ButtonContainer>
        {currentTab === 'plantao' && <Shift />}
        {currentTab === 'historico' && <History />}
        {currentTab === 'relatorios' && <Reports />}

        {currentTab === 'evolucao' && (
          <CareHistoryFilterProvider>
            <EventHistory />
          </CareHistoryFilterProvider>
        )}
      </Container>
    </>
  );
};

export default Follow;
