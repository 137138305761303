/**
 * https://medium.com/trainingcenter/mascara-de-cpf-com-react-javascript-a07719345c93
 * Will be of type ddd.ddd.ddd-dd
 */
export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

/**
 * https://medium.com/reactbrasil/m%C3%A1scara-de-cnpj-com-react-regex-bafb58d2285e
 * Will be of type dd.ddd.ddd/dddd-dd
 */
export const cnpjMask = (value: string) => {
  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
};

export const numberMask = (value: string) => {
  return value.replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada
};

export const floatMask = (value: string) => {
  return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); // substitui qualquer caracter que nao seja numero ou ponto por nada
};

// Will be of type ddddd-ddd
export const cepMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d{1,3})/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

// Will be of type (dd) ddddd-dddd* or (dd) ddddd-dddd*
// Or type 0800 ddd dddd
// Warning: If first 2 digits are "55", they will be removed (brazil international code)
export const phoneMask = (value: string) => {
  let numberOnly = value.replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada

  // Removes "55" if present
  if (value.startsWith('55') && value.length > 10) {
    numberOnly = removeDDI(numberOnly);
  }

  // Format 0800 xxx xxxx
  if (value.startsWith('08')) {
    return numberOnly.replace(/(\d{4})(\d{3})(\d{1,4})(\d*)/, '$1 $2 $3');
  } else if (value.length > 4) {
    return numberOnly
      .replace(/(\d{2})(\d{1,5})/, '($1) $2')
      .replace(/(\d{4,5})(\d{4})(\d*)/, '$1-$2');
  }
  return numberOnly;
};

export const removeDDI = (value: string) => {
  return value.slice(2);
};

/**
 * https://www.geradorcpf.com/javascript-validar-cpf.htm
 */
export function validateCpf(rawCpf = '') {
  const cpf = rawCpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

/**
 * https://www.geradorcnpj.com/javascript-validar-cnpj.htm
 */
export function validateCnpj(rawCnpj = '') {
  const cnpj = rawCnpj.replace(/[^\d]+/g, '');
  if (cnpj === '') return false;
  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj.length !== 14 ||
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;
  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1))) return false;
  return true;
}

export const nonAlphanumericString = (value: string) => {
  return value.replace(/[-|.]/g, '');
};

export const validatePasswordStrength = (value: string) => {
  // at least 8 characters
  // at least 1 number
  // at least 1 special character
  // at least 1 letter uppercase and 1 letter lowercase
  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&?.,;/\* -])[\da-zA-Z!@#$%&?.,;/\* -]{8,}/;
  return regex.test(value);
};

// Will be of type "d minutos"
export const durationMask = (value: string) => {
  // Resolve for exception when value is 1
  if (value === '1') {
    return '1 minuto';
  }
  // Resolve for other values
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d*)/, '$1 minutos');
};

export const cardMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/^(\d{4})(\d)/, '$1 $2')
    .replace(/^(\d{4})\s(\d{4})(\d)/, '$1 $2 $3')
    .replace(/(\d{4})\s(\d{4})\s(\d{4})(\d)/, '$1 $2 $3 $4')
    .replace(/(\s\d{7})\d+?$/, '$1'); // captura 7 numeros seguidos de um espaço e não deixa ser digitado mais nada
};

export const expiryMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{2})\d+?$/, '$1');
};

export const cvcMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})\d+?$/, '$1');
};
