import React, { useMemo } from 'react';
import Header from '../../components/Headers/Header';
import { Typography } from '@material-ui/core';
import { ImageWithLeftMargin, MainContainer } from './styles';
import DevelopmentInProgressIcon from '../../assets/development-in-progress.svg';
import { useHistory, useParams } from 'react-router-dom';

type DevelopmentInProgressPageType = 'produtos-de-saude' | 'servicos-de-saude';

export const DevelopmentInProgress: React.FC = () => {
  const params: { page: DevelopmentInProgressPageType } = useParams();
  const history = useHistory();

  const page: DevelopmentInProgressPageType = params.page;

  const title = useMemo(
    () =>
      page === 'produtos-de-saude' ? 'Produtos de saúde' : 'Serviços de saúde',
    [page]
  );

  return (
    <>
      <Header title={title} leftIconClick={() => history.goBack()} />
      <MainContainer>
        <br />
        <ImageWithLeftMargin src={DevelopmentInProgressIcon} />
        <br />
        <Typography>Esta página está em contrução.</Typography>
        <br />
        <br />
        <Typography>
          Em breve você encontrará aqui o acesso fácil a{' '}
          {page === 'produtos-de-saude' ? 'produtos' : 'serviços'} de saúde para
          quando precisar.
        </Typography>
      </MainContainer>
    </>
  );
};
