import React from 'react';
import { ReactComponent as ActiveIcon } from '../../assets/active-icon.svg';
import { ReactComponent as PendingIcon } from '../../assets/pending-icon.svg';
import { ReactComponent as InactiveIcon } from '../../assets/disable-icon.svg';
import { Shift, ShiftExecution } from '@cuidador/database';

export type StatusLabel = 'ATIVA' | 'PENDENTE' | 'INATIVA';

export type StatusColor = 'red' | 'yellow' | 'green' | 'gray';

export const resolvePatientStatusLabel = (
  status: string,
  isNew: number
): StatusLabel => {
  if (status === 'disabled') return 'INATIVA';
  else if (status === 'enabled' && isNew === 1) return 'PENDENTE';
  else return 'ATIVA';
};

export const resolvePatientStatusColor = (status: string): StatusColor => {
  switch (status) {
    case 'ATIVA':
      return 'green';
    case 'PENDENTE':
      return 'yellow';
    default:
      return 'gray';
  }
};

export const resolveShiftStatusLabel = (shifts: Shift[] | undefined) => {
  if (!shifts || shifts.length === 0) return 'Sem plantão';

  if (shifts.some((shift) => shift.status === 'in_progress')) {
    if (!shifts.some((shift) => !!shift.executionInProgress))
      return 'Incompleto';

    return (
      shifts.find((shift) => !!shift.executionInProgress)?.executionInProgress
        ?.caregiver?.user?.name ?? ''
    );
  }

  if (shifts.some((shift) => shift.status === 'available')) return 'Atrasado';

  return '';
};

export const resolveShiftStatus = (shifts: Shift[] | undefined) => {
  if (!shifts || shifts.length === 0) return 'Sem plantão';

  if (shifts.some((shift) => shift.status === 'in_progress')) {
    if (!shifts.some((shift) => !!shift.executionInProgress))
      return 'Incompleto';

    return 'Em andamento';
  }

  if (shifts.some((shift) => shift.status === 'available')) return 'Atrasado';

  return '';
};

export const resolveShiftStatusColor = (
  status: ReturnType<typeof resolveShiftStatusLabel>
) => {
  switch (status) {
    case 'Sem plantão':
      return 'gray';
    case 'Incompleto':
      return 'yellow';
    case 'Atrasado':
      return 'red';
    default:
      return 'green';
  }
};

export const resolveMemberStatusLabel = (status: string): StatusLabel => {
  switch (status) {
    case 'enabled':
      return 'ATIVA';
    case 'awaitingSignupCompletion':
      return 'PENDENTE';
    default:
      return 'INATIVA';
  }
};

export const resolveMemberStatusIcon = (status: string): JSX.Element => {
  switch (resolveMemberStatusLabel(status)) {
    case 'ATIVA':
      return <ActiveIcon data-testid="active" />;
    case 'PENDENTE':
      return <PendingIcon data-testid="pending" />;
    default:
      return <InactiveIcon data-testid="inactive" />;
  }
};

export const getShiftExecutions = (
  shifts: Shift[] | undefined
): ShiftExecution[] | undefined => {
  if (!shifts || shifts.length === 0) return undefined;

  if (shifts.length === 1) return shifts[0].executions;

  const shiftInExecution = shifts.find(
    (shift) => shift.status === 'in_progress' && !!shift.executionInProgress
  );
  if (!!shiftInExecution) return shiftInExecution.executions;

  const incompleteShift = shifts.find(
    (shift) => shift.status === 'in_progress' && !shift.executionInProgress
  );
  if (!!incompleteShift) return incompleteShift.executions;

  return undefined;
};
