import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../components/Headers/Header';
import MeasurementScheduleForm, {
  FormValues,
} from '../../../components/MeasurementScheduleForm';
import { FrequencyType } from '../../../components/MeasurementScheduleForm/DaysSelector';
import { PeriodType } from '../../../components/MeasurementScheduleForm/PeriodSelector';
import { formDataToEventScheduleModel } from '../../../components/MeasurementScheduleForm/utils/parseFormDataToModel';
import { AuthContext } from '../../../contexts/auth';
import useMeasurement from '../../../hooks/useMeasurement';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress } from './styles';

const initialValues: FormValues = {
  measurements: [],
  dayOptions: FrequencyType.EveryDay,
  days: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
  time: '',
  customTimes: [],
  frequencyStartsAt: format(new Date(), 'yyyy-MM-dd'),
  frequencyEndsAt: '',
  periodType: PeriodType.Continuously,
  timeSchedule: '',
};

const Register: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { loading, post } = useMeasurement();

  const patientId = userInfo?.activePatientId;

  const handleSubmit = async (values: FormValues) => {
    if (!patientId) return;
    const formattedData = formDataToEventScheduleModel(values, patientId);
    return post(formattedData)
      .then(() => {
        toast.success('Medição adicionada com sucesso!');
        history.push('/controles');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        title="Adicionar Controle"
        leftIconClick={() => history.goBack()}
        rightButtonType={params.id ? 'remove' : 'pscList'}
      />
      {loading ? (
        <Backdrop open={loading}>
          {loading && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
      ) : (
        <MeasurementScheduleForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default Register;
