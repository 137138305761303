import React, { useContext, useEffect } from 'react';
import HeaderWithTitle from '../../components/Headers/HeaderWithTitle';
import MedicBalloon from '../../components/MedicBalloon';
import { AuthContext } from '../../contexts/auth';
import { Container } from './styles';

const ProcessingPayment: React.FC = () => {
  const REFRESH_INTERVAL_IN_SECONDS = 15;
  const { refreshUserInfo } = useContext(AuthContext);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshUserInfo();
    }, REFRESH_INTERVAL_IN_SECONDS * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <HeaderWithTitle title="Processando Pagamento" />
      <Container>
        <MedicBalloon text="Estamos processando o seu pagamento, você será redirecionado(a) automaticamente assim que o pagamento for aprovado." />
      </Container>
    </>
  );
};

export default ProcessingPayment;
