import React from 'react';
import { StatusMessageContainer, StatusMessageIconContainer } from './styles';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { getStatusMessage, NounGender } from './utils';

export function renderStatusMessage(
  registeredItemsQuantity: number,
  titleGender: NounGender,
  statusTitleSingular?: string,
  statusTitlePlural?: string
) {
  const iconColor = registeredItemsQuantity > 0 ? 'green' : 'red';
  const icon =
    registeredItemsQuantity > 0 ? (
      <DoneIcon fontSize="inherit" />
    ) : (
      <ClearIcon fontSize="inherit" />
    );

  const statusMessage = getStatusMessage(
    registeredItemsQuantity,
    statusTitleSingular,
    statusTitlePlural,
    titleGender
  );

  return (
    <StatusMessageContainer>
      <StatusMessageIconContainer iconColor={iconColor}>
        {icon}
      </StatusMessageIconContainer>
      {statusMessage}
    </StatusMessageContainer>
  );
}
