import { Shift } from '@cuidador/database';
import React, { useState } from 'react';
import {
  AvatarContainer,
  BorderedAvatar,
  CardBox,
  PatientOverview,
  StatusIndicator,
  PatientName,
  UserInfoContainer,
  ClampedText,
} from '../styles';
import {
  resolvePatientStatusColor,
  resolvePatientStatusLabel,
  resolveShiftStatusColor,
  resolveShiftStatusLabel,
} from '../utils';
import ExpandedContent from './ExpandedContent';
import { ExpandButton, Row, StyledArrow } from './styles';

export type PatientSelectCardProps = {
  id: number;
  name: string;
  age: number;
  profilePictureURL?: string;
  status: string;
  isNew: number;
  shifts?: Shift[];
  active?: boolean;
  handleClick?: (id: number) => void;
};

const PatientSelectCard: React.FC<PatientSelectCardProps> = ({
  id,
  name,
  age,
  profilePictureURL,
  status,
  isNew,
  active,
  shifts,
  handleClick,
}: PatientSelectCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsExpanded((state) => !state);
  };

  const patientStatusLabel = resolvePatientStatusLabel(status, isNew);
  const shiftStatusLabel = resolveShiftStatusLabel(shifts);

  return (
    <CardBox open={isExpanded}>
      <Row data-testid={`patient-select-card-${id}`}>
        <PatientOverview
          onClick={() => {
            if (handleClick) handleClick(id);
          }}
        >
          <AvatarContainer>
            <BorderedAvatar
              src={profilePictureURL}
              alt={name}
              data-testid="avatar"
              borderColor={resolvePatientStatusColor(patientStatusLabel)}
            />
            <ClampedText>{age} anos</ClampedText>
          </AvatarContainer>
          <UserInfoContainer>
            <PatientName
              statusLabel={patientStatusLabel}
              data-testid="patient-name"
            >
              {name}
            </PatientName>

            <StatusIndicator
              data-testid="status-indicator"
              badgeColor={resolveShiftStatusColor(shiftStatusLabel)}
            >
              {shiftStatusLabel}
            </StatusIndicator>
          </UserInfoContainer>
        </PatientOverview>
        <ExpandButton
          onClick={handleExpand}
          data-testid="patient-expand-button"
        >
          <StyledArrow isExpanded={isExpanded} />
        </ExpandButton>
      </Row>
      <ExpandedContent id={id} active={active} shifts={shifts} />
    </CardBox>
  );
};

export default PatientSelectCard;
