import { SubscriptionDetailModel } from '@cuidador/database';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GroupIcon from '@material-ui/icons/Group';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import StatusIcon from '@material-ui/icons/RemoveRedEye';
import format from 'date-fns/format';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import { AuthContext } from '../../contexts/auth';
import useSubscription from '../../hooks/useSubscription';
import {
  Container,
  StyledChangePlan,
  StyledDescriptionIcon,
  StyledIcon,
  StyledLine,
  StyledPlan,
  StyledText,
  StyledTitle,
  StyledTypography,
} from './styles';
import {
  calculateSubscriptionTotalValue,
  IntervalOptions,
  SubscriptionStatusOptions,
} from './utils';

const Plans: React.FC = () => {
  const history = useHistory();
  const [subscription, setSubscription] = useState<SubscriptionDetailModel>();

  const { userInfo } = useContext(AuthContext);
  const subscriptionId = userInfo?.user?.organization?.subscriptionId;
  const { getById, loading } = useSubscription();

  const fetchData = (id: string) => {
    getById(id)
      .then((subscription) => {
        setSubscription(subscription);
      })
      .catch(() => {
        toast.error('Erro ao buscar detalhes do contrato');
      });
  };

  useEffect(() => {
    if (!subscriptionId) return;
    fetchData(subscriptionId);
  }, [subscriptionId]);

  return (
    <>
      <Header title="Plano atual" leftIconClick={() => history.goBack()} />

      {loading ? (
        <LoadingBackdrop loading={loading} />
      ) : (
        <>
          <PendingRegistrationBanner />
          <Container>
            <StyledTitle variant="h6">Detalhes do Plano</StyledTitle>
            <StyledTypography variant="subtitle2">
              <StyledText>
                <StyledDescriptionIcon>
                  <StatusIcon />
                </StyledDescriptionIcon>
                Status: Cobrança{' '}
                {subscription?.status &&
                  SubscriptionStatusOptions[subscription.status]}
              </StyledText>
              <StyledText>
                <StyledDescriptionIcon>
                  <GroupIcon />
                </StyledDescriptionIcon>
                Pessoas sob cuidado: {subscription?.items?.length}
              </StyledText>
              <StyledText>
                <StyledDescriptionIcon>
                  <LocalAtmIcon />
                </StyledDescriptionIcon>
                Valor: R${' '}
                {subscription && calculateSubscriptionTotalValue(subscription)}
              </StyledText>
              <StyledText>
                <StyledDescriptionIcon>
                  <DateRangeIcon />
                </StyledDescriptionIcon>
                Período de cobrança:{' '}
                {subscription?.interval &&
                  IntervalOptions[subscription?.interval]}
              </StyledText>

              <StyledText>
                <StyledDescriptionIcon>
                  <DateRangeIcon />
                </StyledDescriptionIcon>
                {subscription?.nextBillingAt ? (
                  <>
                    Data da próxima cobrança:{' '}
                    {format(new Date(subscription.nextBillingAt), 'dd/MM/yyyy')}
                  </>
                ) : (
                  <>
                    Data de início:{' '}
                    {subscription?.startsAt &&
                      format(new Date(subscription.startsAt), 'dd/MM/yyyy')}
                  </>
                )}
              </StyledText>
            </StyledTypography>
            <StyledPlan>
              <StyledTypography variant="h6">
                <StyledIcon>
                  <LoyaltyIcon />
                </StyledIcon>
                {subscription?.plan?.name}
              </StyledTypography>
              <StyledTypography variant="subtitle2">
                <StyledText>{subscription?.plan?.description}</StyledText>
              </StyledTypography>
              <StyledChangePlan disabled>Trocar plano</StyledChangePlan>
            </StyledPlan>

            <StyledLine />

            {/* <StyledTypography variant="h6">
          Informações do pagamento
        </StyledTypography>
        <StyledTypography variant="subtitle2">
          A cobrança é realizada de maneira recorrente, de acordo com o tipo de
          cobrança.
        </StyledTypography>
        <StyledPay>
          <StyledTypography variant="subtitle1">
            {payMethod.flag}
          </StyledTypography>
          <StyledTypography variant="subtitle1">
            •••• •••• •••• {payMethod.lastCardNumber}
          </StyledTypography>
        </StyledPay>
        <StyledChangeCard>Trocar cartão</StyledChangeCard>
        <StyledChangeContract>Alterar contrato</StyledChangeContract> */}
          </Container>
        </>
      )}
    </>
  );
};

export default Plans;
