import { PlanModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib/error/error';
import { AccordionSummary } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { AxiosError } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import qs from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledFormikTextField } from '../../components/FormCardContainer';
import HeaderWithTitle from '../../components/Headers/HeaderWithTitle';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { StyledFieldset } from '../../components/StyledFieldset';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import useCEP from '../../hooks/useCEP';
import usePlan from '../../hooks/usePlan';
import { resolveErrorMessage } from '../../utils/error';
import { cepMask, cnpjMask } from '../../utils/inputs';
import {
  Container,
  RowContainer,
  StyledAccordion,
  StyledExpandMoreIcon,
  StyledFirstInRow,
  StyledIcon,
  StyledInfo,
  StyledLineSimple,
  StyledPayButton,
  StyledPlan,
  StyledPlanDescription,
  StyledPrice,
  StyledTitle,
  StyledTypography,
} from './styles';
import {
  FormAddressValues,
  FormCompanyValues,
  formValuesToCompanySubscriptionRequest,
  initialAddressValues,
  initialCompanyValues,
  validationAddressSchema,
  validationCompanySchema,
} from './utils';

const SubscriptionCompany: React.FC = () => {
  const formikCompanyValuesRef = useRef<FormikProps<FormCompanyValues> | null>();
  const formikAddressValuesRef = useRef<FormikProps<FormAddressValues> | null>();
  const { getAddress } = useCEP();
  const location = useLocation();
  const { refreshUserInfo, companyPlanId } = useContext(AuthContext);
  const { signCompanyPlan, getById, loading } = usePlan();
  const [district, setDistrict] = useState('');
  const [plan, setPlan] = useState<PlanModel>();
  const { plan: qsPlanId } = qs.parse(location.search);

  const { isAllowedToInvoke } = useCanAccess('user/plan/company.subscribe');

  const submitCompanyInfo = () => {
    // se remover essa funcao a validacao do formik para de funcionar
  };

  const submitAddressInfo = () => {
    // se remover essa funcao a validacao do formik para de funcionar
  };

  const fetchAddress = (cep: string) => {
    if (!formikAddressValuesRef || !formikAddressValuesRef.current) return;
    getAddress(cep)
      .then((data) => {
        const { erro, uf, localidade, bairro, logradouro } = data;

        if (!erro && formikAddressValuesRef.current) {
          formikAddressValuesRef.current.setFieldValue('country', 'Brasil');
          formikAddressValuesRef.current.setFieldValue('province', uf);
          formikAddressValuesRef.current.setFieldValue('city', localidade);
          formikAddressValuesRef.current.setFieldValue('address', logradouro);
          setDistrict(bairro);
        }
      })
      .catch(() => {
        toast.error('CEP inválido');
      });
  };

  // eslint-disable-next-line
  const handlePostalCodeChange = (e: React.ChangeEvent<any>) => {
    if (!formikAddressValuesRef || !formikAddressValuesRef.current) return;
    formikAddressValuesRef.current.setFieldValue('postalCode', e.target.value);
    if (e.target.value.length === 9) {
      fetchAddress(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!formikAddressValuesRef.current || !formikCompanyValuesRef.current)
      return;

    const companyFormik = formikCompanyValuesRef.current;
    const addressFormik = formikAddressValuesRef.current;

    if (!companyFormik.isValid || !addressFormik.isValid) {
      companyFormik.submitForm();
      addressFormik.submitForm();
      return;
    }
    if (!plan?.id) return;

    const body = formValuesToCompanySubscriptionRequest(
      companyFormik.values,
      addressFormik.values,
      district
    );

    signCompanyPlan(plan.id, body)
      .then(() => {
        refreshUserInfo();
        toast.success('Processando...');
      })
      .catch((error) => {
        const displayMessage = resolveErrorMessage(error);
        toast.error(displayMessage);
      });
  };

  useEffect(() => {
    if (!companyPlanId && !qsPlanId) {
      toast.error('Não há ID do plano');
      return;
    }
    const planId = companyPlanId || qsPlanId?.toString() || '';
    getById(planId)
      .then((data) => {
        setPlan(data);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  }, []);

  if (loading) return <LoadingBackdrop loading={loading} />;

  return (
    <>
      <HeaderWithTitle title="Ativação Plano Empresarial" />
      <Container>
        <StyledPlan>
          <StyledTypography variant="h6">
            <StyledTitle>
              <StyledIcon>
                <LoyaltyIcon />
              </StyledIcon>
              {plan?.name}
            </StyledTitle>
          </StyledTypography>
          <StyledTypography variant="subtitle2">
            <StyledPlanDescription>
              <ul>
                <li>
                  Valor por PSC:{' '}
                  <StyledPrice>
                    R$ {(Number(plan?.priceInCents) / 100).toFixed(2)}
                  </StyledPrice>{' '}
                </li>
                <li>Limite de PSCs cadastradas: {plan?.maxAllowedPatients}</li>
                <li>Cadastro ilimitado de cuidadores</li>
              </ul>
            </StyledPlanDescription>
          </StyledTypography>
        </StyledPlan>

        <StyledAccordion>
          <AccordionSummary expandIcon={<StyledExpandMoreIcon />}>
            <StyledTitle>Dados da contratante</StyledTitle>
          </AccordionSummary>
          <StyledLineSimple />
          <Formik
            validateOnChange={false}
            innerRef={(ref) => (formikCompanyValuesRef.current = ref)}
            initialValues={initialCompanyValues}
            validationSchema={validationCompanySchema}
            onSubmit={submitCompanyInfo}
          >
            {({ values }) => {
              return (
                <Form noValidate>
                  <StyledFieldset disabled={!isAllowedToInvoke}>
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="CNPJ"
                      name="cnpj"
                      label="CNPJ"
                      margin="normal"
                      value={cnpjMask(values.cnpj)}
                      inputProps={{ 'data-testid': 'cnpj' }}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="Razão Social"
                      name="companyName"
                      label="Razão Social"
                      margin="normal"
                      inputProps={{ 'data-testid': 'companyName' }}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="Nome Fantasia"
                      name="tradingName"
                      label="Nome Fantasia"
                      margin="normal"
                      inputProps={{ 'data-testid': 'tradingName' }}
                    />
                  </StyledFieldset>
                </Form>
              );
            }}
          </Formik>
        </StyledAccordion>

        <StyledAccordion>
          <AccordionSummary expandIcon={<StyledExpandMoreIcon />}>
            <StyledTitle>Endereço da contratante</StyledTitle>
          </AccordionSummary>
          <StyledLineSimple />
          <Formik
            validateOnChange={false}
            innerRef={(ref) => (formikAddressValuesRef.current = ref)}
            initialValues={initialAddressValues}
            validationSchema={validationAddressSchema}
            onSubmit={submitAddressInfo}
          >
            {({ values }) => (
              <Form noValidate>
                <StyledFieldset disabled={!isAllowedToInvoke}>
                  <StyledFormikTextField
                    color="secondary"
                    placeholder="CEP"
                    name="postalCode"
                    label="CEP"
                    margin="normal"
                    type="tel"
                    inputProps={{ 'data-testid': 'postalCode' }}
                    value={cepMask(values.postalCode)}
                    onChange={handlePostalCodeChange}
                  />
                  <RowContainer>
                    <StyledFirstInRow
                      color="secondary"
                      placeholder="País"
                      name="country"
                      label="País"
                      margin="normal"
                      id="country"
                      inputProps={{ 'data-testid': 'country' }}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="UF"
                      name="province"
                      label="UF"
                      margin="normal"
                      inputProps={{ 'data-testid': 'province' }}
                    />
                  </RowContainer>
                  <StyledFormikTextField
                    color="secondary"
                    placeholder="Cidade"
                    name="city"
                    label="Cidade"
                    margin="normal"
                    inputProps={{ 'data-testid': 'city' }}
                  />
                  <StyledFormikTextField
                    color="secondary"
                    placeholder="Endereço"
                    name="address"
                    label="Endereço"
                    margin="normal"
                    inputProps={{ 'data-testid': 'address' }}
                  />
                  <RowContainer>
                    <StyledFirstInRow
                      color="secondary"
                      placeholder="Número"
                      name="addressNumber"
                      label="Número"
                      margin="normal"
                      type="tel"
                      inputProps={{ 'data-testid': 'addressNumber' }}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="Complemento"
                      name="additional"
                      label="Complemento"
                      margin="normal"
                      inputProps={{ 'data-testid': 'additional' }}
                    />
                  </RowContainer>
                </StyledFieldset>
              </Form>
            )}
          </Formik>
        </StyledAccordion>

        <StyledInfo>
          <StyledTitle>Informações da assinatura</StyledTitle>
          <Typography variant="subtitle2">
            <ul>
              <li>A cobrança será mensal</li>
              <li>
                Todo dia 2 será gerado o boleto de pagamento e será enviado para
                o e-mail cadastrado
              </li>
              <li>
                O valor do boleto será calculado com base na quantidade de PSCs
                ativas no sistema no mês de referência
              </li>
            </ul>
          </Typography>
        </StyledInfo>
        <StyledPayButton
          data-testid="company-submit"
          size="large"
          type="submit"
          onClick={handleSubmit}
          disabled={!isAllowedToInvoke}
        >
          Começar Agora
        </StyledPayButton>
      </Container>
    </>
  );
};

export default SubscriptionCompany;
