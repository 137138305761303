import { EventScheduleModel } from '@cuidador/database';
import { Checkbox, FormGroup, Modal } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { FormikErrors, FormikTouched } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormValues, Measurement } from '..';
import useMeasurement from '../../../hooks/useMeasurement';
import {
  FormCardContainer,
  StyledFormikTextField as FormikTextField,
  StyledBoldTitle,
} from '../../FormCardContainer';
import StyledSimpleDialog from '../../StyledSimpleDialog';
import ErrorText from '../ErrorText';
import {
  Backdrop,
  BackdropCircularProgress,
  ControlOptionsModal,
  ControlOptionsModalBody,
  ControlOptionsModalHeader,
  ControlOptionsModalText,
  Divider,
  FormControlList,
  InstructionRow,
  MeasurementListContainer,
  MeasurementRow,
  OpenModalButton,
  RemoveButtonContainer,
  SaveControlOptionsButton,
  SelectMeasurementContainer,
  StyledBackIcon,
  StyledRemoveButton,
} from './styles';

interface SelectMeasurementProps {
  values: FormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  disabled?: boolean;
}

const MeasurementsSelector: React.FC<SelectMeasurementProps> = ({
  values,
  setFieldValue,
  errors,
  touched,
  disabled,
}) => {
  const [measurementIdToDelete, setMeasurementIdToDelete] = useState<
    string | null
  >(null);
  const [isControlModalOptionsOpen, setIsControlModalOptionsOpen] = useState(
    false
  );
  const { byId, ids: eventsIds, getAllByPatientId, loading } = useMeasurement();

  useEffect(() => {
    getAllByPatientId();
  }, []);

  const isDeleteDialogOpen = useMemo(() => measurementIdToDelete != null, [
    measurementIdToDelete,
  ]);

  const handleRemoveMeasurement = useCallback(
    (id: string | null) => {
      if (!values.measurements || !id) {
        return;
      }
      setMeasurementIdToDelete(null);
      const newMeasurements = values.measurements.filter(
        (measurement) => measurement.id !== id
      );
      setFieldValue('measurements', newMeasurements);
    },
    [values.measurements, setFieldValue, setMeasurementIdToDelete]
  );

  const handleChangeMeasurementInstruction = useCallback(
    (id: string, value: string) => {
      if (!values.measurements) {
        return;
      }
      const newMeasurements = values.measurements.map((measurement) => {
        if (measurement.id === id) {
          return {
            ...measurement,
            instruction: value,
          };
        }
        return measurement;
      });
      setFieldValue('measurements', newMeasurements);
    },
    [values.measurements, setFieldValue]
  );

  const handleMeasurementsConfirm = useCallback(
    (newMeasurements: Measurement[]) => {
      setFieldValue('measurements', newMeasurements);
      setIsControlModalOptionsOpen(false);
    },
    []
  );

  const handleToggleControlModalOptions = () =>
    setIsControlModalOptionsOpen(!isControlModalOptionsOpen);

  return (
    <>
      {loading ? (
        <Backdrop open={loading}>
          {loading && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
      ) : (
        <FormCardContainer>
          <StyledBoldTitle variant="subtitle2">
            Clique no botão abaixo para selecionar os controles e sinais vitais
            que serão medidos neste horário
          </StyledBoldTitle>
          <SelectMeasurementContainer>
            <OpenModalButton
              onClick={() => handleToggleControlModalOptions()}
              disabled={disabled}
            >
              Selecionar controles
            </OpenModalButton>
            {isControlModalOptionsOpen && (
              <SelectMeasurementModal
                open={isControlModalOptionsOpen}
                defaultMeasurements={values.measurements || []}
                events={eventsIds.map((id) => byId[id])}
                onClose={handleToggleControlModalOptions}
                onConfirm={handleMeasurementsConfirm}
              />
            )}
          </SelectMeasurementContainer>
          {errors.measurements && touched.measurements && (
            <ErrorText errorMessage={errors.measurements as string} />
          )}
          <MeasurementListContainer>
            <MeasurementList
              measurements={values.measurements || []}
              measurementsById={byId}
              onMeasurementDelete={(id: string) => {
                setMeasurementIdToDelete(id);
              }}
              onMeasurementInstructionChange={(
                id: string,
                newValue: string
              ) => {
                handleChangeMeasurementInstruction(id, newValue);
              }}
              disabled={disabled}
            />
          </MeasurementListContainer>
        </FormCardContainer>
      )}
      <StyledSimpleDialog
        open={isDeleteDialogOpen}
        handleNo={() => setMeasurementIdToDelete(null)}
        handleYes={() => handleRemoveMeasurement(measurementIdToDelete)}
        title="Remover controle"
        subTitle="Tem certeza de que deseja remover o controle?"
      />
    </>
  );
};

const SelectMeasurementModal: React.FC<{
  open: boolean;
  events: EventScheduleModel[];
  defaultMeasurements: Measurement[];
  onClose: () => void;
  onConfirm: (measurements: Measurement[]) => void;
}> = ({ open, events, defaultMeasurements, onClose, onConfirm }) => {
  const [measurementsToBeConfimed, setMeasurementsToBeConfimed] = useState<
    Measurement[]
  >(defaultMeasurements);

  const isMeasurementChecked = (measurementId: string) => {
    return measurementsToBeConfimed.some(({ id }) => id === measurementId);
  };

  const handleToggleMeasurementToBeConfirmed = (measurementId: string) => {
    if (isMeasurementChecked(measurementId)) {
      const newMeasurementsToBeConfimed = measurementsToBeConfimed.filter(
        ({ id }) => id !== measurementId
      );
      setMeasurementsToBeConfimed(newMeasurementsToBeConfimed);
    } else {
      const newMeasurementsToBeConfimed = [
        ...measurementsToBeConfimed,
        {
          id: measurementId,
          instruction: '',
        },
      ];
      setMeasurementsToBeConfimed(newMeasurementsToBeConfimed);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ControlOptionsModal>
        <ControlOptionsModalHeader>
          <StyledBackIcon onClick={onClose} />
          <ControlOptionsModalText>
            Selecione 1 ou mais controles e clique em “Selecionar”
          </ControlOptionsModalText>
        </ControlOptionsModalHeader>
        <ControlOptionsModalBody>
          <FormGroup>
            {events.map((item) => (
              <FormControlList
                control={
                  <Checkbox checked={isMeasurementChecked(String(item.id))} />
                }
                key={item.id}
                value={item.id}
                onClick={() =>
                  handleToggleMeasurementToBeConfirmed(String(item.id))
                }
                color="secondary"
                label={item.name}
              />
            ))}
          </FormGroup>
          <SaveControlOptionsButton
            onClick={() => onConfirm(measurementsToBeConfimed)}
          >
            Selecionar
          </SaveControlOptionsButton>
        </ControlOptionsModalBody>
      </ControlOptionsModal>
    </Modal>
  );
};

const MeasurementList: React.FC<{
  measurements: Measurement[];
  measurementsById: Record<string, EventScheduleModel>;
  onMeasurementDelete: (id: string) => void;
  onMeasurementInstructionChange: (id: string, newValue: string) => void;
  disabled?: boolean;
}> = ({
  measurements,
  measurementsById,
  onMeasurementDelete,
  onMeasurementInstructionChange,
  disabled,
}) => {
  return (
    <>
      {measurements.map(
        (measurement, index) =>
          measurementsById[measurement.id] && (
            <MeasurementListItem
              key={measurement.id}
              label={`${index + 1}ª ${
                measurementsById[measurement.id].name || ''
              }`}
              measurement={measurement}
              onDelete={() => onMeasurementDelete(measurement.id)}
              onInstructionChange={(newValue) =>
                onMeasurementInstructionChange(measurement.id, newValue)
              }
              disabled={disabled}
            />
          )
      )}
    </>
  );
};

const MeasurementListItem: React.FC<{
  label: string;
  measurement: Measurement;
  onDelete: () => void;
  onInstructionChange: (newValue: string) => void;
  disabled?: boolean;
}> = ({ label, measurement, onDelete, onInstructionChange, disabled }) => {
  const [measurementInstruction, setMeasurementInstruction] = useState(
    measurement.instruction
  );
  const isRemoveButtonDisabled = !disabled;

  useEffect(() => {
    debouncedOnInstructionChange(measurementInstruction);
  }, [measurementInstruction]);

  const debouncedOnInstructionChange = useCallback(
    debounce(onInstructionChange, 200),
    [onInstructionChange]
  );

  return (
    <>
      <Divider />
      <MeasurementRow key={measurement.id}>
        <StyledBoldTitle variant="subtitle2">{label}</StyledBoldTitle>
        {isRemoveButtonDisabled && (
          <RemoveButtonContainer>
            <StyledRemoveButton onClick={onDelete}>
              <Clear fontSize="small" />
            </StyledRemoveButton>
          </RemoveButtonContainer>
        )}
      </MeasurementRow>
      <InstructionRow>
        <FormikTextField
          id="measurementInstruction"
          name="measurementInstruction"
          value={measurementInstruction}
          variant="outlined"
          label="Instruções (opcional)"
          placeholder="Instruções"
          color="secondary"
          inputProps={{ 'data-testid': 'measurementInstruction' }}
          margin="normal"
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          size="small"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMeasurementInstruction(e.target.value)
          }
        />
      </InstructionRow>
    </>
  );
};

export default MeasurementsSelector;
