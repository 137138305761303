import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { AxiosResponse } from 'axios';
import { PatientModel } from '@cuidador/database';
import AddIcon from '@material-ui/icons/AddRounded';
import useCanAccess from '../../../../hooks/useCanAccess';
import usePatient, {
  CustomStatus,
  GetPatientPaginatedRequestParams,
  PatientQueryType,
} from '../../../../hooks/usePatient';
import { AuthContext } from '../../../../contexts/auth';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';
import PatientSearchTextField from '../../../../components/PatientSearchTextField';
import PatientSelectCard from '../../../../components/SelectCard/Patient';
import StyledButton from '../../../../components/StyledButton';
import FilterChips from './FilterChips';
import { Item } from '../../../../utils/store';
import { getAge, handleGetProfilePictureURL } from '../utils';
import { CardsContainer, Container, StyledFab } from '../styles';

const Patients: React.FC = () => {
  const [page, setPage] = useState(0);
  const [patients, setPatients] = useState<PatientModel[]>([]);
  const [
    patientIdToProfilePictureURL,
    setPatientIdToProfilePictureURL,
  ] = useState<Record<number, string>>({});
  const [customStatus, setCustomStatus] = useState<CustomStatus[]>([
    'enabled',
    'pending',
  ]);
  const [patientQueryType, setPatientQueryType] = useState<
    PatientQueryType | undefined
  >(undefined);

  const history = useHistory();
  const {
    getRelatedPatientsPaginated,
    getOrganizationPatientsPaginated,
    getProfilePicture,
    loading: loadingPatient,
    total,
    setActive,
  } = usePatient();
  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );
  const {
    isAllowedToCreate: isAllowedToCreatePatient,
    isAllowedToRead: isAllowedToReadRelatedPatient,
  } = useCanAccess('user/patient');
  const { isAllowedToRead: isAllowedToReadOrganizationPatient } = useCanAccess(
    'user/patient.by-organization'
  );
  const { userInfo, refreshUserInfo, loading: loadingAuth } = useContext(
    AuthContext
  );

  const activePatient = userInfo?.activePatient;
  const historyLocationSearch = history.location.search;

  useEffect(() => {
    if (!isAllowedToReadRelatedPatient) {
      if (!isAllowedToReadOrganizationPatient) {
        toast.error('Você não tem permissão para visualizar essa página');
        history.push('/cadastros');
        return;
      } else {
        // Forces query type to organization (which is enabled by isAllowedToReadOrganizationPatient)
        setPatientQueryType('organizationPatients');
      }
    } else {
      setPatientQueryType('relatedPatients');
    }
  }, []);

  useEffect(() => {
    handleGetPatients();
    refreshUserInfo();
  }, [page, customStatus, patientQueryType, historyLocationSearch]);

  const isPatientQueryTypeToogleable = useMemo(() => {
    return isAllowedToReadOrganizationPatient && isAllowedToReadRelatedPatient;
  }, [isAllowedToReadOrganizationPatient, isAllowedToReadRelatedPatient]);

  const handleGetPatients = () => {
    if (patientQueryType === undefined) {
      // Not ready yet
      return;
    }
    if (patientQueryType === 'relatedPatients') {
      return handleGetRelatedPatientsPaginated();
    } else {
      return handleGetPatientsOrganizationPaginated();
    }
  };

  const handleTogglePatientQueryType = () => {
    if (!isPatientQueryTypeToogleable) {
      return;
    }
    setPatientQueryType((currentPatientQueryType) =>
      currentPatientQueryType === 'organizationPatients'
        ? 'relatedPatients'
        : 'organizationPatients'
    );
  };

  const makeFetchPatients = (
    fetchFn: (
      params: GetPatientPaginatedRequestParams
    ) => Promise<
      AxiosResponse<{
        results: Item<PatientModel>[];
        total: number;
      }>
    >
  ) => {
    const params = qs.parse(historyLocationSearch);
    fetchFn({ ...params, customStatus, page, orderBy: 'name', limit: 10 })
      .then((response) => {
        const loadedPatients = response.data.results;
        const patientsToInsert = loadedPatients;
        for (const patient of patientsToInsert) {
          if (!patient.id || !isAllowedToReadProfilePicture) return;
          handleGetProfilePictureURL(patient.id, getProfilePicture).then(
            (url) => {
              if (!url) return;
              setPatientIdToProfilePictureURL((previousMap) => {
                if (!patient.id) return { ...previousMap };
                const newMap = { ...previousMap };
                newMap[patient.id] = url;
                return newMap;
              });
            }
          );
        }

        if (page === 0) {
          setPatients(patientsToInsert);
        } else {
          setPatients([...patients, ...patientsToInsert]);
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const handleGetPatientsOrganizationPaginated = () => {
    makeFetchPatients(getOrganizationPatientsPaginated);
  };

  const handleGetRelatedPatientsPaginated = () => {
    makeFetchPatients(getRelatedPatientsPaginated);
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const onChangeParams = () => {
    if (page !== 0) {
      setPage(0);
    }
  };

  const isLoading =
    loadingAuth || loadingPatient || patientQueryType === undefined;

  if (isLoading && patients.length === 0) {
    return <LoadingBackdrop loading={isLoading} />;
  }

  return (
    <>
      <PatientSearchTextField
        fieldName="patient.name"
        onChangeDebounced={onChangeParams}
      />
      <FilterChips
        setCustomStatus={setCustomStatus}
        customStatus={customStatus}
        isPatientQueryTypeToogleable={isPatientQueryTypeToogleable}
        handleTogglePatientQueryType={handleTogglePatientQueryType}
        patientQueryType={patientQueryType}
      />
      <Container>
        <CardsContainer data-testid="card-container">
          {activePatient && (
            <PatientSelectCard
              key={Number(activePatient.id)}
              id={Number(activePatient.id)}
              name={activePatient.name || ''}
              age={getAge(activePatient.dateOfBirth)}
              profilePictureURL={
                patientIdToProfilePictureURL[Number(activePatient.id)]
              }
              status={String(activePatient.status)}
              isNew={Number(activePatient.isNew)}
              shifts={activePatient.shifts}
              active
              handleClick={() => history.goBack()}
            />
          )}
          {patients.map((patient) => {
            if (patient.id === activePatient?.id) return null;
            return (
              <PatientSelectCard
                key={Number(patient.id)}
                id={Number(patient.id)}
                name={patient.name || ''}
                age={getAge(patient.dateOfBirth)}
                profilePictureURL={
                  patientIdToProfilePictureURL[Number(patient.id)]
                }
                status={String(patient.status)}
                isNew={Number(patient.isNew)}
                shifts={patient.shifts}
                handleClick={async () => {
                  try {
                    await setActive(Number(patient.id));
                    await refreshUserInfo();
                    history.goBack();
                  } catch (err) {
                    toast.error(err);
                  }
                }}
              />
            );
          })}
        </CardsContainer>
        {(loadingAuth || loadingPatient) && (
          <LoadingBackdrop loading={loadingAuth || loadingPatient} />
        )}
        {patients.length < total && (
          <StyledButton
            data-testid="show-more"
            size="medium"
            color="secondary"
            onClick={pageIncrement}
          >
            Ver mais
          </StyledButton>
        )}
      </Container>
      {isAllowedToCreatePatient && (
        <StyledFab
          onClick={() => history.push('/cadastro-psc')}
          data-testid="create-patient-button"
        >
          <AddIcon />
        </StyledFab>
      )}
    </>
  );
};

export default Patients;
