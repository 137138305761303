import { PlanModel } from '@cuidador/database';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ReactComponent as HandsIcon } from '../../assets/hands.svg';
import HeaderWithSignOut from '../../components/Headers/HeaderWithSignOut';
import AssignPlanCardProps from '../../components/AssignPlanCard';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import usePlan from '../../hooks/usePlan';
import {
  Container,
  StyledTypography,
  TextTitleContainer,
  TitleContainer,
} from './styles';

export const PlanList: React.FC = () => {
  const [plans, setPlans] = useState<PlanModel[]>([]);
  const { loading, getAvailablePlans } = usePlan();
  const history = useHistory();

  useEffect(() => {
    getAvailablePlans()
      .then((data) => {
        setPlans(data);
      })
      .catch(() => toast.error('Erro ao carregar planos.'));
  }, []);

  return (
    <>
      <HeaderWithSignOut title="Planos de assinatura" />
      {loading ? (
        <LoadingBackdrop loading={loading} />
      ) : (
        <>
          <TitleContainer>
            <HandsIcon data-testid="handsImage" />
            <TextTitleContainer>
              <StyledTypography>
                Contrate o plano que melhor se ajusta à sua necessidade.
              </StyledTypography>
            </TextTitleContainer>
          </TitleContainer>

          <Container>
            {plans.map((plan) => (
              <AssignPlanCardProps
                handleClick={() =>
                  history.push('/assinatura', { selectedPlan: plan })
                }
                name={plan.planCardTitle || plan.name}
                value={plan.priceInCents}
                discount={plan.discountInPercentage}
                interval={plan.interval}
                intervalCount={plan.intervalCount}
                key={plan.id}
              />
            ))}
          </Container>
        </>
      )}
    </>
  );
};
