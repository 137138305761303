import styled from 'styled-components';
import { Tab } from '@material-ui/core';

export const TabbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: ${({ theme }) => theme.spacing(1)}px;

  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
