import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Account from '../pages/Account';
import Appointments from '../pages/Appointments';
import AppointmentsInsert from '../pages/Appointments/AppointmentsInsert';
import AppointmentsUpdate from '../pages/Appointments/AppointmentsUpdate';
import AwaitingReview from '../pages/AwaitingReview';
import NoOrganizationIdBlock from '../pages/BlockPages/NoOrganizationIdBlock';
import NoPatientRelationsBlock from '../pages/BlockPages/NoPatientRelationsBlock';
import Caregiver from '../pages/Caregiver';
import { default as UserForm } from '../pages/Caregiver/UserForm';
import CompleteSignup from '../pages/CompleteSignup';
import Contacts from '../pages/Contacts';
import ContactsInsert from '../pages/Contacts/ContactsInsert';
import ContactsUpdate from '../pages/Contacts/ContactsUpdate';
import CreateTimeContestation from '../pages/CreateTimeContestation';
import ExecutionHistoryContestation from '../pages/ExecutionHistoryContestation';
import Follow from '../pages/Follow';
import FollowShift from '../pages/Follow/History/Shift';
import ForgotPassword from '../pages/ForgotPassword';
import GuardianRegister from '../pages/GuardianRegister';
import Interview from '../pages/Interview';
import Login from '../pages/Login';
import MeasurementScheduleList from '../pages/MeasurementSchedule/List';
import MeasurementScheduleRegister from '../pages/MeasurementSchedule/Register';
import UpdateMeasurementSchedule from '../pages/MeasurementSchedule/Update';
import Medication from '../pages/Medication';
import MedicationInsert from '../pages/Medication/MedicationInsert';
import MedicationInsertV2 from '../pages/Medication/MedicationInsertV2';
import MedicationUpdate from '../pages/Medication/MedicationUpdate';
import MedicationUpdateV2 from '../pages/Medication/MedicationUpdateV2';
import Onboarding from '../pages/Onboarding';
import OrganizationPatient from '../pages/Organization/Patient';
import OrganizationUser from '../pages/Organization/User';
import PatientRegister from '../pages/Patient/PatientRegister';
import { PatientSelect } from '../pages/Patient/PatientSelect';
import PatientUpdate from '../pages/Patient/PatientUpdate';
import Plan from '../pages/Plan';
import { PlanList } from '../pages/PlanList';
import PatientPreInterview from '../pages/PreInterview';
import ProcessingPayment from '../pages/ProcessingPayment';
import ProfessionalReport from '../pages/ProfessionalReport';
import ProfessionalReportItem from '../pages/ProfessionalReport/ProfessionalReportItem';
import RegistrationDashboard from '../pages/RegistrationDashboard';
import ReportList from '../pages/ReportList';
import Routines from '../pages/Routines';
import RoutinesInsert from '../pages/Routines/RoutinesInsert';
import RoutinesUpdate from '../pages/Routines/RoutinesUpdate';
import ShiftExecution from '../pages/ShiftExecution';
import ShiftSchedule from '../pages/ShiftSchedule';
import AdditionalCoverageInsert from '../pages/ShiftSchedule/AdditionalCoverage/AdditionalCoverageInsert';
import AdditionalCoverageUpdate from '../pages/ShiftSchedule/AdditionalCoverage/AdditionalCoverageUpdate';
import ShiftScheduleInsert from '../pages/ShiftSchedule/NextShiftSchedules/ShiftScheduleInsert';
import ShiftScheduleUpdate from '../pages/ShiftSchedule/NextShiftSchedules/ShiftScheduleUpdate';
import ShoppingList from '../pages/ShoppingList';
import SubscriptionActivation from '../pages/SubscriptionActivation';
import SubscriptionCompany from '../pages/SubscriptionCompany';
import SubscriptionPayment from '../pages/SubscriptionPayment';
import AuthenticatedRoute from './AuthenticatedRoute';
import RouteAnalytics from './RoutesAnalytics';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import { DevelopmentInProgress } from '../pages/DevelopmentInProgress';

const Routes: React.FC = () => {
  return (
    <>
      <RouteAnalytics />
      <Switch>
        <UnauthenticatedRoute path="/login" component={Login} />
        <UnauthenticatedRoute path="/cadastro" component={GuardianRegister} />
        <UnauthenticatedRoute
          path="/completar-cadastro"
          component={CompleteSignup}
        />
        <UnauthenticatedRoute
          path="/esqueci-minha-senha"
          component={ForgotPassword}
        />
        <Route
          path="/em-construcao/:page"
          render={() => <DevelopmentInProgress />}
        />
        <AuthenticatedRoute path="/onboarding" component={Onboarding} />
        <AuthenticatedRoute path="/cadastro-psc" component={PatientRegister} />
        <AuthenticatedRoute
          path="/aguardando-revisao"
          component={AwaitingReview}
        />

        <AuthenticatedRoute exact path="/medicamentos" component={Medication} />
        <AuthenticatedRoute
          path="/medicamentos/novo"
          component={MedicationInsert}
        />
        <AuthenticatedRoute
          path="/medicamentos/:id"
          component={MedicationUpdate}
        />
        <AuthenticatedRoute
          path="/medicamentos_v2/novo"
          component={MedicationInsertV2}
        />
        <AuthenticatedRoute
          path="/medicamentos_v2/:id"
          component={MedicationUpdateV2}
        />

        <AuthenticatedRoute path="/acompanhar/:tabId?" component={Follow} />
        <AuthenticatedRoute
          path="/historico/plantao/:id"
          component={FollowShift}
        />
        <AuthenticatedRoute
          path="/correcao-de-horarios/historico/plantao/:shiftId/execucao/:executionId"
          component={ExecutionHistoryContestation}
        />
        <AuthenticatedRoute
          path="/correcao-de-horarios/plantao/:shiftId/execucao/:shiftExecutionId/novo"
          component={CreateTimeContestation}
        />
        <AuthenticatedRoute
          path="/correcao-de-horarios/plantao/:id"
          component={ShiftExecution}
        />
        <AuthenticatedRoute path="/compras" component={ShoppingList} />
        <AuthenticatedRoute exact path="/cuidadores" component={Caregiver} />
        <AuthenticatedRoute path="/usuarios/novo" component={UserForm} />
        <AuthenticatedRoute path="/usuarios/:id" component={UserForm} />
        <AuthenticatedRoute
          path="/controles/novo"
          component={MeasurementScheduleRegister}
        />
        <AuthenticatedRoute
          exact
          path="/controles"
          component={MeasurementScheduleList}
        />
        <AuthenticatedRoute
          exact
          path="/controles/:id"
          component={UpdateMeasurementSchedule}
        />
        <AuthenticatedRoute
          path="/plantoes/novo"
          component={ShiftScheduleInsert}
        />
        <AuthenticatedRoute
          exact
          path="/plantoes/:tab"
          component={ShiftSchedule}
        />
        <AuthenticatedRoute
          exact
          path="/plantoes/item/:id"
          component={ShiftScheduleUpdate}
        />
        <AuthenticatedRoute
          exact
          path="/cobertura-adicional/novo"
          component={AdditionalCoverageInsert}
        />
        <AuthenticatedRoute
          exact
          path="/cobertura-adicional/:id"
          component={AdditionalCoverageUpdate}
        />
        <AuthenticatedRoute exact path="/agendar/rotina" component={Routines} />
        <AuthenticatedRoute
          exact
          path="/agendar/compromisso"
          component={Appointments}
        />
        <AuthenticatedRoute
          exact
          path="/agendar/compromisso/novo"
          component={AppointmentsInsert}
        />
        <AuthenticatedRoute
          exact
          path="/agendar/compromisso/:id"
          component={AppointmentsUpdate}
        />
        <AuthenticatedRoute
          exact
          path="/agendar/rotina/nova"
          component={RoutinesInsert}
        />
        <AuthenticatedRoute
          exact
          path="/agendar/rotina/:id"
          component={RoutinesUpdate}
        />

        <AuthenticatedRoute exact path="/contatos" component={Contacts} />
        <AuthenticatedRoute
          exact
          path="/contatos/novo"
          component={ContactsInsert}
        />
        <AuthenticatedRoute
          exact
          path="/contatos/:id"
          component={ContactsUpdate}
        />
        <AuthenticatedRoute
          exact
          path="/minha-conta/:tabId"
          component={Account}
        />
        <AuthenticatedRoute exact path="/plano" component={Plan} />

        <AuthenticatedRoute
          exact
          path="/cadastros"
          component={RegistrationDashboard}
        />

        <AuthenticatedRoute
          exact
          path="/entrevista/:actionType"
          component={Interview}
        />

        <AuthenticatedRoute
          exact
          path="/pre-entrevista"
          component={PatientPreInterview}
        />

        <AuthenticatedRoute exact path="/psc" component={PatientUpdate} />

        <AuthenticatedRoute
          exact
          path="/pessoas/:tab"
          component={PatientSelect}
        />

        <AuthenticatedRoute
          exact
          path="/pessoas/usuarios/:id"
          component={UserForm}
        />

        <AuthenticatedRoute
          exact
          path="/subscription-activation"
          component={SubscriptionActivation}
        />

        <AuthenticatedRoute
          exact
          path="/assinatura"
          component={SubscriptionPayment}
        />

        <AuthenticatedRoute exact path="/planos" component={PlanList} />

        <AuthenticatedRoute
          exact
          path="/company"
          component={SubscriptionCompany}
        />

        <AuthenticatedRoute
          exact
          path="/processando-pagamento"
          component={ProcessingPayment}
        />

        <AuthenticatedRoute
          exact
          path="/avaliacao-multiprofissional/paciente/:patientId"
          component={ProfessionalReport}
        />

        <AuthenticatedRoute
          exact
          path="/avaliacao-multiprofissional/:id"
          component={ProfessionalReportItem}
        />

        <AuthenticatedRoute
          exact
          path="/relatorio/paciente/:patientId"
          component={ReportList}
        />

        <AuthenticatedRoute
          exact
          path="/organizacao/usuario/:id"
          component={OrganizationUser}
        />

        <AuthenticatedRoute
          exact
          path="/organizacao/paciente/:id"
          component={OrganizationPatient}
        />

        <AuthenticatedRoute
          exact
          path="/usuario-sem-organizacao"
          component={NoOrganizationIdBlock}
        />

        <AuthenticatedRoute
          exact
          path="/usuario-sem-vinculo"
          component={NoPatientRelationsBlock}
        />

        <Redirect path="/pessoas-sob-cuidado" to="/pessoas/pacientes" />
        <Redirect to="/acompanhar/plantao" />
      </Switch>
    </>
  );
};

export default Routes;
