import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/Headers/Header';
import Tabbar from './Tabbar';
import OrganizationMembers from './OrganizationMembers';
import Patients from './Patients';
import { RouteParams } from './Tabbar/utils';

export const PatientSelect: React.FC = () => {
  const { tab } = useParams<RouteParams>();

  return (
    <>
      <Header title="Gestão Operacional" rightButtonType="pscList" />

      <Tabbar />

      {tab === 'pacientes' && <Patients />}
      {tab === 'usuarios' && <OrganizationMembers />}
    </>
  );
};
