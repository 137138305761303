import LinkIcon from '@material-ui/icons/InsertLink';
import React from 'react';
import {
  CustomStatus,
  PatientQueryType,
} from '../../../../../hooks/usePatient';
import ToggleableChip from '../../../../../components/ToggleableChip';
import { Container } from '../../../../../components/ToggleableChip/styles';
import { ReactComponent as ActiveIcon } from '../../../../../assets/active-icon.svg';
import { ReactComponent as InactiveIcon } from '../../../../../assets/disable-icon.svg';
import { ReactComponent as PendingIcon } from '../../../../../assets/pending-icon.svg';

export const chipItens: Array<{
  label: string;
  value: CustomStatus;
  icon?: JSX.Element;
}> = [
  { label: 'Ativos', value: 'enabled', icon: <ActiveIcon /> },
  { label: 'Pendentes', value: 'pending', icon: <PendingIcon /> },
  { label: 'Inativos', value: 'disabled', icon: <InactiveIcon /> },
];

interface FilterChipsProps {
  setCustomStatus: (customStatus: CustomStatus[]) => void;
  customStatus: CustomStatus[];
  isPatientQueryTypeToogleable: boolean;
  handleTogglePatientQueryType: () => void;
  patientQueryType?: PatientQueryType;
}

const FilterChips: React.FC<FilterChipsProps> = ({
  setCustomStatus,
  customStatus,
  isPatientQueryTypeToogleable,
  handleTogglePatientQueryType,
  patientQueryType,
}) => {
  const handleToggleStatus = (value: CustomStatus, isActive: boolean) => {
    if (isActive) {
      setCustomStatus(customStatus.filter((status) => status !== value));
    } else {
      setCustomStatus([...customStatus, value]);
    }
  };

  return (
    <Container>
      {chipItens.map(({ label, value, icon }) => {
        const isActive = customStatus.includes(value);
        return (
          <ToggleableChip
            key={value}
            value={value}
            label={label}
            isActive={isActive}
            onClick={() => {
              handleToggleStatus(value, isActive);
            }}
            icon={icon}
          />
        );
      })}
      {isPatientQueryTypeToogleable && (
        <ToggleableChip
          label="Meus pacientes"
          icon={<LinkIcon />}
          onClick={handleTogglePatientQueryType}
          isActive={patientQueryType === 'relatedPatients'}
          value="my-patients"
        />
      )}
    </Container>
  );
};

export default FilterChips;
