import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../../components/Headers/Header';
import ShiftScheduleUpsertForm, {
  formDataToShiftScheduleModel,
  FormValues,
} from '../../../../components/ShiftScheduleUpsertForm';
import { AuthContext } from '../../../../contexts/auth';
import useShiftSchedule from '../../../../hooks/useShiftSchedule';
import { resolveErrorMessage } from '../../../../utils/error';
import { Container } from './styles';

const formInitialValue = {
  name: '',
  startingTime: '',
  duration: '',
  days: [],
};

const ShiftScheduleInsert: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const { post } = useShiftSchedule();

  const patientId = userInfo?.activePatientId;

  const history = useHistory();
  const handleSubmit = (values: FormValues) => {
    if (!patientId) return;
    return post(patientId, formDataToShiftScheduleModel(values))
      .then(() => {
        history.replace('/plantoes/proximos');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        title="Adicionar plantão"
        leftIconClick={() => history.push('/cadastros')}
      />
      <Container>
        <ShiftScheduleUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};

export default ShiftScheduleInsert;
