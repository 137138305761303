import { UserModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import qs from 'query-string';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserHeader from '../../../components/Headers/UserHeader';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import PatientSearchTextField from '../../../components/PatientSearchTextField';
import OrganizationMember from '../../../components/SelectCard/Member';
import StyledButton from '../../../components/StyledButton';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import usePatient from '../../../hooks/usePatient';
import useUser from '../../../hooks/useUser';
import { resolveErrorMessage } from '../../../utils/error';
import { handleGetProfilePictureURL } from '../utils';
import { Container } from './styles';

const OrganizationPatient: React.FC = () => {
  const [page, setPage] = useState(0);
  const [organizationMembers, setOrganizationMembers] = useState<UserModel[]>(
    []
  );
  const [
    patientIdToProfilePictureURL,
    setPatientIdToProfilePictureURL,
  ] = useState<Record<number, string>>({});

  const {
    total,
    loading: loadingOrganizationMembers,
    getOrganizationMembersPaginated,
    getProfilePicture,
    relateToPatient,
    unrelateFromPatient,
  } = useUser();
  const { getById, byId, loading: loadingPatient } = usePatient();

  const { loading: loadingUserInfo, refreshUserInfo, userInfo } = useContext(
    AuthContext
  );

  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );
  const { isAllowedToRead: isAllowedToReadOrganizationMembers } = useCanAccess(
    'user/user.by-organization'
  );
  const { isAllowedToInvoke: isAllowedToRelatePatient } = useCanAccess(
    'user/relate-to-patient'
  );
  const { isAllowedToInvoke: isAllowedToUnrelatePatient } = useCanAccess(
    'user/unrelate-from-patient'
  );
  const { isAllowedToRead: isAllowedToReadPatient } = useCanAccess(
    'user/patient'
  );

  const history = useHistory();
  const params = useParams<{ id: string }>();
  const patientId = parseInt(params.id);
  const patient = patientId ? byId[patientId] : null;
  const historyLocationSearch = history.location.search;

  useEffect(() => {
    if (!isAllowedToReadOrganizationMembers || !isAllowedToReadPatient) {
      toast.error('Você não tem permissão para visualizar essa página');
      history.push('/cadastros');
    }
  }, [isAllowedToReadOrganizationMembers]);

  useEffect(() => {
    getPatient();
  }, [patientId, isAllowedToReadPatient]);

  useEffect(() => {
    getOrganizationMembers();
  }, [
    page,
    historyLocationSearch,
    patientId,
    isAllowedToReadOrganizationMembers,
  ]);

  const getPatient = async () => {
    if (patientId && isAllowedToReadPatient) {
      getById(patientId).catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
    }
  };

  const getOrganizationMembers = () => {
    const params = qs.parse(historyLocationSearch);
    getOrganizationMembersPaginated({
      ...params,
      page,
      limit: 10,
      withRelatedPatientId: Number(patientId),
    })
      .then((response) => {
        const loadedPatients = response.data.results;
        const patientsToInsert = loadedPatients;
        for (const patient of patientsToInsert) {
          if (patient.id && isAllowedToReadProfilePicture) {
            handleGetProfilePictureURL(patient.id, getProfilePicture).then(
              (url) => {
                if (!url) return;
                setPatientIdToProfilePictureURL((previousMap) => {
                  if (!patient.id) return { ...previousMap };
                  const newMap = { ...previousMap };
                  newMap[patient.id] = url;
                  return newMap;
                });
              }
            );
          }
        }

        if (page === 0) {
          setOrganizationMembers(patientsToInsert);
        } else {
          setOrganizationMembers([...organizationMembers, ...patientsToInsert]);
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const onChangeParams = () => {
    if (page !== 0) {
      setPage(0);
    }
  };

  const handleRelateOrUnrelate = async (user: UserModel) => {
    if (user.isUserRelatedToPatient) {
      await handleConfirmUnrelate(user);
    } else {
      await handleConfirmRelation(user);
    }
    // refresh userInfo if patched patient<>user relation is about logged user
    if (user.id === userInfo?.id) await refreshUserInfo();

    // refresh organization members list from beginning (page 0)
    if (page === 0) {
      getOrganizationMembers();
    } else {
      setPage(0);
    }
  };

  const handleConfirmRelation = (user: UserModel) => {
    if (isAllowedToUnrelatePatient) {
      return relateToPatient(Number(user.id), patientId)
        .then(() => {
          toast.dismiss();
          toast.success('Paciente vinculado ao usuário');
        })
        .catch((err) => {
          toast.dismiss();
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
        });
    }
  };

  const handleConfirmUnrelate = (user: UserModel) => {
    if (isAllowedToRelatePatient) {
      return unrelateFromPatient(Number(user.id), patientId)
        .then(() => {
          toast.dismiss();
          toast.success('Vinculo com usuário removido');
        })
        .catch((err) => {
          toast.dismiss();
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
        });
    }
  };

  const isLoading =
    loadingPatient || loadingOrganizationMembers || Boolean(loadingUserInfo);

  return (
    <>
      <LoadingBackdrop loading={isLoading} />
      {patient && (
        <>
          <UserHeader
            title={String(patient.name)}
            userId={Number(patient.id)}
          />
          <PatientSearchTextField
            fieldName="user.name"
            onChangeDebounced={onChangeParams}
          />
          <Container>
            {organizationMembers.map((user) => (
              <OrganizationMember
                key={Number(user.id)}
                id={Number(user.id)}
                name={String(user.name)}
                roleTitle={String(user.role?.title)}
                profilePictureURL={
                  patientIdToProfilePictureURL[Number(user.id)]
                }
                status={String(user.status)}
                isPatientRelatedToUser={Boolean(user.isUserRelatedToPatient)}
                onRelationClick={() => handleRelateOrUnrelate(user)}
                onRowClick={() =>
                  history.push(`/pessoas/usuarios/${Number(user.id)}`)
                }
              />
            ))}
            {organizationMembers.length < total && (
              <StyledButton
                data-testid="show-more"
                size="medium"
                color="secondary"
                onClick={pageIncrement}
              >
                Ver mais
              </StyledButton>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default OrganizationPatient;
