import { differenceInYears } from 'date-fns';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export function getAge(dateOfBirth = '') {
  return differenceInYears(new Date(), new Date(dateOfBirth));
}

export const handleGetProfilePictureURL = async (
  id: number,
  getProfilePictureFn: (
    id: number
  ) => Promise<
    AxiosResponse<{
      signedUrl: string;
    }>
  >
) => {
  try {
    return await getProfilePictureFn(id).then(
      ({ data: { signedUrl } }) => signedUrl
    );
  } catch (err) {
    // ignore patient without profile picture error
    if (!String(err).includes('404')) toast.error(err);
  }
};
