import { clientSideScheme } from '@cuidador/whitelabel';
import { IconButton, ListItemIcon } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ReactComponent as SignOutIcon } from '../../../../assets/sign-out-icon.svg';
import HealthServices from '../../../../assets/health-services-icon.svg';
import HealthProducts from '../../../../assets/health-products-icon.svg';
import { AuthContext } from '../../../../contexts/auth';
import useCanAccess from '../../../../hooks/useCanAccess';
import LogoutDialog from '../../../LogoutDialog';
import AppShortcut from '../AppSchortcut';
import {
  AppIcon,
  DrawerBody,
  DrawerButton,
  DrawerFooter,
  DrawerFooterText,
  DrawerHeader,
  HealthProductsIconWithMargin,
  StyledCloseIcon,
  StyledDrawer,
  StyledFileIcon,
  StyledHrefLink,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledLogoBlock,
  StyledPersonIcon,
  StyledWorkIcon,
} from './styles';

interface DrawerProps {
  handleToggleDrawer: () => void;
  isDrawerOpen: boolean;
}

const Drawer: React.FC<DrawerProps> = ({
  handleToggleDrawer,
  isDrawerOpen,
}) => {
  const { isAllowedToRead: isAllowedToReadSubscription } = useCanAccess(
    'user/subscription'
  );
  const { signOut, userInfo } = useContext(AuthContext);
  const { appCShortcutIconUrl, appCUrl } = clientSideScheme();

  const isCaregiverAppAccess = userInfo?.user?.role?.caregiverAppAccess;
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const handleToggleLogoutDialog = () => {
    setIsLogoutDialogOpen(!isLogoutDialogOpen);
  };

  return (
    <>
      <StyledDrawer
        open={isDrawerOpen}
        onClose={() => {
          handleToggleDrawer();
        }}
      >
        <DrawerHeader>
          <IconButton
            onClick={() => {
              handleToggleDrawer();
            }}
          >
            <StyledCloseIcon fontSize="large" />
          </IconButton>
          <StyledLogoBlock />
        </DrawerHeader>
        <DrawerBody>
          <StyledList>
            <StyledLink to={'/minha-conta/pessoais'} key={'Minha conta'}>
              <StyledListItem button>
                <ListItemIcon>{<StyledPersonIcon />}</ListItemIcon>
                <StyledListItemText>{'Minha conta'}</StyledListItemText>
              </StyledListItem>
            </StyledLink>
            <StyledHrefLink
              href={'https://www.cuidadordeconfianca.com.br/termos-e-politicas'}
              key={'Termos de uso'}
            >
              <StyledListItem button>
                <ListItemIcon>{<StyledFileIcon />}</ListItemIcon>
                <StyledListItemText>{'Termos de uso'}</StyledListItemText>
              </StyledListItem>
            </StyledHrefLink>
            {isAllowedToReadSubscription && (
              <StyledLink to={'/plano'} key={'Plano'}>
                <StyledListItem button>
                  <ListItemIcon>{<StyledWorkIcon />}</ListItemIcon>
                  <StyledListItemText>{'Plano'}</StyledListItemText>
                </StyledListItem>
              </StyledLink>
            )}
            <StyledLink
              to={'/em-construcao/servicos-de-saude'}
              key={'Serviços de saúde'}
            >
              <StyledListItem button>
                <ListItemIcon>{<img src={HealthServices} />}</ListItemIcon>
                <StyledListItemText>{'Serviços de saúde'}</StyledListItemText>
              </StyledListItem>
            </StyledLink>
            <StyledLink
              to={'/em-construcao/produtos-de-saude'}
              key={'Produtos de saúde'}
            >
              <StyledListItem button>
                <ListItemIcon>
                  {
                    <HealthProductsIconWithMargin
                      width="28px"
                      height="28px"
                      src={HealthProducts}
                    />
                  }
                </ListItemIcon>
                <StyledListItemText>{'Produtos de saúde'}</StyledListItemText>
              </StyledListItem>
            </StyledLink>
            {Boolean(isCaregiverAppAccess) && (
              <StyledHrefLink href={appCUrl}>
                <AppShortcut
                  icon={<AppIcon src={appCShortcutIconUrl} />}
                  label={'Ir para o app de Cuidados'}
                />
              </StyledHrefLink>
            )}
          </StyledList>
        </DrawerBody>
        <DrawerFooter>
          <DrawerButton
            onClick={() => {
              handleToggleLogoutDialog();
            }}
          >
            <SignOutIcon />
            <DrawerFooterText>Sair da conta</DrawerFooterText>
          </DrawerButton>
        </DrawerFooter>
      </StyledDrawer>
      <LogoutDialog
        open={isLogoutDialogOpen}
        onConfirm={signOut}
        onClose={handleToggleLogoutDialog}
      />
    </>
  );
};

export default Drawer;
