import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderWithSignOut from '../../../components/Headers/HeaderWithSignOut';
import { AuthContext } from '../../../contexts/auth';
import {
  Container,
  CardContainer,
  CardText,
  StyledWarningIcon,
} from '../styles';

const NoPatientRelationsBlock: React.FC = () => {
  const { userInfo, refreshUserInfo } = useContext(AuthContext);
  const history = useHistory();

  const userHasRelatedPatients = Number(userInfo?.user?.patients?.length) > 0;

  const handleRefreshUserInfo = async () => {
    try {
      await refreshUserInfo();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleRefreshUserInfo();

    if (userHasRelatedPatients) {
      history.push('/');
    }
  }, [userHasRelatedPatients]);

  return (
    <>
      <HeaderWithSignOut title="" />
      <Container>
        <CardContainer>
          <StyledWarningIcon color="primary" />
          <CardText color="primary" data-testid="first-block">
            Você não está vinculado a nenhuma rede de cuidado ainda.
          </CardText>
          <CardText color="primary" data-testid="second-block">
            Para começar a usar o app, entre em contato com a pessoa que
            adicionou você e solicite o vínculo.
          </CardText>
        </CardContainer>
      </Container>
    </>
  );
};

export default NoPatientRelationsBlock;
