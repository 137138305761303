import { PatientModel } from '@cuidador/database';
import { AxiosResponse } from 'axios';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';
import useMedia from './useMedia';

export interface GetPatientPaginatedRequestParams
  extends PaginatedRequestParams {
  customStatus?: CustomStatus[];
  withRelatedUserId?: number;
}

export type CustomStatus = 'enabled' | 'disabled' | 'pending';
export type PatientQueryType = 'relatedPatients' | 'organizationPatients';

const endpoint = '/user/patient';

const initialData: ReducerData<PatientModel> = {
  byId: {} as Record<string, Item<PatientModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const usePatient = () => {
  const { uploadMedia, getMedia } = useMedia();
  const [state, dispatch] = useReducer(
    createReducer<PatientModel>(),
    initialData
  );

  const post = useCallback(async (data: PatientModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(async (id: Id, data: Partial<PatientModel>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/${id}`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const uploadProfilePicture = useCallback(
    async (file: File, patientId: Id) => {
      try {
        const response = await uploadMedia(
          `/media/profile-picture/patient/${patientId}`,
          file
        );
        return Promise.resolve(response);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const getProfilePicture = useCallback(async (id: number) => {
    try {
      const response = await getMedia(`/media/profile-picture/patient/${id}`);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const getRelatedPatientsPaginated = useCallback(
    async (params: GetPatientPaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get<{
          results: Item<PatientModel>[];
          total: number;
        }>(endpoint, { params });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getOrganizationPatientsPaginated = useCallback(
    async (params: GetPatientPaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get<{
          results: Item<PatientModel>[];
          total: number;
        }>(`${endpoint}/by-organization`, { params });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response as AxiosResponse<PatientModel>);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const setActive = useCallback(async (id: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/activate/${id}`);
      dispatch({ type: 'UPDATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    post,
    uploadProfilePicture,
    getProfilePicture,
    getRelatedPatientsPaginated,
    getOrganizationPatientsPaginated,
    getById,
    patch,
    setActive,
  };
};

export default usePatient;
