import { DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import StyledDialog from '../../components/StyledDialog';
import {
  CenterLayout,
  StyledDialogContent,
  StyledTextButton,
  StyledWhiteButton,
} from './styles';
type BuyMedicationDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  isLoading: boolean;
};

export default function BuyMedicationDialog({
  isOpen,
  onConfirm,
  onClose,
  isLoading,
}: BuyMedicationDialogProps) {
  return (
    <StyledDialog open={isOpen} onClose={onClose} data-testid="buy-dialog">
      <StyledDialogContent>
        <DialogTitle>
          Receba os medicamentos em casa pela parceria com a Far.me
        </DialogTitle>
        <Typography align="center" paragraph>
          Enviaremos a lista de medicamentos e a Far.me entrará em contato com
          você dentro de 1 hora com o orçamento, no horário comercial
        </Typography>
        <Typography align="center" variant="subtitle2" paragraph>
          (seg. a sex. de 9hs às 18hs, sábado de 10hs às 19hs)
        </Typography>
        <Typography align="center" paragraph>
          Para agilizar seu atendimento, esteja com as receitas dos medicamentos
          em mãos.
        </Typography>
        <CenterLayout>
          <StyledWhiteButton
            onClick={onConfirm}
            color="inherit"
            size="large"
            data-testid="confirm-dialog"
            disabled={isLoading}
          >
            Quero um orçamento
          </StyledWhiteButton>
          <StyledTextButton
            onClick={onClose}
            color="inherit"
            data-testid="close-dialog"
            disabled={isLoading}
          >
            Não, obrigado
          </StyledTextButton>
        </CenterLayout>
      </StyledDialogContent>
    </StyledDialog>
  );
}
