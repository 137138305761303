import { AddRounded } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Fab from '../../../components/Fab';
import Header from '../../../components/Headers/Header';
import MeasurementScheduleCard from '../../../components/MeasurementScheduleCard';
import ReportDownloadButton from '../../../components/ReportDownloadButton';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useMeasurementSchedule from '../../../hooks/useMeasurementSchedule';
import {
  Backdrop,
  BackdropCircularProgress,
  Container,
  ReportContainer,
  StyledTypography,
  TypographyContainer,
} from './styles';

const MeasurementScheduleList: React.FC = () => {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);
  const [downloadLink, setDownloadLink] = useState('');
  const patientId = userInfo?.activePatientId;

  const {
    loading,
    getAllByPatientId,
    ids: measurementScheduleIds,
    byId,
    createReportByPatientId,
    getReportDownloadLinkByPatientId,
  } = useMeasurementSchedule();

  useEffect(() => {
    getMeasurementsByPatient();
  }, [patientId]);

  const getMeasurementsByPatient = () => {
    if (patientId) {
      getAllByPatientId(patientId);
    }
  };

  const { isAllowedToCreate } = useCanAccess('care/event/schedule');
  const { isAllowedToCreate: isAllowedToCreateReport } = useCanAccess(
    'report/measurement'
  );
  const { isAllowedToRead: isAllowedToReadReport } = useCanAccess(
    'media/patient/report/measurement'
  );
  const handleCreateReportAndGetDownloadLink = async () => {
    createMeasurementReport()
      .then(() => getMeasurementReportDownloadUrl())
      .then((signedUrl) => setDownloadLink(signedUrl));
  };

  const createMeasurementReport = async () => {
    try {
      await createReportByPatientId(Number(userInfo?.activePatientId));
      toast.success('Relátorio de controles gerado com sucesso');
    } catch (err) {
      toast.error('Erro ao gerar relatório de controles');
    }
  };

  const getMeasurementReportDownloadUrl = async (): Promise<string> => {
    try {
      const { signedUrl } = await getReportDownloadLinkByPatientId(
        Number(userInfo?.activePatientId)
      );
      return signedUrl;
    } catch (err) {
      toast.error('Erro ao baixar relatório de controles');
      return '';
    }
  };

  return (
    <>
      <Header
        title="Controles"
        leftIconClick={() => history.push('/cadastros')}
        rightButtonType="pscList"
      />
      {loading ? (
        <Backdrop open={loading}>
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        </Backdrop>
      ) : (
        <>
          <Container>
            {measurementScheduleIds.length <= 0 ? (
              <TypographyContainer>
                <StyledTypography variant="body2">
                  Ainda não há agendamento de controles cadastrados.
                </StyledTypography>
              </TypographyContainer>
            ) : (
              <>
                {isAllowedToReadReport && isAllowedToCreateReport && (
                  <ReportContainer>
                    <ReportDownloadButton
                      createTitle="Clique aqui para gerar o relatório de controles"
                      downloadtitle="Clique aqui para baixar o relatório de controles"
                      onGenerateReportClick={
                        handleCreateReportAndGetDownloadLink
                      }
                      downloadLink={downloadLink}
                    />
                  </ReportContainer>
                )}
                {measurementScheduleIds
                  .map((id) => byId[id])
                  .map((measurementSchedule) => (
                    <MeasurementScheduleCard
                      key={measurementSchedule.id}
                      eventSchedule={measurementSchedule}
                    />
                  ))}
              </>
            )}
          </Container>
          {isAllowedToCreate && (
            <Fab to={'/controles/novo'}>
              <AddRounded />
            </Fab>
          )}
        </>
      )}
    </>
  );
};

export default MeasurementScheduleList;
