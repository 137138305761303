import React from 'react';
import MedicationFirstStep from './MedicationFirstStep';
import MedicationSecondStep from './MedicationSecondStep';
import {
  FormValues,
  MedicationInsertFormStep,
  MedicationItem,
} from './MedicationSecondStep/utils';
import { Container } from './styles';

interface Props {
  step: MedicationInsertFormStep;
  initialValues: FormValues;
  handleSubmit: (values: FormValues) => void;
  // optional values, because this form is also used to update medication, and that does not require these parameters
  allMedication?: MedicationItem[];
  handleChangeMedicationText?: (textValue: string) => void;
  handleSelectMedication?: (medication: MedicationItem) => void;
  handleStepChange?: (step: MedicationInsertFormStep) => void;
  id?: number;
  disabled?: boolean;
}

const MedicationInsertForm: React.FC<Props> = ({
  step,
  initialValues,
  handleSubmit,
  allMedication,
  handleChangeMedicationText,
  handleSelectMedication,
  id,
  disabled,
}) => {
  const renderStep = (step: MedicationInsertFormStep) => {
    switch (step) {
      case MedicationInsertFormStep.SelectMedication:
        return (
          <MedicationFirstStep
            allMedication={allMedication || undefined}
            handleChangeMedicationText={handleChangeMedicationText || undefined}
            handleSelectMedication={handleSelectMedication || undefined}
          />
        );
      case MedicationInsertFormStep.FillDetails:
        return (
          <MedicationSecondStep
            initialValues={initialValues}
            onSubmit={handleSubmit}
            id={id}
            disabled={disabled}
          />
        );
      default:
        return null;
    }
  };

  return <Container>{renderStep(step)}</Container>;
};

export default MedicationInsertForm;
