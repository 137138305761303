import { Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  background-color: #fff;
  border-radius: 4px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const RealTimeContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.yellow};
  color: black;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledBoldTitle = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.extra.color.yellow};
`;

export const Instructions = styled(Typography)`
  line-height: 14px;
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const MeasurementInput = styled.input`
  border: 1px solid ${({ theme }) => theme.palette.extra.color.grey.main};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  opacity: 1;
  width: 100%;
`;

export const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: ${({ theme }) => theme.spacing(0.5)}px;
  width: 100%;
`;

export const NoteTypography = styled(Typography)`
  line-height: 1.2;
  overflow-wrap: break-word;
`;

interface CheckButtonProps {
  isChecked: boolean;
}

export const CloseIconContainer = styled.button<CheckButtonProps>`
  ${({ theme, isChecked }) => {
    const background = isChecked
      ? theme.palette.extra.color.red.main
      : theme.palette.common.white;

    const iconColor = isChecked
      ? theme.palette.primary.light
      : theme.palette.extra.color.grey.main;

    const borderColor = isChecked
      ? background
      : theme.palette.extra.color.grey.main;

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 2px solid ${borderColor};
      color: ${iconColor};
      background-color: ${background};
      border-radius: 100%;
    `;
  }}
`;

export const StyledCloseIcon = styled(Close)`
  width: 15px;
  height: 15px;
`;

export const CheckIconContainer = styled.div<CheckButtonProps>`
  ${({ theme, isChecked }) => {
    const background = isChecked
      ? theme.palette.extra.color.green
      : theme.palette.common.white;

    const iconColor = isChecked
      ? theme.palette.primary.light
      : theme.palette.extra.color.grey.main;

    const borderColor = isChecked
      ? background
      : theme.palette.extra.color.grey.main;

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      width: 20px;
      height: 20px;
      border: 2px solid ${borderColor};
      color: ${iconColor};
      background-color: ${background};
      border-radius: 100%;
    `;
  }}
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 15px;
  height: 15px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const CardFooter = styled.footer`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(1, 1, 0, 1)};
`;

export const CaregiverTypography = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &::before {
    content: 'por ';
    font-weight: 400;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1, 1, 0, 1)};
`;
