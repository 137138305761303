import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppointmentsUpsertForm, {
  appointmentModelToFormData,
  formDataToAppointmentModel,
  FormValues,
} from '../../../components/AppointmentsUpsertForm';
import Header from '../../../components/Headers/Header';
import { StyledFieldset } from '../../../components/StyledFieldset';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import { AuthContext } from '../../../contexts/auth';
import useAppointment from '../../../hooks/useAppointment';
import useCanAccess from '../../../hooks/useCanAccess';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress } from '../styles';
import { Container } from './styles';

const AppointmentsUpdate: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { getById, byId, loading, patch, remove } = useAppointment();
  const [dialogStatus, setDialogStatus] = useState(false);
  const formikRef = useRef<FormikProps<FormValues> | null>(null);
  const history = useHistory();
  const { isAllowedToDelete, isAllowedToUpdate } = useCanAccess(
    'care/event/appointment'
  );
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const id = parseInt(params.id);

  useEffect(
    function fetchInitialValues() {
      // If 'id' exists, it's an Update form.
      // Else, it's a creation
      if (id) {
        getById(id);
      }
    },
    [id]
  );

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  const appointment = byId[id];

  if (!appointment) {
    return null;
  }

  const handleSubmit = (values: FormValues) => {
    return patch(id!, formDataToAppointmentModel(values, patientId!))
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleDelete = (id: number) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        title="Editar Compromisso"
        rightButtonType={isAllowedToDelete ? 'remove' : undefined}
        rightIconClick={() => setDialogStatus(true)}
      />
      <Container>
        <StyledFieldset disabled={!isAllowedToUpdate}>
          <AppointmentsUpsertForm
            innerRef={(ref) => (formikRef.current = ref)}
            initialValues={appointmentModelToFormData(appointment)}
            onSubmit={handleSubmit}
            disabled={!isAllowedToUpdate}
          />
        </StyledFieldset>
        <StyledSimpleDialog
          open={dialogStatus}
          handleNo={() => setDialogStatus(false)}
          handleYes={() => handleDelete(id!)}
          title="Excluir Compromisso"
          subTitle="Tem certeza que deseja excluir o Compromisso?"
        />
      </Container>
    </>
  );
};

export default AppointmentsUpdate;
