import * as Sentry from '@sentry/react';
import { FormikProps } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../components/Headers/Header';
import PatientUpsertForm from '../../../components/PatientUpsertForm';
import {
  formDataToPatientModel,
  FormValues,
  initialValues,
  patientModelToFormData,
} from '../../../components/PatientUpsertForm/utils';
import { StyledFieldset } from '../../../components/StyledFieldset';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import usePatient from '../../../hooks/usePatient';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress, Container } from '../styles';

const PatientUpdate: React.FC = () => {
  const history = useHistory();
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const {
    patch,
    uploadProfilePicture,
    getProfilePicture,
    loading,
  } = usePatient();
  const { userInfo, refreshUserInfo } = useContext(AuthContext);
  const [pictureUrl, setPictureUrl] = useState('');
  const patientId = userInfo?.activePatientId;
  const { isAllowedToUpdate } = useCanAccess('user/patient');
  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );

  const loadUserImage = async () => {
    if (!patientId) return;
    const response = isAllowedToReadProfilePicture
      ? await getProfilePicture(patientId)
      : null;
    setPictureUrl(response?.data?.signedUrl || '');
  };

  useEffect(() => {
    if (!patientId) history.replace('/');
    loadUserImage();
  }, []);

  const patientFormData = userInfo?.activePatient
    ? patientModelToFormData(userInfo.activePatient)
    : initialValues;

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!patientId) return;
      await patch(patientId, formDataToPatientModel(values));
      if (values.picture) {
        try {
          await uploadProfilePicture(values.picture, patientId);
        } catch (err) {
          toast.error(
            'Houve um erro durante o envio de sua imagem, tente novamente mais tarde'
          );
          Sentry.captureException(err);
        }
      }
      refreshUserInfo();
      toast.success('Cadastro da Pessoa Sob Cuidado atualizado');
      history.push('/cadastros');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const displayMessage = resolveErrorMessage(err);
      const fieldError = err.response?.data.context?.key;
      if (fieldError && Object.keys(patientFormData).includes(fieldError)) {
        formikRef.current?.setFieldError(fieldError, displayMessage || '');
      } else {
        toast.error(displayMessage);
      }
    }
  };

  return (
    <>
      <Header
        title="Pessoa Sob Cuidado"
        rightButtonType="pscList"
        leftIconClick={() => history.push('/cadastros')}
      />
      <Container>
        <Backdrop open={loading}>
          {loading && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
        <StyledFieldset disabled={!isAllowedToUpdate}>
          <PatientUpsertForm
            initialValues={patientFormData}
            onSubmit={handleSubmit}
            innerRef={(ref) => (formikRef.current = ref)}
            formikRef={formikRef}
            initialPictureUrl={pictureUrl}
            editingPatient={true}
            disabled={!isAllowedToUpdate}
          />
        </StyledFieldset>
      </Container>
    </>
  );
};

export default PatientUpdate;
