import { Avatar } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import usePatient from '../../../hooks/usePatient';
import HeaderWithDrawer from '../HeaderWithDrawer';
import { CenterContainer, PhotoElement, Title } from './styles';

const PatientHeader: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const [imageUrl, setImageUrl] = useState('');
  const { getProfilePicture } = usePatient();

  const { name = '', id: patientId } = userInfo?.activePatient || {};

  const handleGetProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
    } catch (err) {
      console.log('error');
    }
  };

  useEffect(() => {
    async function fetchPatientProfilePicture() {
      if (!patientId) return;
      const result = await handleGetProfilePicture(parseInt(`${patientId}`));
      if (result) {
        setImageUrl(result);
      }
    }

    fetchPatientProfilePicture();
  }, []);

  return (
    <HeaderWithDrawer
      centerContent={
        <CenterContainer>
          <PhotoElement>{<Avatar src={imageUrl} />}</PhotoElement>
          <Title align="center" variant="h6">
            {name}
          </Title>
        </CenterContainer>
      }
    />
  );
};

export default PatientHeader;
