import { APIError } from '@cuidador/lib';
import { Checkbox, FormHelperText } from '@material-ui/core';
import { AxiosError } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import qs from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FormCardContainer,
  StyledBoldTitle,
  StyledFormikSelect,
  StyledFormikTextField,
  StyledFormikTextPassword,
} from '../../components/FormCardContainer';
import FormikTextField from '../../components/Forms/FormikTextField';
import HeaderBase from '../../components/Headers/HeaderBase';
import MedicBalloon from '../../components/MedicBalloon';
import PasswordRules from '../../components/PasswordRules';
import StyledButton from '../../components/StyledButton';
import StyledMenuItem from '../../components/StyledMenuItem';
import { AuthContext } from '../../contexts/auth';
import useGuardian from '../../hooks/useGuardian';
import usePlan from '../../hooks/usePlan';
import { resolveErrorMessage } from '../../utils/error';
import { cpfMask, numberMask, phoneMask } from '../../utils/inputs';
import {
  Background,
  ContentContainer,
  Line,
  Logo,
  LogoContainer,
  StyledFormControlLabel,
  TermsContainer,
  Title,
  TitleBold,
  VisibilityContainer,
} from './styles';
import {
  formDataToGuardianModel,
  FormValues,
  howMetUsOptions,
  initialValues,
  relationToPatientOptions,
  validationSchema,
} from './utils';

const GuardianRegister: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const formikValuesRef = useRef<FormikProps<FormValues> | null>();
  const { post, ids } = useGuardian();
  const { getById } = usePlan();
  const {
    signIn,
    error: signInError,
    setPromotionPlanId,
    setCompanyPlanId,
  } = useContext(AuthContext);
  const [signInLoading, setSignInLoading] = useState(false);

  const { etapa: step } = qs.parse(location.search);
  const { plan: planId } = qs.parse(location.search);
  const successVisible = step === 'sucesso';
  const formVisible = !successVisible;
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (planId) {
      getById(planId.toString())
        .then((plan) => {
          if (plan.isB2B) {
            toast.success('Plano empresarial aplicado!');
            setCompanyPlanId(planId as string);
          } else {
            toast.success('Plano promocional aplicado!');
            setPromotionPlanId(planId as string);
          }
        })
        .catch(() => toast.error('Erro ao obter plano - plano inválido!'));
    }

    // avoids direct access to success page
    if (step === 'sucesso' && !ids.length) {
      history.replace('/onboarding');
      return;
    }

    // avoids returning to any step after success
    if (step !== 'sucesso' && ids.length) {
      history.replace(`${location.pathname}?etapa=sucesso`);
      return;
    }
  }, [location.search]);

  useEffect(
    function removeLoadingOnSignInError() {
      if (signInError) {
        toast.error(resolveErrorMessage(signInError));
        setSignInLoading(false);
      }
    },
    [signInError]
  );

  const submitGuardianInfo = (values: FormValues) => {
    return post(formDataToGuardianModel({ ...values! }))
      .then(() => {
        handleSignIn();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        const fieldError = err.response?.data.context?.key;
        if (fieldError) {
          if (Object.keys(initialValues).includes(fieldError)) {
            formikValuesRef.current?.setFieldError(
              fieldError,
              displayMessage || ''
            );
            history.goBack();
          }
        } else {
          toast.error(displayMessage);
        }
      });
  };

  const handleSignIn = () => {
    setSignInLoading(true);
    const { cpf, password } = formikValuesRef.current?.values as FormValues;
    return signIn(numberMask(cpf), password);
  };

  return (
    <>
      <HeaderBase
        centerContent={
          <LogoContainer>
            <Logo />
          </LogoContainer>
        }
      />
      <Background>
        <VisibilityContainer $active={formVisible}>
          <MedicBalloon
            text={
              'Muito prazer, eu sou a Augusta, vou te acompanhar durante nossa jornada.\n\nEstamos muito felizes em ter você aqui com a gente! Juntos, queremos fazer do cuidado confiável, algo simples e possível, com disciplina, educação e atenção.\n\nPara começarmos, precisamos de alguns dados para o seu cadastro.'
            }
          />

          <Formik
            validateOnChange={false}
            innerRef={(ref) => (formikValuesRef.current = ref)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitGuardianInfo}
          >
            {({ values, setFieldValue, errors }) => (
              <Form noValidate>
                <ContentContainer>
                  <FormCardContainer>
                    <StyledBoldTitle variant="subtitle2">
                      Informações pessoais
                    </StyledBoldTitle>

                    <StyledFormikTextField
                      color="secondary"
                      placeholder="Nome completo"
                      name="name"
                      label="Nome completo"
                      margin="normal"
                      inputProps={{ 'data-testid': 'name' }}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="CPF"
                      name="cpf"
                      label="CPF"
                      type="tel" // numeric keyboard without parsing to number
                      margin="normal"
                      inputProps={{ 'data-testid': 'cpf' }}
                      value={cpfMask(values.cpf)}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="Telefone"
                      name="phoneNumber"
                      label="Telefone"
                      type="tel"
                      margin="normal"
                      inputProps={{ 'data-testid': 'phoneNumber' }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('phoneNumber', phoneMask(e.target.value));
                      }}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      label="E-mail"
                      margin="normal"
                      inputProps={{ 'data-testid': 'email' }}
                    />
                  </FormCardContainer>

                  <FormCardContainer>
                    <StyledBoldTitle variant="subtitle2">
                      Outras informações
                    </StyledBoldTitle>

                    <StyledFormikSelect
                      color="secondary"
                      name="howMetUs"
                      label="Como nos conheceu?"
                      SelectDisplayProps={{
                        'data-testid': 'howMetUs',
                      }}
                    >
                      {howMetUsOptions.map((item) => (
                        <StyledMenuItem key={item.value} value={item.value}>
                          {item.label}
                        </StyledMenuItem>
                      ))}
                    </StyledFormikSelect>
                    <StyledFormikSelect
                      color="secondary"
                      name="relationToPatient"
                      label="Relação com o paciente"
                      SelectDisplayProps={{
                        'data-testid': 'relationToPatient',
                      }}
                    >
                      {relationToPatientOptions.map((item) => (
                        <StyledMenuItem key={item.value} value={item.value}>
                          {item.label}
                        </StyledMenuItem>
                      ))}
                    </StyledFormikSelect>
                  </FormCardContainer>
                  <FormCardContainer>
                    <StyledBoldTitle variant="subtitle2">
                      Crie sua senha
                    </StyledBoldTitle>
                    <FormikTextField
                      as={StyledFormikTextPassword}
                      name="password"
                      label="Senha"
                      placeholder="Senha"
                      margin="normal"
                      inputProps={{ 'data-testid': 'password' }}
                    />
                    <FormikTextField
                      as={StyledFormikTextPassword}
                      name="passwordConfirmation"
                      label="Confirmar senha"
                      placeholder="Confirmar senha"
                      margin="normal"
                      inputProps={{ 'data-testid': 'passwordConfirmation' }}
                    />
                    <PasswordRules
                      password={values.password}
                      validationError={validationError}
                    />
                  </FormCardContainer>
                </ContentContainer>

                <TermsContainer>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        name="termsAccepted"
                        value={values.termsAccepted}
                        onChange={() =>
                          setFieldValue('termsAccepted', !values.termsAccepted)
                        }
                      />
                    }
                    label={
                      <a
                        href="https://www.cuidadordeconfianca.com.br/termos-e-politicas"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Eu li e aceito os Termos de Uso e Políticas de
                        Privacidade
                      </a>
                    }
                  />
                  {errors.termsAccepted && (
                    <FormHelperText error>
                      {errors.termsAccepted}
                    </FormHelperText>
                  )}
                </TermsContainer>

                <StyledButton
                  size="large"
                  color="secondary"
                  disabled={signInLoading}
                  type="submit"
                  data-testid="signIn-submit"
                  onClick={() => setValidationError(true)}
                >
                  Criar conta
                </StyledButton>
              </Form>
            )}
          </Formik>

          <Line />
          <Title>Já tem uma conta?</Title>
          <TitleBold onClick={history.goBack}>Entrar</TitleBold>
        </VisibilityContainer>
      </Background>
    </>
  );
};

export default GuardianRegister;
