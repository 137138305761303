import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { parse } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../components/Headers/Header';
import MedicationUpsertForm from '../../../components/MedicationUpsertForm';
import {
  formDataToMedicationModel,
  FormValues,
  medicationModelToFormValues,
} from '../../../components/MedicationUpsertForm/utils';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import { AuthContext } from '../../../contexts/auth';
import useMedication from '../../../hooks/useMedication';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress, Container } from './styles';

const MedicationUpdate: React.FC = () => {
  const [dialogStatus, setDialogStatus] = useState(false);
  const [page, setPage] = useState(1);
  const params = useParams<{ id: string }>();
  const { getById, byId, loading, patch, remove } = useMedication();

  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const id = parseInt(params.id);
  const history = useHistory();

  useEffect(
    function fetchInitialValues() {
      // If 'id' exists, it's an Update form.
      // Else, it's a creation
      if (id) {
        getById(id);
      }
    },
    [id]
  );

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  const medication = byId[id];

  if (!medication) {
    return null;
  }

  const handleSubmit = (values: FormValues) => {
    const today = new Date();
    const date = !values.ifNecessary
      ? parse(
          `${values.frequencyStartsAt} 23:59`,
          'yyyy-MM-dd HH:mm',
          new Date()
        )
      : '';

    if (!values.ifNecessary && today > date) {
      toast.error('Coloque a data de início hoje ou em um dia futuro');
      return false;
    }

    return patch(id!, formDataToMedicationModel(values, patientId!))
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleDelete = (id: Id) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handlePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Header
        title="Editar Medicamento"
        rightButtonType="remove"
        rightIconClick={() => setDialogStatus(true)}
        leftIconClick={() => (page !== 1 ? handlePage(1) : history.goBack())}
      />
      <Container>
        <MedicationUpsertForm
          initialValues={medicationModelToFormValues(medication)}
          onSubmit={handleSubmit}
          page={page}
          handlePage={handlePage}
        />
        <StyledSimpleDialog
          open={dialogStatus}
          handleNo={() => setDialogStatus(false)}
          handleYes={() => handleDelete(id!)}
          title="Excluir Medicamento"
          subTitle="Tem certeza que deseja excluir o Medicamento?"
        />
      </Container>
    </>
  );
};

export default MedicationUpdate;
