import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../components/Headers/Header';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import { StyledFieldset } from '../../components/StyledFieldset';
import StyledTabs from '../../components/StyledTabs';
import useCanAccess from '../../hooks/useCanAccess';
import ChangePassword from './ChangePassword';
import Payment from './Payment';
import Personal from './Personal';
import { ButtonContainer, Container, StyledTabItem } from './styles';

const Account: React.FC<RouteComponentProps<{ tabId: string }>> = (props) => {
  const currentTab = props.match.params.tabId;

  const { isAllowedToUpdate } = useCanAccess('user/guardian');
  const { isAllowedToRead: isAllowedToReadSubscription } = useCanAccess(
    'user/subscription'
  );

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.replace(`/minha-conta/${newValue}`);
  };

  return (
    <>
      <Header title="Meus dados" />
      <PendingRegistrationBanner />
      <Container>
        <ButtonContainer>
          <StyledTabs value={currentTab} onChange={handleChange}>
            <StyledTabItem value="pessoais" label="Pessoais" />
            <StyledTabItem value="senha" label="Senha" />
            {isAllowedToReadSubscription && (
              <StyledTabItem value="pagamento" label="Pagamento" />
            )}
          </StyledTabs>
        </ButtonContainer>
        <StyledFieldset disabled={!isAllowedToUpdate}>
          {currentTab === 'pessoais' && <Personal />}
        </StyledFieldset>

        {currentTab === 'senha' && <ChangePassword />}
        {currentTab === 'pagamento' && <Payment />}
      </Container>
    </>
  );
};

export default Account;
