import React, { useCallback } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import useCanAccess from '../../../../hooks/useCanAccess';
import { Tabs } from '@material-ui/core';
import { RouteParams } from './utils';
import { TabbarContainer, StyledTab } from './styles';

const Tabbar: React.FC = () => {
  const history = useHistory();
  const { tab } = useParams<RouteParams>();
  const { isAllowedToRead: isAllowedToReadOrganizationMembers } = useCanAccess(
    'user/user.by-organization'
  );

  const resolveTabOptions = (isAllowedToReadOrganizationMembers: boolean) => {
    return [
      {
        key: 'pacientes',
        label: 'Pacientes',
        shouldRender: true,
      },
      {
        key: 'usuarios',
        label: 'Usuários',
        shouldRender: isAllowedToReadOrganizationMembers,
      },
    ].filter(({ shouldRender }) => shouldRender);
  };

  const tabOptions = resolveTabOptions(isAllowedToReadOrganizationMembers);

  const handleChange = useCallback((_, value) => {
    history.replace(`/pessoas/${value}`);
  }, []);

  if (!tab || !tabOptions.some((item) => item.key === tab)) {
    return <Redirect to="/pessoas/pacientes" />;
  }

  return (
    <TabbarContainer>
      <Tabs value={tab} onChange={handleChange}>
        {tabOptions.map(({ key, label }) => (
          <StyledTab key={key} value={key} label={label} />
        ))}
      </Tabs>
    </TabbarContainer>
  );
};
export default Tabbar;
