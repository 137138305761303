import { EventModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import MesurementIcon from '../../../../assets/Icon-mesurement.svg';
import { TimerContainer } from '../styles';
import Measurement from './Measurement';

import { CardBox, LeftContainer, RightContainer } from './styles';

interface CardProps {
  scheduledMeasurements: EventModel[];
  readonly?: boolean;
}

const ScheduledMeasurementCard: React.FC<CardProps> = ({
  scheduledMeasurements,
}) => {
  const { eventScheduleId, eventHappensAt } = scheduledMeasurements[0];

  const hour = format(new Date(`${eventHappensAt}`), 'HH:mm');

  return (
    <CardBox data-testid={`measurementCard-${eventScheduleId}`}>
      <LeftContainer>
        <img
          src={MesurementIcon}
          title={`measurement-image-${eventScheduleId}`}
        />
        <TimerContainer>
          <Typography variant="h6">{hour}</Typography>
        </TimerContainer>
      </LeftContainer>
      <RightContainer>
        {scheduledMeasurements?.map((measurement) => {
          return (
            <div key={measurement.id}>
              <Measurement
                caregiverName={measurement.caregiver?.user?.name}
                {...measurement}
                id={measurement.id!}
              />
            </div>
          );
        })}
      </RightContainer>
    </CardBox>
  );
};

export default ScheduledMeasurementCard;
