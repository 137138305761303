import { ShiftModel } from '@cuidador/database';
import DateFnsUtils from '@date-io/date-fns';
import { Checkbox } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/DateRange';
import TimeIcon from '@material-ui/icons/WatchLater';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import { orderBy } from 'lodash';
import { default as React, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import FormikTextField from '../../components/Forms/FormikTextField';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import ShiftTimeContestationDateBlock from '../../components/ShiftTimeContestationDateBlock';
import StyledFormControlLabel from '../../components/StyledFormControlLabel';
import useShift from '../../hooks/useShift';
import useShiftTimeContestation from '../../hooks/useShiftExecutionTimeContestation';
import { resolveErrorMessage } from '../../utils/error';
import ConfirmSaveDialog from './ConfirmSaveDialog';
import { DatePicker, TimePicker } from './DateTimePickers';
import {
  Body,
  Button,
  Container,
  DateTimeCheckboxGroup,
  DateTimePickersGroup,
  Form,
  FormInnerContainer,
  JustificationContainer,
  StyledOptions,
  TopGuidelineParagraph,
} from './styles';

export type FormValues = {
  startedAtDate: Date | null | undefined;
  startedAtTime: Date | null | undefined;
  endedAtDate: Date | null | undefined;
  endedAtTime: Date | null | undefined;
  justification: string;
  startedDatetimeEnabled: boolean;
  endedDatetimeEnabled: boolean;
  isSubmitConfirmationModalOpen: boolean;
};

const validationSchema = yup.object().shape({
  startedAtDate: yup
    .date()
    .nullable()
    .typeError('Por favor, insira uma data válida')
    .when(
      'startedDatetimeEnabled',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (startedDatetimeEnabled: any, schema: any) =>
        startedDatetimeEnabled
          ? schema.required('Por favor, insira uma data de início')
          : schema
    ),
  startedAtTime: yup
    .date()
    .nullable()
    .typeError('Por favor, insira uma data válida')
    .when(
      'startedDatetimeEnabled',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (startedDatetimeEnabled: any, schema: any) =>
        startedDatetimeEnabled
          ? schema.required('Por favor, insira um horário de início')
          : schema
    ),
  endedAtDate: yup
    .date()
    .nullable()
    .typeError('Por favor, insira uma data válida')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when('endedDatetimeEnabled', (endedDatetimeEnabled: any, schema: any) =>
      endedDatetimeEnabled
        ? schema.required('Por favor, insira uma data de fim')
        : schema
    ),
  endedAtTime: yup
    .date()
    .nullable()
    .typeError('Por favor, insira uma data válida')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when('endedDatetimeEnabled', (endedDatetimeEnabled: any, schema: any) =>
      endedDatetimeEnabled
        ? schema.required('Por favor, insira um horário de fim')
        : schema
    ),
  justification: yup
    .string()
    .nullable()
    .typeError('Por favor, insira uma justificativa válida')
    .required('Por favor, insira uma justificativa'),
});

const CreateTimeContestationForm: React.FC<{
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  minTimeContestationDatetime?: Date;
  maxTimeContestationDatetime?: Date;
}> = ({
  onSubmit,
  onCancel,
  minTimeContestationDatetime,
  maxTimeContestationDatetime,
}) => {
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        startedAtDate: null,
        startedAtTime: null,
        endedAtDate: null,
        endedAtTime: null,
        justification: '',
        startedDatetimeEnabled: false,
        endedDatetimeEnabled: false,
        isSubmitConfirmationModalOpen: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        submitForm,
        validateForm,
        setFieldTouched,
      }) => {
        const handleStartedDatetimeToogle = () => {
          const newValue = !values.startedDatetimeEnabled;
          setFieldValue('startedDatetimeEnabled', newValue);
          if (
            newValue &&
            !values.startedAtDate &&
            minTimeContestationDatetime &&
            maxTimeContestationDatetime &&
            checkIfDatesDaysAreEqual(
              minTimeContestationDatetime,
              maxTimeContestationDatetime
            )
          ) {
            setFieldValue('startedAtDate', minTimeContestationDatetime);
          }
        };
        const handleEndedDatetimeToogle = () => {
          const newValue = !values.endedDatetimeEnabled;
          setFieldValue('endedDatetimeEnabled', newValue);
          if (
            newValue &&
            !values.endedAtDate &&
            minTimeContestationDatetime &&
            maxTimeContestationDatetime &&
            checkIfDatesDaysAreEqual(
              minTimeContestationDatetime,
              maxTimeContestationDatetime
            )
          ) {
            setFieldValue('endedAtDate', minTimeContestationDatetime);
          }
        };

        const handleSaveClick = () => {
          return validateForm().then((errors) => {
            const hasErrors = Object.keys(errors).length > 0;
            if (hasErrors) {
              // If errors exist, set all fields as "touched", so validation errors
              // are displayed
              Object.keys(values).forEach((field) => {
                setFieldTouched(field);
              });
            } else {
              // If no errors exist, open confirmation modal
              setFieldValue('isSubmitConfirmationModalOpen', true);
            }
          });
        };

        return (
          <Form noValidate={true}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
              <FormInnerContainer>
                <div>
                  <DateTimeCheckboxGroup>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          name="startedDatetimeEnabled"
                          value={values.startedDatetimeEnabled}
                          onChange={handleStartedDatetimeToogle}
                        />
                      }
                      label="Início da execução"
                    />
                  </DateTimeCheckboxGroup>
                  <DateTimePickersGroup>
                    <div>
                      <CalendarIcon width={20} />
                      <DatePicker
                        format="dd/MM/yyyy"
                        name="startedAtDate"
                        inputProps={{
                          'data-testid': 'startedAtDate',
                        }}
                        DialogProps={
                          {
                            'data-testid': 'startedAtDateDialog',
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          } as any // 'data-testid' isn't typed on current DialogProps interface
                        }
                        setFieldValue={setFieldValue}
                        values={values}
                        disabled={!values.startedDatetimeEnabled}
                        minDate={minTimeContestationDatetime}
                        maxDate={maxTimeContestationDatetime}
                      />
                    </div>
                    <div>
                      <TimeIcon width={20} />
                      <TimePicker
                        format="HH:mm"
                        name="startedAtTime"
                        inputProps={{
                          'data-testid': 'startedAtTime',
                        }}
                        DialogProps={
                          {
                            'data-testid': 'startedAtTimeDialog',
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          } as any // 'data-testid' isn't typed on current DialogProps interface
                        }
                        setFieldValue={setFieldValue}
                        values={values}
                        disabled={!values.startedDatetimeEnabled}
                        ampm={false}
                      />
                    </div>
                  </DateTimePickersGroup>
                </div>
                <div>
                  <DateTimeCheckboxGroup>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          name="endedDatetimeEnabled"
                          value={values.endedDatetimeEnabled}
                          onChange={handleEndedDatetimeToogle}
                        />
                      }
                      label="Fim da execução"
                    />
                  </DateTimeCheckboxGroup>
                  <DateTimePickersGroup>
                    <div>
                      <CalendarIcon width={20} />
                      <DatePicker
                        format="dd/MM/yyyy"
                        name="endedAtDate"
                        inputProps={{
                          'data-testid': 'endedAtDate',
                        }}
                        DialogProps={
                          {
                            'data-testid': 'endedAtDateDialog',
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          } as any // 'data-testid' isn't typed on current DialogProps interface
                        }
                        setFieldValue={setFieldValue}
                        values={values}
                        disabled={!values.endedDatetimeEnabled}
                        minDate={minTimeContestationDatetime}
                        maxDate={maxTimeContestationDatetime}
                      />
                    </div>
                    <div>
                      <TimeIcon width={20} />
                      <TimePicker
                        format="HH:mm"
                        name="endedAtTime"
                        inputProps={{
                          'data-testid': 'endedAtTime',
                        }}
                        DialogProps={
                          {
                            'data-testid': 'endedAtTimeDialog',
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          } as any // 'data-testid' isn't typed on current DialogProps interface
                        }
                        setFieldValue={setFieldValue}
                        values={values}
                        disabled={!values.endedDatetimeEnabled}
                        ampm={false}
                      />
                    </div>
                  </DateTimePickersGroup>
                </div>
                <JustificationContainer>
                  <FormikTextField
                    color="primary"
                    id="justification"
                    multiline
                    rows={4}
                    inputProps={{
                      'data-testid': 'justification',
                      maxLength: 2000,
                    }}
                    label="Justificativa (obrigatória)"
                    name="justification"
                    fullWidth
                    autoComplete="off"
                    required
                  />
                </JustificationContainer>
                <StyledOptions>
                  <Button size="medium" color="primary" onClick={onCancel}>
                    Cancelar
                  </Button>
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleSaveClick}
                  >
                    Salvar
                  </Button>
                </StyledOptions>
              </FormInnerContainer>
            </MuiPickersUtilsProvider>
            <ConfirmSaveDialog
              open={values.isSubmitConfirmationModalOpen}
              onClose={() => {
                setFieldValue('isSubmitConfirmationModalOpen', false);
              }}
              onConfirm={() => {
                setFieldValue('isSubmitConfirmationModalOpen', false);
                submitForm();
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const checkIfDatesDaysAreEqual = (dateA: Date, dateB: Date) => {
  return dateA.getDate() === dateB.getDate();
};

const CreateTimeContestation: React.FC = () => {
  const history = useHistory();
  const { shiftId, shiftExecutionId } = useParams<{
    shiftId: string;
    shiftExecutionId: string;
  }>();
  const [shift, setShift] = useState<ShiftModel>();
  const { getById, loading } = useShift();
  const { post: postShiftTimeContestation } = useShiftTimeContestation();

  useEffect(() => {
    if (shiftId) {
      getById(Number(shiftId)).then((shift) => {
        setShift(shift as ShiftModel);
      });
    }
  }, [shiftId]);

  const {
    minTimeContestationDatetime,
    maxTimeContestationDatetime,
  } = useMemo(() => {
    const minTimeContestationDatetime = shift?.plannedToStartAt
      ? new Date(shift.plannedToStartAt)
      : undefined;
    const maxTimeContestationDatetime = shift?.plannedToEndAt
      ? new Date(shift.plannedToEndAt)
      : undefined;

    return {
      minTimeContestationDatetime,
      maxTimeContestationDatetime,
    };
  }, [shift?.plannedToStartAt, shift?.plannedToEndAt]);

  const handleTimeContestationSubmitted = async (values: FormValues) => {
    let startedAt;
    if (
      values.startedDatetimeEnabled &&
      values.startedAtDate &&
      values.startedAtTime
    ) {
      startedAt = new Date(values.startedAtDate);
      startedAt.setHours(values.startedAtTime?.getHours());
      startedAt.setMinutes(values.startedAtTime?.getMinutes());
    }

    let endedAt;
    if (
      values.endedDatetimeEnabled &&
      values.endedAtDate &&
      values.endedAtTime
    ) {
      endedAt = new Date(values.endedAtDate);
      endedAt.setHours(values.endedAtTime?.getHours());
      endedAt.setMinutes(values.endedAtTime?.getMinutes());
    }

    const body: {
      justification: string;
      startedAt?: Date;
      endedAt?: Date;
    } = {
      justification: values.justification,
    };

    if (startedAt) {
      body.startedAt = startedAt;
    }

    if (endedAt) {
      body.endedAt = endedAt;
    }

    await postShiftTimeContestation(Number(shiftExecutionId), body)
      .then(() => {
        toast.success('Correção de horários feita com sucesso');
        history.goBack();
      })
      .catch((err) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const onCancel = () => {
    history.goBack();
  };

  const title: string = useMemo(() => {
    if (!shift || !shift.executions || shift.executions.length === 0)
      return 'Correção de horários';

    const currentExecutionIndex = orderBy(
      shift.executions,
      'createdAt'
    ).findIndex((execution) => execution.id === Number(shiftExecutionId));

    if (currentExecutionIndex < 0) return 'Correção de horários';

    return `Correção de horários da Execução ${currentExecutionIndex + 1}`;
  }, [shift]);

  if (loading) {
    return <LoadingBackdrop loading />;
  }

  if (!shift || !shift.executions || shift.executions.length === 0) {
    return null;
  }

  return (
    <Container>
      <Header title={title} />
      <Body>
        <TopGuidelineParagraph>
          Selecione e preencha <b>apenas</b> o período (data e horário) que
          deseja corrigir.
        </TopGuidelineParagraph>
        <ShiftTimeContestationDateBlock
          startingLabel="Início planejado"
          startingDatetime={
            shift?.plannedToStartAt
              ? new Date(shift.plannedToStartAt)
              : undefined
          }
          endingLabel="Fim planejado"
          endingDatetime={
            shift?.plannedToEndAt ? new Date(shift.plannedToEndAt) : undefined
          }
        />
        <div>
          <CreateTimeContestationForm
            onCancel={onCancel}
            onSubmit={handleTimeContestationSubmitted}
            minTimeContestationDatetime={minTimeContestationDatetime}
            maxTimeContestationDatetime={maxTimeContestationDatetime}
          />
        </div>
      </Body>
    </Container>
  );
};

export default CreateTimeContestation;
