import { format, parse } from 'date-fns';
import * as Yup from 'yup';
import {
  EventSchedule,
  EventSubCategoryModel,
  MedicationModel,
} from '@cuidador/database';

export const needScheduleData = (values: FormValues): boolean => {
  return values.continuous || !values.ifNecessary;
};

export interface FormValues {
  subCategory?: EventSubCategoryModel;
  subCategoryId?: Id;
  name?: string;
  dosageFormat?: MedicationModel['dosageFormat'];
  dosageQuantity?: string;
  administeredBy?: MedicationModel['administeredBy'];
  scheduleType?: EventSchedule['scheduleType'];
  frequencyRule: string;
  frequencyStartsAt: string;
  frequencyEndsAt: string;
  mon?: boolean;
  tue?: boolean;
  wed?: boolean;
  thu?: boolean;
  fri?: boolean;
  sat?: boolean;
  sun?: boolean;
  continuous?: boolean;
  ifNecessary?: boolean;
  startDate?: string;
  endDate?: string;
  time?: string;
  medicalNotes?: string;
  eventScheduleId?: Id;
}

export const medicationModelToFormValues = (
  data: MedicationModel
): FormValues => {
  let formattedDate;
  let formattedTime;
  let formattedEndDate;

  if (data?.eventSchedule?.frequencyStartsAt) {
    formattedDate = format(
      new Date(data?.eventSchedule?.frequencyStartsAt),
      'yyyy-MM-dd'
    );
    formattedTime = format(
      new Date(data?.eventSchedule?.frequencyStartsAt),
      'HH:mm'
    );

    if (data?.eventSchedule?.frequencyEndsAt) {
      formattedEndDate = format(
        new Date(data?.eventSchedule?.frequencyEndsAt),
        'yyyy-MM-dd'
      );
    }
  }

  return {
    subCategory: data?.subCategory,
    subCategoryId: data?.subCategory?.id || undefined,
    name: data?.subCategory?.name || '',
    dosageFormat: data?.dosageFormat || undefined,
    dosageQuantity: data.dosageQuantity ? String(data.dosageQuantity) : '',
    administeredBy: data?.administeredBy || undefined,
    frequencyRule: data?.eventSchedule?.frequencyRule || '',
    time: formattedTime || '',
    startDate: formattedDate || format(new Date(), 'yyyy-MM-dd'),
    endDate: formattedEndDate || '',
    frequencyStartsAt: data?.eventSchedule?.frequencyStartsAt || '',
    frequencyEndsAt: data?.eventSchedule?.frequencyEndsAt || '',
    medicalNotes: data?.medicalNotes || undefined,
    eventScheduleId: data?.eventSchedule?.id || undefined,
    continuous:
      data?.eventSchedule?.frequencyEndsAt === undefined
        ? false
        : Boolean(!data?.eventSchedule?.frequencyEndsAt),
    ifNecessary: Boolean(data.ifNecessary),
  } as FormValues;
};

export const formDataToMedicationModel = (
  data: FormValues,
  patientId: Id
): MedicationModel => {
  const startIsoDate = needScheduleData(data)
    ? parse(
        `${data.startDate} ${data.time}`,
        'yyyy-MM-dd HH:mm',
        new Date()
      ).toISOString()
    : '';

  let endIsoDate;
  if (data.endDate) {
    endIsoDate = needScheduleData(data)
      ? parse(
          `${data.endDate} ${data.time}`,
          'yyyy-MM-dd HH:mm',
          new Date()
        ).toISOString()
      : '';
  }

  if (data.continuous) {
    endIsoDate = undefined;
  }

  return {
    dosageFormat: data?.dosageFormat || undefined,
    dosageQuantity: data.dosageQuantity
      ? parseFloat(data?.dosageQuantity)
      : undefined,
    administeredBy: data?.administeredBy || undefined,
    subCategoryId: (data?.subCategoryId as number) || undefined,
    patientId: (patientId as number) || undefined,
    medicalNotes: data?.medicalNotes || undefined,
    ifNecessary: Boolean(data?.ifNecessary),
    eventSchedule: needScheduleData(data)
      ? {
          id: (data?.eventScheduleId as number) || undefined,
          frequencyRule: data?.frequencyRule || '',
          frequencyStartsAt: startIsoDate || '',
          frequencyEndsAt: endIsoDate || undefined,
          mon: data?.mon || true,
          tue: data?.tue || true,
          wed: data?.wed || true,
          thu: data?.thu || true,
          fri: data?.fri || true,
          sat: data?.sat || true,
          sun: data?.sun || true,
          scheduleType: 'frequency',
        }
      : undefined,
  };
};

export const validationSchema = Yup.object().shape({
  subCategoryId: Yup.number().required('Por favor, insira um medicamento.'),
  dosageFormat: Yup.string().required('Por favor, insira um formato de dose.'),
  dosageQuantity: Yup.string().required(
    'Por favor, insira uma quantidade (em números) por dose.'
  ),
  administeredBy: Yup.string().required(
    'Por favor, insira uma via de aplicação.'
  ),

  frequencyRule: Yup.string().when('ifNecessary', {
    is: true,
    otherwise: Yup.string().required('Por favor, insira uma frequência.'),
    then: Yup.string().when('continuous', {
      is: true,
      then: Yup.string().required('Por favor, insira uma frequência.'),
    }),
  }),
  startDate: Yup.string().when('ifNecessary', {
    is: true,
    otherwise: Yup.string().required('Por favor, insira uma data inicial.'),
    then: Yup.string().when('continuous', {
      is: true,
      then: Yup.string().required('Por favor, insira uma data inicial.'),
    }),
  }),
  endDate: Yup.string().when('continuous', {
    is: false,
    then: Yup.string().when('ifNecessary', {
      is: false,
      then: Yup.string().required('Por favor, insira uma data final.'),
    }),
  }),
  time: Yup.string().when('ifNecessary', {
    is: true,
    otherwise: Yup.string().required('Por favor, insira um horário.'),
    then: Yup.string().when('continuous', {
      is: true,
      then: Yup.string().required('Por favor, insira um horário.'),
    }),
  }),
});

export const dosesNames = [
  { id: 1, value: 'pill', name: 'Comprimido' },
  { id: 2, value: 'capsule', name: 'Cápsula' },
  { id: 3, value: 'sachet', name: 'Sachê' },
  { id: 4, value: 'drops', name: 'Gota' },
  { id: 5, value: 'ampoule', name: 'Ampola' },
  { id: 6, value: 'suppository', name: 'Supositório' },
  { id: 7, value: 'inhaled', name: 'Jato' },
  { id: 8, value: 'unit', name: 'Unidade' },
];

export const administeredNames = [
  { id: 1, value: 'oral', name: 'Oral' },
  { id: 2, value: 'sublingual', name: 'Sublingual' },
  { id: 3, value: 'subcutaneous', name: 'Subcutânea' },
  { id: 4, value: 'intravenous', name: 'Intravenosa' },
  { id: 5, value: 'intramuscular', name: 'Intramuscular' },
  { id: 6, value: 'tube', name: 'Sonda' },
  { id: 7, value: 'rectal', name: 'Retal' },
  { id: 8, value: 'inhalation', name: 'Inalatório' },
];

export const frequencyOptions = [
  { id: 2, value: '2h', name: '2h/2h' },
  { id: 3, value: '4h', name: '4h/4h' },
  { id: 4, value: '6h', name: '6h/6h' },
  { id: 5, value: '8h', name: '8h/8h' },
  { id: 6, value: '12h', name: '12h/12h' },
  { id: 7, value: '24h', name: '1 vez ao dia' },
  { id: 8, value: '2d', name: 'A cada dois dias' },
  { id: 9, value: '3d', name: 'A cada três dias' },
  { id: 10, value: '4d', name: 'A cada quatro dias' },
  { id: 11, value: '5d', name: 'A cada cinco dias' },
  { id: 12, value: '6d', name: 'A cada seis dias' },
  { id: 13, value: '7d', name: 'Semanalmente' },
  { id: 14, value: '15d', name: 'Quinzenal' },
  { id: 15, value: '1M', name: 'Mensal' },
  { id: 16, value: '3M', name: 'Trimestral' },
  { id: 17, value: '6M', name: 'Semestral' },
];
