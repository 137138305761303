import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DialogSubtitle } from './styles';

import StyledButton from '../StyledButton';
import StyledDialog from '../StyledDialog';

export type DialogProps = {
  open: boolean;
  handleNo: () => void;
  handleYes: () => void;
  title: string;
  subTitle: string;
};

const StyledSimpleDialog: React.FC<DialogProps> = ({
  open,
  handleNo,
  handleYes,
  title,
  subTitle,
}) => {
  return (
    <StyledDialog open={open}>
      <DialogContent>
        <DialogTitle>{title}</DialogTitle>
        <DialogSubtitle>{subTitle}</DialogSubtitle>
        <DialogActions>
          <StyledButton color="inherit" onClick={handleNo}>
            Não
          </StyledButton>
          <StyledButton color="inherit" onClick={handleYes}>
            Sim
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export default StyledSimpleDialog;
