import { Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import HeaderBase from '../HeaderBase';
import {
  CenterContainer,
  PhotoElement,
  StyledBackIcon,
  StyledBlankDiv,
  StyledIconButton,
  Title,
} from './styles';

const UserHeader: React.FC<{ userId: number; title: string }> = ({
  userId,
  title,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const { getProfilePicture } = useUser();
  const history = useHistory();

  useEffect(() => {
    handleGetProfilePicture(userId).then((result) => {
      if (result) setImageUrl(result);
    });
  }, []);

  const handleGetProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <HeaderBase
      leftContent={
        <StyledIconButton
          edge="end"
          color="inherit"
          data-testid="back-button"
          onClick={history.goBack}
        >
          <StyledBackIcon />
        </StyledIconButton>
      }
      centerContent={
        <CenterContainer>
          <PhotoElement>{<Avatar src={imageUrl} />}</PhotoElement>
          <Title align="center" variant="h6">
            {title}
          </Title>
        </CenterContainer>
      }
      rightContent={<StyledBlankDiv />}
    />
  );
};

export default UserHeader;
