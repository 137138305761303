import { EventSubCategoryModel, MeasurementModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { getMeasurementUnit } from '../../../utils';

import {
  CardFooter,
  CaregiverTypography,
  InformationContainer,
  BoldText,
  CheckIconContainer,
  CloseIconContainer,
  Container,
  Instructions,
  MeasurementInput,
  RealTimeContainer,
  StyledBoldTitle,
  StyledCheckIcon,
  StyledCloseIcon,
  NoteTypography,
  Row,
} from './styles';

interface MeasurementProps {
  id: number;
  name?: string;
  description?: string;
  status?: string;
  comment?: string;
  measurement?: MeasurementModel;
  updatedTimeHappensAt?: string | null;
  subCategory?: EventSubCategoryModel;
  caregiverName?: string;
}

const Measurement = ({
  id: eventId,
  status,
  comment,
  measurement,
  description,
  updatedTimeHappensAt,
  subCategory,
  caregiverName,
}: MeasurementProps) => {
  const measurementValue = measurement?.measurementValue;
  const measurementUnit = getMeasurementUnit(subCategory?.id);

  return (
    <Container>
      {updatedTimeHappensAt && (
        <RealTimeContainer data-testid={`realTimeContainer-${eventId}`}>
          <Typography variant="caption">{`Realizado às ${format(
            new Date(updatedTimeHappensAt),
            'HH:mm'
          )}`}</Typography>
        </RealTimeContainer>
      )}
      <StyledBoldTitle variant="subtitle1">
        {subCategory?.name?.trim()}
      </StyledBoldTitle>
      <Instructions variant="caption">{description}</Instructions>
      <Row>
        <MeasurementInput
          id={`measurement-input-${eventId}`}
          placeholder="sem registro"
          value={measurementValue}
          disabled={true}
        />
        {subCategory?.id && measurementUnit && <span>{measurementUnit}</span>}
      </Row>
      <CardFooter>
        {!!comment && (
          <NoteTypography variant="caption">
            <BoldText>Obs:</BoldText> {comment}
          </NoteTypography>
        )}
        <InformationContainer>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
          {status === 'not_accomplished' && (
            <CloseIconContainer isChecked={true} disabled={true}>
              <StyledCloseIcon />
            </CloseIconContainer>
          )}

          {status === 'accomplished' && (
            <CheckIconContainer
              isChecked={true}
              data-testid={`doneIcon-${eventId}`}
            >
              <StyledCheckIcon />
            </CheckIconContainer>
          )}
        </InformationContainer>
      </CardFooter>
    </Container>
  );
};

export default Measurement;
