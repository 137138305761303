import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import PscIcon from '../../Icons/PscIcon';
import HeaderBase, { HeaderProps } from '../HeaderBase';
import Drawer from './Drawer';
import { IconBackground, StyledMenuIcon } from './styles';

const HeaderWithDrawer: React.FC<HeaderProps> = ({ ...props }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <HeaderBase
        leftContent={
          <IconButton
            onClick={() => {
              handleToggleDrawer();
            }}
          >
            <StyledMenuIcon />
          </IconButton>
        }
        rightContent={
          <IconBackground to="/pessoas/pacientes">
            <PscIcon />
          </IconBackground>
        }
        {...props}
      />
      <Drawer
        isDrawerOpen={isDrawerOpen}
        handleToggleDrawer={handleToggleDrawer}
      />
    </>
  );
};

export default HeaderWithDrawer;
