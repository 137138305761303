import { EventScheduleModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import { format, subHours } from 'date-fns';
import React from 'react';
import { getLocalWeeklySchedule, weekdayMap } from '../../utils/date';

export const getDaysText = (eventSchedule: EventScheduleModel) => {
  const { mon, tue, wed, thu, fri, sat, sun } = eventSchedule;
  const areAllDaysSelected = mon && tue && wed && thu && fri && sat && sun;
  const areAllWeekDaysSelected = mon && tue && wed && thu && fri;
  const areSomeDaysSelected = !areAllDaysSelected || !areAllWeekDaysSelected;

  if (eventSchedule?.frequencyRule === '1d') {
    if (areAllDaysSelected) {
      return 'Todos os dias';
    } else if (areAllWeekDaysSelected) {
      return 'Segunda a Sexta';
    } else if (areSomeDaysSelected) {
      return `Apenas alguns dias (${getSelectedWeekdaysText(eventSchedule)})`;
    }
  } else {
    const frequencyTimeString = eventSchedule?.frequencyRule?.replace(/.$/, '');
    return `A cada ${frequencyTimeString} dias`;
  }
};

const getSelectedWeekdaysText = (eventSchedule: EventScheduleModel) => {
  const { mon, tue, wed, thu, fri, sat, sun } = eventSchedule;
  const utcSchedule = { mon, tue, wed, thu, fri, sat, sun };
  const date = new Date(eventSchedule.frequencyStartsAt || '');
  const utcTime = `${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
  const localSchedule = getLocalWeeklySchedule(utcSchedule, utcTime);

  const selectedWeekDays: string[] = [];
  if (localSchedule.mon) selectedWeekDays.push(weekdayMap.mon);
  if (localSchedule.tue) selectedWeekDays.push(weekdayMap.tue);
  if (localSchedule.wed) selectedWeekDays.push(weekdayMap.wed);
  if (localSchedule.thu) selectedWeekDays.push(weekdayMap.thu);
  if (localSchedule.fri) selectedWeekDays.push(weekdayMap.fri);
  if (localSchedule.sat) selectedWeekDays.push(weekdayMap.sat);
  if (localSchedule.sun) selectedWeekDays.push(weekdayMap.sun);

  return selectedWeekDays.reduce(
    (accumulatorString, weekday, index) =>
      `${accumulatorString}${index > 0 ? ', ' : ''}${weekday.toLowerCase()}`,
    ''
  );
};

export const getIntervalText = (eventSchedule: EventScheduleModel) => {
  const startDate = eventSchedule.frequencyStartsAt;
  const endDate = eventSchedule.frequencyEndsAt;

  if (startDate && endDate) {
    const formatedStartDate = format(new Date(startDate), 'dd/MM/yyyy');
    const formatedEndDate = format(new Date(endDate), 'dd/MM/yyyy');
    return `De ${formatedStartDate} até ${formatedEndDate}`;
  } else if (startDate) {
    const formatedStartDate = format(new Date(startDate), 'dd/MM/yyyy');
    return `Continuamente, com início em ${formatedStartDate}`;
  }
};

export const getTimes = (eventSchedule: EventScheduleModel) => {
  if (eventSchedule.customTimes && eventSchedule.customTimes.length > 0) {
    return renderCustomTimes(eventSchedule);
  } else {
    return renderTimes(eventSchedule);
  }
};

const renderCustomTimes = (eventSchedule: EventScheduleModel) => {
  const times = eventSchedule?.customTimes?.map((time) => {
    const now = new Date();
    const hours = Number(time.happensAt!.split(':')[0]);
    const minutes = Number(time.happensAt!.split(':')[1]);
    now.setHours(hours);
    now.setMinutes(minutes);
    return format(subHours(now, 3), 'HH:mm');
  });
  return renderTimesBox(times);
};

const renderTimes = (eventSchedule: EventScheduleModel) => {
  const handleDoses = (startTime: string, frequency: string) => {
    const formattedFrequency = Number(frequency.replace('h', ''));
    const hour = startTime.split(':');
    const formattedHour = Number(hour[0]);

    const doses = [];
    for (let x = formattedHour; x >= 0; x -= formattedFrequency) {
      doses.push(x);
    }
    for (let x = formattedHour; x <= 23; x += formattedFrequency) {
      if (x != formattedHour) {
        doses.push(x);
      }
    }
    doses.sort((a, b) => a - b);

    const formattedDoses = doses.map((item) => {
      return item.toString().padStart(2, '0') + ':' + hour[1];
    });

    return formattedDoses;
  };

  if (!eventSchedule?.frequencyRule || !eventSchedule.frequencyStartsAt)
    return null;

  const startTime = format(new Date(eventSchedule.frequencyStartsAt), 'HH:mm');
  const doseTimes = handleDoses(startTime, eventSchedule.frequencyRule);

  return renderTimesBox(doseTimes);
};

const renderTimesBox = (times?: string[]) => {
  return (
    <>
      {times?.map((time, index) => (
        <Typography key={index} variant="body2">
          <strong>{index + 1}ª medição</strong>: {time}
        </Typography>
      ))}
    </>
  );
};
