import {
  EventSubCategoryModel,
  ProfessionalReportModel,
} from '@cuidador/database';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../components/Headers/Header';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import ViewProfessionalReportForm, {
  FormValues,
} from '../../../components/ViewProfessionalReportForm';
import useProfessionalReport from '../../../hooks/UseProfessionalReport';
import { Container } from './styles';
import { ProfessionalReportModelToFormValues } from './utils';

const ProfessionalReportItem: React.FC = () => {
  const { getById, loading } = useProfessionalReport();
  const params = useParams<{ id: string }>();
  const reportId = parseInt(params.id);
  const [report, setReport] = useState<ProfessionalReportModel>();
  const [reportFormData, setReporFormData] = useState<FormValues>();
  const [subCategories, setSubCategories] = useState<EventSubCategoryModel[]>(
    []
  );

  useEffect(() => {
    handleProfessionalReport();
  }, []);

  const handleProfessionalReport = async () => {
    if (reportId) {
      getById(reportId)
        .then((data) => {
          setReport(data);
          setReporFormData(ProfessionalReportModelToFormValues(data));
          const noneOption: EventSubCategoryModel = {
            id: 0,
            name: 'Nenhuma',
          };
          const subCategory = data.eventSubCategory
            ? [data.eventSubCategory]
            : [noneOption];

          setSubCategories(subCategory);
        })
        .catch(() =>
          toast.error('Erro ao carregar avaliação multiprofissional.')
        );
    }
  };

  return (
    <>
      <Header
        title={`Avaliação Multiprofissional ${report?.patient?.name || ''}`}
      />
      <Container>
        <LoadingBackdrop loading={loading} />
        {reportFormData ? (
          <ViewProfessionalReportForm
            subCategories={subCategories}
            formValues={reportFormData}
          />
        ) : (
          <Typography>
            Não foi possível carregar a avalição multiprofissional.
          </Typography>
        )}
      </Container>
    </>
  );
};

export default ProfessionalReportItem;
