import { PatientModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { Checkbox } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as LinkIcon } from '../../../assets/link-icon.svg';
import UserHeader from '../../../components/Headers/UserHeader';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import PatientSearchTextField from '../../../components/PatientSearchTextField';
import MemberPatients from '../../../components/SelectCard/Member';
import StyledButton from '../../../components/StyledButton';
import StyledFormControlLabel from '../../../components/StyledFormControlLabel';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import usePatient from '../../../hooks/usePatient';
import useUser from '../../../hooks/useUser';
import { resolveErrorMessage } from '../../../utils/error';
import { getAge, handleGetProfilePictureURL } from '../utils';
import AutoRelateModal from './AutoRelateModal';
import {
  AutoRelateContainer,
  ButtonContainer,
  Container,
  RemoveRelationsContainer,
} from './styles';

const OrganizationUser: React.FC = () => {
  const [page, setPage] = useState(0);
  const [patients, setPatients] = useState<PatientModel[]>([]);
  const [
    patientIdToProfilePictureURL,
    setPatientIdToProfilePictureURL,
  ] = useState<Record<number, string>>({});
  const [showAutoRelateDialog, setShowAutoRelateDialog] = useState(false);
  const {
    getById,
    byId,
    loading: loadingOrganizationMember,
    patchAutoPatientRelate,
    relateToPatient,
    unrelateFromPatient,
    unrelateAllPatients,
  } = useUser();
  const {
    loading: loadingPatients,
    total,
    getOrganizationPatientsPaginated,
    getProfilePicture,
  } = usePatient();

  const { loading: loadingUserInfo, refreshUserInfo, userInfo } = useContext(
    AuthContext
  );

  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );
  const { isAllowedToRead: isAllowedToReadPatients } = useCanAccess(
    'user/patient.by-organization'
  );
  const { isAllowedToUpdate: isAllowedToPatchAutoPatientRelate } = useCanAccess(
    'user.auto-patient-relate'
  );
  const { isAllowedToRead: isAllowedToReadUser } = useCanAccess('user');
  const { isAllowedToInvoke: isAllowedToRelatePatient } = useCanAccess(
    'user/relate-to-patient'
  );
  const { isAllowedToInvoke: isAllowedToUnrelatePatient } = useCanAccess(
    'user/unrelate-from-patient'
  );

  const history = useHistory();
  const params = useParams<{ id: string }>();
  const userId = parseInt(params.id);
  const user = userId ? byId[userId] : null;
  const historyLocationSearch = history.location.search;

  useEffect(() => {
    if (!isAllowedToReadPatients || !isAllowedToReadUser) {
      toast.error('Você não tem permissão para visualizar essa página');
      history.push('/cadastros');
    }
  }, [isAllowedToReadPatients]);

  useEffect(() => {
    getUser();
  }, [userId, isAllowedToReadUser]);

  useEffect(() => {
    handleGetPatients();
  }, [page, historyLocationSearch, userId, isAllowedToReadPatients]);

  const getUser = async () => {
    if (userId && isAllowedToReadUser) {
      getById(userId).catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
    }
  };

  const handleGetPatients = () => {
    const params = qs.parse(historyLocationSearch);
    getOrganizationPatientsPaginated({
      ...params,
      page,
      limit: 10,
      withRelatedUserId: Number(userId),
    })
      .then((response) => {
        const patientsToInsert = response.data.results;
        for (const patient of patientsToInsert) {
          if (patient.id && isAllowedToReadProfilePicture) {
            handleGetProfilePictureURL(patient.id, getProfilePicture).then(
              (url) => {
                if (!url) return;
                setPatientIdToProfilePictureURL((previousMap) => {
                  if (!patient.id) return { ...previousMap };
                  const newMap = { ...previousMap };
                  newMap[patient.id] = url;
                  return newMap;
                });
              }
            );
          }
        }

        if (page === 0) {
          setPatients(patientsToInsert);
        } else {
          setPatients([...patients, ...patientsToInsert]);
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const handleConfirmRelation = (patient: PatientModel) => {
    if (isAllowedToUnrelatePatient) {
      return relateToPatient(userId, patient.id!)
        .then(() => {
          patient.isPatientRelatedToUser = !patient.isPatientRelatedToUser;
          toast.dismiss();
          toast.success('Paciente vinculado ao usuário');
          return true;
        })
        .catch((err) => {
          toast.dismiss();
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
          return false;
        });
    }
  };

  const handleConfirmUnrelate = (patient: PatientModel) => {
    if (isAllowedToRelatePatient) {
      return unrelateFromPatient(userId, patient.id!)
        .then(() => {
          patient.isPatientRelatedToUser = !patient.isPatientRelatedToUser;
          toast.dismiss();
          toast.success('Vinculo com paciente removido');
          return true;
        })
        .catch((err) => {
          toast.dismiss();
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
          return false;
        });
    }
  };

  const handleRelateOrUnrelate = async (patient: PatientModel) => {
    if (patient.isPatientRelatedToUser) {
      await handleConfirmUnrelate(patient);
    } else {
      await handleConfirmRelation(patient);
    }
    // refresh userInfo if patched patient<>user relation is about logged user
    if (userId === userInfo?.id) await refreshUserInfo();

    // refresh patients list from beginning (page 0)
    if (page === 0) {
      handleGetPatients();
    } else {
      setPage(0);
    }
  };

  const handleAutoRelatePatientsToUser = async () => {
    if (user?.isAutoPatientRelateEnabled) {
      await autoRelatePatientsToUser(false);
    } else {
      setShowAutoRelateDialog(true);
    }
  };

  const autoRelatePatientsToUser = async (
    isAutoPatientRelateEnabled: boolean
  ) => {
    try {
      await patchAutoPatientRelate(
        Number(user?.id),
        isAutoPatientRelateEnabled
      );
      if (isAutoPatientRelateEnabled) {
        toast.success('Vínculo automático ativado');
      } else {
        toast.success('Vínculo automático desativado');
      }
      await Promise.all([refreshUserInfo(), handleGetPatients()]);
    } catch (err) {
      toast.error('Erro ao modificar vinculo automatico');
      Sentry.captureException(err);
    }
    setShowAutoRelateDialog(false);
  };

  const unrelateAllPatientsFromUser = async () => {
    try {
      await unrelateAllPatients(userId);
      toast.success('Todos os vínculos com pacientes foram removidos');
      getUser();
      handleGetPatients();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao remover vínculos');
      Sentry.captureException(err);
    }
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const onChangeParams = () => {
    if (page !== 0) {
      setPage(0);
    }
  };

  const isLoading =
    loadingOrganizationMember || loadingPatients || Boolean(loadingUserInfo);

  return (
    <>
      {<LoadingBackdrop loading={isLoading} />}
      {user && (
        <>
          <UserHeader title={String(user.name)} userId={Number(user.id)} />
          <PatientSearchTextField
            fieldName="patient.name"
            onChangeDebounced={onChangeParams}
          />
          <Container>
            <ButtonContainer>
              <AutoRelateContainer
                isChecked={!!user.isAutoPatientRelateEnabled}
              >
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="isAutoPatientRelateEnabled"
                      value={!!user.isAutoPatientRelateEnabled}
                      checked={!!user.isAutoPatientRelateEnabled}
                      onChange={handleAutoRelatePatientsToUser}
                      disabled={!isAllowedToPatchAutoPatientRelate}
                      data-testid={'isAutoPatientRelateEnabled'}
                      role="checkbox"
                      aria-checked={!!user.isAutoPatientRelateEnabled}
                    />
                  }
                  label="Vinculo automático"
                />
              </AutoRelateContainer>
              <RemoveRelationsContainer>
                <StyledButton onClick={unrelateAllPatientsFromUser}>
                  <LinkIcon fontSize="medium" /> <span>Remover todos</span>
                </StyledButton>
              </RemoveRelationsContainer>
            </ButtonContainer>
            {patients.map((patient) => (
              <MemberPatients
                key={Number(patient.id)}
                id={Number(patient.id)}
                name={String(patient.name)}
                age={getAge(patient.dateOfBirth)}
                profilePictureURL={
                  patientIdToProfilePictureURL[Number(patient.id)]
                }
                status={String(patient.status)}
                isPatientRelatedToUser={Boolean(patient.isPatientRelatedToUser)}
                onRelationClick={() => handleRelateOrUnrelate(patient)}
              />
            ))}
            {patients.length < total && (
              <StyledButton
                data-testid="show-more"
                size="medium"
                color="secondary"
                onClick={pageIncrement}
              >
                Ver mais
              </StyledButton>
            )}
            <AutoRelateModal
              opened={showAutoRelateDialog}
              onClose={() => setShowAutoRelateDialog(false)}
              onConfirm={() => autoRelatePatientsToUser(true)}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default OrganizationUser;
