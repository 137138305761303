import { EventSubCategoryModel, MeasurementModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import DoneIcon from '../../../../assets/Icon-done.svg';
import MesurementIcon from '../../../../assets/Icon-mesurement.svg';
import NotDoneIcon from '../../../../assets/Icon-notdone.svg';
import {
  CardFooter,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  StatusContainer,
  TimerContainer,
  TypographyContainer,
} from '../styles';
import { CardBox } from './styles';

interface CardProps {
  id?: Id;
  eventHappensAt?: string;
  description?: string;
  status?: string;
  subCategory?: EventSubCategoryModel;
  measurement?: MeasurementModel;
  comment?: string;
  caregiverName?: string;
}

const MeasurementCard: React.FC<CardProps> = ({
  id,
  eventHappensAt,
  status,
  subCategory,
  measurement,
  comment,
  caregiverName,
}) => {
  const formattedDate = format(new Date(`${eventHappensAt}`), 'HH:mm');
  return (
    <CardBox data-testid={`mesurementCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedDate}</Typography>
        </TimerContainer>
        <img src={MesurementIcon} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">{subCategory?.name}</Typography>
          <Typography variant="subtitle2">
            Valores medidos: {measurement?.measurementValue}
          </Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">
              {measurement?.measurementDescription}
            </Typography>
            {comment && (
              <Typography variant="subtitle2">Obs: {comment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
          <StatusContainer>
            {status === 'awaiting' && <></>}
            {status === 'accomplished' && <img src={DoneIcon} />}
            {status === 'not_accomplished' && <img src={NotDoneIcon} />}
          </StatusContainer>
        </CardFooter>
      </RightContainer>
    </CardBox>
  );
};

export default MeasurementCard;
