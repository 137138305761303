import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useCanAccess from '../../../hooks/useCanAccess';
import PscIcon from '../../Icons/PscIcon';
import HeaderWithTitle from '../HeaderWithTitle';
import {
  IconBackground,
  StyledBackIcon,
  StyledBlankDiv,
  StyledIconButton,
  StyledPhoneIcon,
} from './styles';

export type HeaderProps = {
  title: string;
  rightButtonType?: 'pscList' | 'phone' | 'remove';
  rightIconClick?: () => void;
  leftIconClick?: () => void;
};

const Header: React.FC<HeaderProps> = ({
  title,
  rightButtonType,
  rightIconClick,
  leftIconClick,
}: HeaderProps) => {
  const history = useHistory();
  const { isAllowedToRead: isAllowedToReadEmergencyContact } = useCanAccess(
    'care/emergency-contact'
  );

  const actions = {
    pscList: (
      <IconBackground to="/pessoas/pacientes">
        <PscIcon />
      </IconBackground>
    ),
    phone: isAllowedToReadEmergencyContact && (
      <IconBackground to="/contatos">
        <StyledPhoneIcon fontSize="small" />
      </IconBackground>
    ),
    remove: <DeleteIcon fontSize="large" />,
  };

  return (
    <HeaderWithTitle
      leftContent={
        <StyledIconButton
          edge="end"
          color="inherit"
          data-testid="back-button"
          onClick={!leftIconClick ? () => history.goBack() : leftIconClick}
        >
          <StyledBackIcon />
        </StyledIconButton>
      }
      title={title}
      rightContent={
        rightButtonType ? (
          <StyledIconButton
            edge="end"
            color="inherit"
            data-testid={rightButtonType}
            onClick={rightIconClick}
          >
            {actions[rightButtonType]}
          </StyledIconButton>
        ) : (
          <StyledBlankDiv />
        )
      }
    />
  );
};

export default Header;
