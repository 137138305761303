import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import qs from 'query-string';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { StyledStyledTextField } from './styles';

type PatientSearchTextFieldProps = {
  fieldName: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeDebounced?: (value: string) => void;
};

const getSearchValueFromUrl = (url: string) => url.replace(/^\%|\%$/g, '');

const PatientSearchTextField: React.FC<PatientSearchTextFieldProps> = ({
  fieldName,
  onChange,
  onChangeDebounced,
}) => {
  const [value, setValue] = useState('');
  const history = useHistory();
  const defaultValue = getSearchValueFromUrl(
    (qs.parse(history.location.search)[fieldName] as string) || ''
  );

  const debounced = useDebouncedCallback((value) => {
    const newSearch = {
      ...qs.parse(history.location.search),
      [fieldName]: value ? `%${value}%` : undefined,
    };
    const newUrl = qs.stringifyUrl({
      url: history.location.pathname,
      query: newSearch,
    });
    history.replace(newUrl);
    if (onChangeDebounced) onChangeDebounced(value);
  }, 500);

  return (
    <StyledStyledTextField
      data-testid="search-bar"
      autoFocus={true}
      variant="outlined"
      size="small"
      type="search"
      color="secondary"
      defaultValue={defaultValue}
      onChange={(e) => {
        setValue(e.target.value);
        debounced.callback(e.target.value);
        if (onChange) onChange(e);
      }}
      InputProps={{
        endAdornment: value.length === 0 && (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PatientSearchTextField;
