import styled from 'styled-components';
import StyledTextField from '../StyledTextField';

export const StyledStyledTextField = styled(StyledTextField)`
  margin-block: ${({ theme }) => theme.spacing(2)}px;
  margin-inline: ${({ theme }) => theme.spacing(3)}px;
  max-width: 800px;
  align-self: center;

  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.common.white};
    padding-right: 0;
  }

  svg {
    background-color: ${({ theme }) => theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(1)}px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  path {
    color: ${({ theme }) => theme.palette.common.white};
  }

  input[type='search']:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  input.input:not(.Mui-disabled),
  .MuiInputBase-input,
  .MuiOutlinedInput-root:not(.Mui-error, .Mui-disabled) fieldset,
  .MuiOutlinedInput-root:not(.Mui-error, .Mui-disabled).Mui-focused fieldset {
    color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
