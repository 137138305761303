import React from 'react';
import HeaderBase, { HeaderProps as BaseHeaderProps } from '../HeaderBase';

import { Title } from './styles';

export interface HeaderProps extends BaseHeaderProps {
  title: string;
}

const HeaderWithTitle: React.FC<HeaderProps> = ({ title, ...props }) => {
  return (
    <HeaderBase
      centerContent={
        <Title align="center" variant="h6">
          {title}
        </Title>
      }
      {...props}
    />
  );
};

export default HeaderWithTitle;
