import { Typography } from '@material-ui/core';
import MuiFab from '@material-ui/core/Fab';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`;

export const StyledFab = styled(MuiFab)`
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.secondary.main};
  border: solid 1px ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  position: fixed;
  right: 0;
  bottom: 0;
  .MuiSvgIcon-root {
    flex-shrink: inherit;
  }
  svg {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'h6',
})`
  font-size: 16px;
`;

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  font-size: 1.05rem;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-inline: auto;
  padding-bottom: ${({ theme }) => theme.spacing(0.3)}px;
  width: fit-content;
`;

export const CardsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  max-width: 800px;
`;
