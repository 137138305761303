import styled from 'styled-components';

import TextField from '../../components/StyledTextField';
import BackgroundImage from '../../assets/loginBackground.png';

export const Background = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  background-image: ${`url(${BackgroundImage})`};
  background-size: cover;
  background-position: center;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  max-width: 500px;
  color: white;

  img {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;

    .MuiButton-root {
      margin-top: ${({ theme }) => theme.spacing(4)}px;
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  a {
    font-size: 14px;
    color: white;
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  }

  @media (max-height: 675px) {
    form {
      .MuiButton-root {
        margin-top: ${({ theme }) => theme.spacing(2)}px;
      }
    }

    a {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }

    img {
      max-height: 95px;
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }

    p:last-child {
      position: inherit;
    }
  }
`;

export const StyledTextField = styled(TextField).attrs({ variant: 'outlined' })`
  & label:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
  & svg {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const TypographyContainer = styled.div`
  max-width: 100%;
  text-align: center;
`;
