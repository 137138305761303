import AddIcon from '@material-ui/icons/AddRounded';
import React, { useCallback } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import Fab from '../../components/Fab';
import Header from '../../components/Headers/Header';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import StyledTabs from '../../components/StyledTabs';
import useCanAccess from '../../hooks/useCanAccess';
import AdditionalCoverage from './AdditionalCoverage';
import NextShiftSchedule from './NextShiftSchedules';
import { ButtonContainer, Container, StyledTabItem } from './styles';
import { RouteParams, TabOptions, tabOptions } from './utils';

const ShiftSchedule: React.FC = () => {
  const history = useHistory();
  const { tab } = useParams<RouteParams>();

  const {
    isAllowedToRead: isAllowedToReadShift,
    isAllowedToCreate: isAllowedToCreateShift,
  } = useCanAccess('care/shiftschedule');
  const {
    isAllowedToRead: isAllowedToReadAdditionalShift,
    isAllowedToCreate: isAllowedToCreateAdditionalShift,
  } = useCanAccess('care/shift.additional-coverage');

  const permissions: Record<TabOptions['permission'], boolean> = {
    isAllowedToReadShift,
    isAllowedToReadAdditionalShift,
  };

  const isCreateButtonVisible =
    (tab === 'proximos' && isAllowedToCreateShift) ||
    (tab === 'cobertura-adicional' && isAllowedToCreateAdditionalShift);

  const handleChange = useCallback((_, value) => {
    history.replace(`/plantoes/${value}`);
  }, []);

  if (!tab || !tabOptions.some((item) => item.key === tab)) {
    return (
      <Redirect
        to={
          isAllowedToReadShift
            ? '/plantoes/proximos'
            : '/plantoes/cobertura-adicional'
        }
      />
    );
  }

  return (
    <>
      <Header title="Plantões" rightButtonType="pscList" />
      <PendingRegistrationBanner />
      <Container>
        <ButtonContainer>
          <StyledTabs centered value={tab} onChange={handleChange}>
            {tabOptions.map(
              (option) =>
                permissions[option.permission] && (
                  <StyledTabItem
                    wrapped={true}
                    key={option.key}
                    label={option.label}
                    value={option.key}
                  />
                )
            )}
          </StyledTabs>
        </ButtonContainer>
        {tab == 'proximos' && <NextShiftSchedule />}
        {tab == 'cobertura-adicional' && <AdditionalCoverage />}
      </Container>

      {isCreateButtonVisible && (
        <Fab
          to={
            tab == 'proximos' ? '/plantoes/novo' : '/cobertura-adicional/novo'
          }
        >
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export default ShiftSchedule;
