/* eslint-disable @typescript-eslint/no-var-requires */

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const packageJson = require('../../../package.json');
// removes monorepo package identifier
const packageName = packageJson.name.split('/')[1];

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${packageName}@${packageJson.version}`,
    environment: process.env.REACT_APP_ENV,
    debug: process.env.NODE_ENV === 'development',
    attachStacktrace: true,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

/* eslint-enable @typescript-eslint/no-var-requires */
