import React from 'react';
import HeaderWithTitle from '../../components/Headers/HeaderWithTitle';
import MedicBalloon from '../../components/MedicBalloon';
import RegistrationOnboardingCard from '../../components/RegistrationOnboardingCard';
import { Container } from './styles';

const Onboarding: React.FC = () => {
  return (
    <>
      <HeaderWithTitle title="Cadastro" />
      <Container>
        <MedicBalloon
          text={
            'Olá, eu sou a Augusta! Vou auxiliar você durante o seu cadastro inicial no Cuidador de Confiança.\n\nPara começar, cadastre as informações da pessoa sob cuidado.'
          }
        />
        <RegistrationOnboardingCard
          color="#725B53"
          title="Pessoa sob Cuidado"
          subTitle="Cadastre as informações da pessoa sob cuidado."
          registrationPSCRoute="/cadastro-psc"
        />
      </Container>
    </>
  );
};

export default Onboarding;
