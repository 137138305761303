import { EmergencyContactModel } from '@cuidador/database';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmergencyContacts from '../../components/EmergencyContactCard';
import Fab from '../../components/Fab';
import Header from '../../components/Headers/Header';
import MedicBalloon from '../../components/MedicBalloon';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import useEmergencyContacts from '../../hooks/useEmergencyContacts';
import { Backdrop, BackdropCircularProgress, Container } from './styles';

const EmergencyContactsCard: React.FC<{
  contacts: EmergencyContactModel;
}> = ({ contacts }) => {
  return <EmergencyContacts {...contacts} />;
};

const WHATSAPP_NUMBER = '5511961896764';

const Contacts: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const { getAllByPatientId, byId, ids, loading } = useEmergencyContacts();
  const { isAllowedToCreate } = useCanAccess('care/emergency-contact');
  const history = useHistory();
  const FAQ_URL =
    'https://www.cuidadordeconfianca.com.br/perguntas-frequentes-aplicativo';

  const patientId = userInfo?.activePatientId;

  const { isAllowedToRead: isAllowedToReadEmercencyContact } = useCanAccess(
    'care/emergency-contact'
  );

  useEffect(() => {
    if (!isAllowedToReadEmercencyContact) {
      toast.error('Você não tem permissão para visualizar essa página');
      history.goBack();
      return;
    }
    if (!patientId) return;
    getAllByPatientId(patientId);
  }, [patientId]);

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      <Header title="Contatos de Emergência" />
      <PendingRegistrationBanner />
      <Container>
        <MedicBalloon text="Nesta página, deixe organizados os contatos que devem ser acionados em situações importantes." />
        <EmergencyContactsCard
          contacts={{
            name: 'Samu',
            phoneNumber: '192',
            callingCondition:
              'Ligar em caso de acidente, crises convulsivas, falta de ar, suspeita de infarto ou AVC.',
          }}
        />
        <EmergencyContactsCard
          contacts={{
            name: 'Bombeiros',
            phoneNumber: '193',
            callingCondition:
              'Ligar em caso de afogamento, choque elétrico, queda e acidente doméstico.',
          }}
        />
        <EmergencyContactsCard
          contacts={{
            name: 'Dúvidas frequentes',
            relevantInformation: 'Dicas sobre o uso do aplicativo.',
            link: FAQ_URL,
          }}
        />
        <EmergencyContactsCard
          contacts={{
            name: 'Central de Apoio ao Cuidador de Confiança',
            relevantInformation: 'Dicas de cuidado e uso do aplicativo.',
            phoneNumber: WHATSAPP_NUMBER,
            isWhatsapp: true,
          }}
        />
        {ids
          .map((id) => byId[id])
          .map((contacts) => (
            <EmergencyContactsCard key={contacts.id} contacts={contacts} />
          ))}

        {isAllowedToCreate && (
          <Fab to="/contatos/novo">
            <AddIcon />
          </Fab>
        )}
      </Container>
    </>
  );
};

export default Contacts;
