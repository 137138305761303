import { EventSubCategoryModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import AlertIcon from '../../../../assets/AlertIcon.svg';
import {
  DescriptionContainer,
  LeftContainer,
  TimerContainer,
  TypographyContainer,
} from '../styles';
import { CardBox, CaregiverTypography } from './styles';

interface CardProps {
  id?: Id;
  name?: string;
  eventNotifiedAt?: string | null;
  description?: string;
  subCategory?: EventSubCategoryModel;
  comment?: string;
  caregiverName?: string;
}

const ComplicationCard: React.FC<CardProps> = ({
  id,
  description,
  eventNotifiedAt,
  subCategory,
  comment,
  caregiverName,
}) => {
  const formattedDate = format(new Date(`${eventNotifiedAt}`), 'HH:mm');
  return (
    <CardBox data-testid={`complicationCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedDate}</Typography>
        </TimerContainer>
        <img src={AlertIcon} />
      </LeftContainer>
      <TypographyContainer>
        <Typography variant="h6">{subCategory?.name}</Typography>
        <DescriptionContainer>
          <Typography variant="subtitle2">{description}</Typography>
          {comment && (
            <Typography variant="subtitle2">Obs: {comment}</Typography>
          )}
        </DescriptionContainer>
        {caregiverName && (
          <CaregiverTypography>{caregiverName}</CaregiverTypography>
        )}
      </TypographyContainer>
    </CardBox>
  );
};

export default ComplicationCard;
