import { PlanModel } from '@cuidador/database';
import {
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useRef, useState } from 'react';
import Cards, { Focused } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledFormikTextField } from '../../components/FormCardContainer';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { AuthContext } from '../../contexts/auth';
import useCEP from '../../hooks/useCEP';
import usePlan from '../../hooks/usePlan';
import { resolveErrorMessage } from '../../utils/error';
import {
  cardMask,
  cepMask,
  cpfMask,
  cvcMask,
  expiryMask,
  numberMask,
} from '../../utils/inputs';
import {
  FormValues,
  initialValues,
  makeValidationSchema,
} from '../SubscriptionActivation/utils';
import {
  Container,
  RowContainer,
  StyledAccordion,
  StyledExpandMoreIcon,
  StyledFirstInRow,
  StyledIcon,
  StyledInfo,
  StyledInfoPrice,
  StyledLine,
  StyledLineSimple,
  StyledPayButton,
  StyledPlan,
  StyledPlanDescription,
  StyledPrice,
  StyledTitle,
  StyledTypography,
} from './styles';
import {
  FormAddressValues,
  FormCardValues,
  formValuesToPostRequisitionBody,
  initialAddressValues,
  initialCardValues,
  validationAddressSchema,
  validationCardSchema,
} from './utils';

const SubscriptionPayment: React.FC = () => {
  const history = useHistory();
  const formikCardValuesRef = useRef<FormikProps<FormCardValues> | null>();
  const formikAddressValuesRef = useRef<FormikProps<FormAddressValues> | null>();
  const formikPromotionalCodeRef = useRef<FormikProps<FormValues> | null>();
  const { getAddress } = useCEP();
  const { state } = useLocation<{ selectedPlan: PlanModel }>();
  const plan = state.selectedPlan;
  const { signAvailablePlan, loading } = usePlan();
  const { refreshUserInfo } = useContext(AuthContext);
  const [district, setDistrict] = useState('');
  const [cardFocus, setCardFocus] = useState<Focused>('name');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCardchange = (e: React.ChangeEvent<any>) => {
    if (!formikCardValuesRef || !formikCardValuesRef.current) return;

    switch (e.target.name) {
      case 'cvc':
        formikCardValuesRef.current.setFieldValue(
          e.target.name,
          cvcMask(e.target.value)
        );
        break;

      case 'cardNumber':
        formikCardValuesRef.current.setFieldValue(
          e.target.name,
          cardMask(e.target.value)
        );
        break;

      case 'expiry':
        formikCardValuesRef.current.setFieldValue(
          e.target.name,
          expiryMask(e.target.value)
        );
        break;
      case 'cpf':
        formikCardValuesRef.current.setFieldValue(
          e.target.name,
          cpfMask(e.target.value)
        );
        break;
      default:
        formikCardValuesRef.current.setFieldValue(
          e.target.name,
          e.target.value
        );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCardfocus = (e: React.ChangeEvent<any>) => {
    setCardFocus(e.target.name);
  };

  const submitCardInfo = () => {
    // se remover essa funcao a validacao do formik para de funcionar
  };

  const submitAddressInfo = () => {
    // se remover essa funcao a validacao do formik para de funcionar
  };

  const fetchAddress = (cep: string) => {
    if (!formikAddressValuesRef || !formikAddressValuesRef.current) return;
    getAddress(cep)
      .then((data) => {
        const { erro, uf, localidade, bairro, logradouro } = data;

        if (!erro && formikAddressValuesRef.current) {
          formikAddressValuesRef.current.setFieldValue('country', 'Brasil');
          formikAddressValuesRef.current.setFieldValue('province', uf);
          formikAddressValuesRef.current.setFieldValue('city', localidade);
          formikAddressValuesRef.current.setFieldValue('address', logradouro);
          setDistrict(bairro);
        }
      })
      .catch(() => {
        toast.error('CEP inválido');
      });
  };

  // eslint-disable-next-line
  const handlePostalCodeChange = (e: React.ChangeEvent<any>) => {
    if (!formikAddressValuesRef || !formikAddressValuesRef.current) return;
    formikAddressValuesRef.current.setFieldValue('postalCode', e.target.value);
    if (e.target.value.length === 9) {
      fetchAddress(e.target.value);
    }
  };

  const pay = () => {
    if (
      !formikAddressValuesRef.current ||
      !formikCardValuesRef.current ||
      (plan.requirePromotionalCode && !formikPromotionalCodeRef?.current)
    )
      return;

    const cardFormik = formikCardValuesRef.current;
    const billingAddressFormik = formikAddressValuesRef.current;
    const promotinalCodeFormik = formikPromotionalCodeRef?.current;

    if (
      !cardFormik.isValid ||
      !billingAddressFormik.isValid ||
      (plan.requirePromotionalCode && !promotinalCodeFormik?.isValid)
    ) {
      cardFormik.submitForm();
      billingAddressFormik.submitForm();
      promotinalCodeFormik?.submitForm();
      return;
    }
    if (!plan.id) return;

    const body = formValuesToPostRequisitionBody(
      cardFormik.values,
      billingAddressFormik.values,
      district,
      promotinalCodeFormik?.values
    );

    signAvailablePlan(plan.id, body)
      .then(() => {
        refreshUserInfo();
        toast.success('Processando pagamento');
      })
      .catch((error) => {
        const displayMessage = resolveErrorMessage(error);
        toast.error(displayMessage);
      });
  };

  if (loading) return <LoadingBackdrop loading={loading} />;

  return (
    <>
      <Header title="Assinatura" leftIconClick={() => history.push('/menu')} />
      <Container>
        <StyledPlan>
          <StyledTypography variant="h6">
            <StyledTitle>
              <StyledIcon>
                <LoyaltyIcon />
              </StyledIcon>
              {plan.name}
              <StyledPrice>
                R$ {(Number(plan.priceInCents) / 100).toFixed(2)}
              </StyledPrice>
            </StyledTitle>
          </StyledTypography>
          <StyledTypography variant="subtitle2">
            <StyledPlanDescription>
              <ul>
                <li>Limite de uma Pessoa Sob Cuidado cadastrada;</li>
                <li>Acesso a todas as funcionalidades.</li>
              </ul>
            </StyledPlanDescription>
          </StyledTypography>
          {/* <StyledChangePlan disabled>Trocar plano</StyledChangePlan> */}
        </StyledPlan>

        <StyledLine />

        <StyledTitle variant="h6">Informações de Pagamento</StyledTitle>

        <StyledAccordion>
          <AccordionSummary expandIcon={<StyledExpandMoreIcon />}>
            <StyledTitle>Informações do cartão</StyledTitle>
          </AccordionSummary>

          <StyledLineSimple />

          <Formik
            validateOnChange={false}
            innerRef={(ref) => (formikCardValuesRef.current = ref)}
            initialValues={initialCardValues}
            validationSchema={validationCardSchema}
            onSubmit={submitCardInfo}
          >
            {({ values }) => {
              return (
                <Form noValidate>
                  <Cards
                    cvc={values.cvc}
                    expiry={values.expiry}
                    focused={cardFocus}
                    name={values.name}
                    number={values.cardNumber}
                    locale={{ valid: 'Validade' }}
                    placeholders={{ name: 'Seu nome' }}
                  />

                  <StyledFormikTextField
                    color="secondary"
                    placeholder="Nome exibido no cartão"
                    name="name"
                    label="Nome exibido no cartão"
                    margin="normal"
                    inputProps={{ 'data-testid': 'name' }}
                    onChange={handleCardchange}
                    onFocus={handleCardfocus}
                  />
                  <StyledFormikTextField
                    color="secondary"
                    placeholder="Número do Cartão"
                    name="cardNumber"
                    label="Número do Cartão"
                    type="tel" // numeric keyboard without parsing to number
                    margin="normal"
                    inputProps={{ 'data-testid': 'cardNumber' }}
                    onChange={handleCardchange}
                    onFocus={handleCardfocus}
                  />
                  <RowContainer>
                    <StyledFirstInRow
                      color="secondary"
                      placeholder="Validade"
                      name="expiry"
                      label="Validade"
                      margin="normal"
                      inputProps={{ 'data-testid': 'expiry' }}
                      onChange={handleCardchange}
                      onFocus={handleCardfocus}
                    />
                    <StyledFormikTextField
                      color="secondary"
                      placeholder="CVV"
                      name="cvc"
                      label="CVV"
                      type="tel" // numeric keyboard without parsing to number
                      margin="normal"
                      inputProps={{ 'data-testid': 'cvc' }}
                      onChange={handleCardchange}
                      onFocus={handleCardfocus}
                    />
                  </RowContainer>
                  <StyledFormikTextField
                    color="secondary"
                    placeholder="CPF"
                    name="cpf"
                    label="CPF"
                    type="tel" // numeric keyboard without parsing to number
                    margin="normal"
                    inputProps={{ 'data-testid': 'cpf' }}
                    onChange={handleCardchange}
                  />
                  <Typography variant="subtitle1">
                    Qual o tipo do cartão?
                  </Typography>
                  <RadioGroup
                    aria-label="tipo"
                    defaultValue="credit"
                    row
                    name="cardType"
                  >
                    <FormControlLabel
                      value="credit"
                      control={<Radio />}
                      label="Crédito"
                    />
                    <FormControlLabel
                      value="debt"
                      disabled
                      control={<Radio />}
                      label="Débito"
                    />
                  </RadioGroup>
                </Form>
              );
            }}
          </Formik>
        </StyledAccordion>

        <StyledAccordion>
          <AccordionSummary expandIcon={<StyledExpandMoreIcon />}>
            <StyledTitle>Endereço de cobrança</StyledTitle>
          </AccordionSummary>

          <StyledLineSimple />

          <Formik
            validateOnChange={false}
            innerRef={(ref) => (formikAddressValuesRef.current = ref)}
            initialValues={initialAddressValues}
            validationSchema={validationAddressSchema}
            onSubmit={submitAddressInfo}
          >
            {({ values }) => (
              <Form noValidate>
                <StyledFormikTextField
                  color="secondary"
                  placeholder="CEP"
                  name="postalCode"
                  label="CEP"
                  margin="normal"
                  type="tel"
                  inputProps={{ 'data-testid': 'postalCode' }}
                  value={cepMask(values.postalCode)}
                  onChange={handlePostalCodeChange}
                />
                <RowContainer>
                  <StyledFirstInRow
                    color="secondary"
                    placeholder="País"
                    name="country"
                    label="País"
                    margin="normal"
                    id="country"
                    inputProps={{ 'data-testid': 'country' }}
                  />
                  <StyledFormikTextField
                    color="secondary"
                    placeholder="UF"
                    name="province"
                    label="UF"
                    margin="normal"
                    inputProps={{ 'data-testid': 'province' }}
                  />
                </RowContainer>
                <StyledFormikTextField
                  color="secondary"
                  placeholder="Cidade"
                  name="city"
                  label="Cidade"
                  margin="normal"
                  inputProps={{ 'data-testid': 'city' }}
                />
                <StyledFormikTextField
                  color="secondary"
                  placeholder="Endereço"
                  name="address"
                  label="Endereço"
                  margin="normal"
                  inputProps={{ 'data-testid': 'address' }}
                />
                <RowContainer>
                  <StyledFirstInRow
                    color="secondary"
                    placeholder="Número"
                    name="addressNumber"
                    label="Número"
                    margin="normal"
                    type="tel"
                    inputProps={{ 'data-testid': 'addressNumber' }}
                  />
                  <StyledFormikTextField
                    color="secondary"
                    placeholder="Complemento"
                    name="additional"
                    label="Complemento"
                    margin="normal"
                    inputProps={{ 'data-testid': 'additional' }}
                  />
                </RowContainer>
              </Form>
            )}
          </Formik>
        </StyledAccordion>

        <div>
          {plan?.requirePromotionalCode && (
            <Formik
              innerRef={(ref) => (formikPromotionalCodeRef.current = ref)}
              initialValues={initialValues}
              validationSchema={makeValidationSchema(
                Boolean(plan?.requirePromotionalCode),
                'Por favor, informe o número da carteirinha ou crachá'
              )}
              onSubmit={() => {
                return;
              }}
            >
              {({ values }) => (
                <Form noValidate>
                  <StyledFormikTextField
                    color="secondary"
                    name="promotionalCode"
                    margin="normal"
                    inputProps={{ 'data-testid': 'promotionalCode' }}
                    value={values.promotionalCode}
                    // eslint-disable-next-line
                    onChange={(e: React.ChangeEvent<any>) =>
                      formikPromotionalCodeRef.current?.setFieldValue(
                        e.target.name,
                        numberMask(e.target.value)
                      )
                    }
                    placeholder="obrigatório"
                    label="Número Carteirinha ou Crachá"
                    type="tel"
                  />
                </Form>
              )}
            </Formik>
          )}
        </div>

        <StyledLine />

        <StyledInfo>
          <StyledTitle>Informações da compra</StyledTitle>
          <Typography variant="subtitle2">
            <li>
              Valor:{' '}
              <StyledInfoPrice>
                {' '}
                R${(Number(plan.priceInCents) / 100).toFixed(2)}{' '}
              </StyledInfoPrice>
            </li>
            <li>
              Desconto: <StyledInfoPrice> R$--,-- </StyledInfoPrice>
            </li>
          </Typography>
          <StyledLineSimple />
          <Typography variant="subtitle1">
            Total:{' '}
            <StyledPrice>
              R${(Number(plan.priceInCents) / 100).toFixed(2)}
            </StyledPrice>
          </Typography>
        </StyledInfo>

        <StyledPayButton
          data-testid="payment-submit"
          size="large"
          type="submit"
          onClick={pay}
        >
          Realizar Pagamento
        </StyledPayButton>
      </Container>
    </>
  );
};

export default SubscriptionPayment;
