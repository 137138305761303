import { IconButton, Typography } from '@material-ui/core';
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded';
import styled from 'styled-components';

export const PhotoElement = styled.div`
  display: flex;
  justify-content: center;
`;

export const CenterContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 4fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-grow: 1;
  margin-inline: ${({ theme }) => theme.spacing(1.55)}px;
  max-width: 350px;
  width: 100%;
`;

export const Title = styled(Typography).attrs({
  variant: 'h6',
})`
  text-align: center;
  font-size: 16px;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin: 0;
`;

export const StyledBackIcon = styled(ChevronLeftRounded)`
  font-size: 40px;
`;

export const StyledBlankDiv = styled.div`
  height: 40px;
  width: 40px;
`;
