import { UserModel } from '@cuidador/database';
import AddIcon from '@material-ui/icons/AddRounded';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';
import PatientSearchTextField from '../../../../components/PatientSearchTextField';
import OrganizationMemberSelectCard from '../../../../components/SelectCard/Member';
import StyledButton from '../../../../components/StyledButton';
import useCanAccess from '../../../../hooks/useCanAccess';
import useUser from '../../../../hooks/useUser';
import { Container, StyledFab } from '../styles';
import { handleGetProfilePictureURL } from '../utils';
import FilterChips from './FilterChips';

const OrganizationMembers: React.FC = () => {
  const [page, setPage] = useState(0);
  const [organizationMembers, setOrganizationMembers] = useState<UserModel[]>(
    []
  );
  const [
    organiationMemberIdToProfilePictureURL,
    setOrganiationMemberToProfilePictureURL,
  ] = useState<Record<number, string>>({});
  const [status, setStatus] = useState<UserModel['status'][]>([
    'enabled',
    'awaitingSignupCompletion',
  ]);

  const history = useHistory();
  const {
    getOrganizationMembersPaginated,
    getProfilePicture,
    loading: loadingOrganizationMembers,
    total,
  } = useUser();
  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );
  const { isAllowedToRead: isAllowedToReadOrganizationMembers } = useCanAccess(
    'user/user.by-organization'
  );
  const { isAllowedToCreate } = useCanAccess('user');

  const historyLocationSearch = history.location.search;

  useEffect(() => {
    if (!isAllowedToReadOrganizationMembers) {
      toast.error('Você não tem permissão para visualizar essa página');
      history.push('/cadastros');
    }
  }, []);

  useEffect(() => {
    handleFetchOrganizationMembersPaginated();
  }, [page, status, historyLocationSearch]);

  const handleRedirectToPatientRelatedToUser = (userId: number) => {
    history.push(`/organizacao/usuario/${userId}`);
  };

  const handleFetchOrganizationMembersPaginated = () => {
    const params = qs.parse(historyLocationSearch);
    getOrganizationMembersPaginated({
      ...params,
      page,
      status,
      orderBy: 'name',
      limit: 10,
    })
      .then((response) => {
        const loadedOrganizationMembers = response.data.results;
        const organizationMembersToInsert = loadedOrganizationMembers;
        for (const organizationMember of organizationMembersToInsert) {
          if (!organizationMember.id || !isAllowedToReadProfilePicture) return;
          handleGetProfilePictureURL(
            organizationMember.id,
            getProfilePicture
          ).then((url) => {
            if (!url) return;
            setOrganiationMemberToProfilePictureURL((previousMap) => {
              if (!organizationMember.id) return { ...previousMap };
              const newMap = { ...previousMap };
              newMap[organizationMember.id] = url;
              return newMap;
            });
          });
        }

        if (page === 0) {
          setOrganizationMembers(organizationMembersToInsert);
        } else {
          setOrganizationMembers([
            ...organizationMembers,
            ...organizationMembersToInsert,
          ]);
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const onChangeParams = () => {
    if (page !== 0) {
      setPage(0);
    }
  };

  if (loadingOrganizationMembers && organizationMembers.length === 0) {
    return <LoadingBackdrop loading={loadingOrganizationMembers} />;
  }

  return (
    <>
      <PatientSearchTextField
        fieldName="user.name"
        onChangeDebounced={onChangeParams}
      />
      <FilterChips setStatus={setStatus} status={status} />
      <Container>
        {organizationMembers.map(({ id, name, status, role }) => {
          if (!id) return null;
          return (
            <OrganizationMemberSelectCard
              key={Number(id)}
              id={Number(id)}
              name={String(name)}
              profilePictureURL={
                organiationMemberIdToProfilePictureURL[Number(id)]
              }
              status={String(status)}
              roleTitle={String(role?.title)}
              onRelationClick={() => handleRedirectToPatientRelatedToUser(id)}
              onRowClick={() => history.push(`/pessoas/usuarios/${id}`)}
            />
          );
        })}
        {loadingOrganizationMembers && (
          <LoadingBackdrop loading={loadingOrganizationMembers} />
        )}
        {organizationMembers.length < total && (
          <StyledButton
            data-testid="show-more"
            size="medium"
            color="secondary"
            onClick={pageIncrement}
          >
            Ver mais
          </StyledButton>
        )}
      </Container>
      {isAllowedToCreate && (
        <StyledFab onClick={() => history.push('/usuarios/novo')}>
          <AddIcon />
        </StyledFab>
      )}
    </>
  );
};

export default OrganizationMembers;
