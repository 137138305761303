import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../components/Headers/Header';
import RoutinesUpsertForm, {
  FormValues,
} from '../../../components/RoutinesUpsertForm';
import {
  formDataToRoutineModel,
  routineModelToFormValues,
} from '../../../components/RoutinesUpsertForm/utils';
import { StyledFieldset } from '../../../components/StyledFieldset';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useRoutine from '../../../hooks/useRoutine';
import { resolveErrorMessage } from '../../../utils/error';
import { Backdrop, BackdropCircularProgress } from '../styles';
import { Container } from './styles';

const RoutinesUpdate: React.FC = () => {
  const [dialogStatus, setDialogStatus] = useState(false);
  const params = useParams<{ id: string }>();
  const routineId = parseInt(params.id);
  const history = useHistory();
  const { isAllowedToDelete, isAllowedToUpdate } = useCanAccess(
    'care/event/schedule'
  );

  const { byId, getById, remove, patch, loading } = useRoutine();
  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const routineData = routineId != null ? byId[routineId] : null;

  useEffect(
    function fetchRoutine() {
      if (routineId) {
        getById(routineId);
      }
    },
    [routineId]
  );

  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;

  const handleSubmit = (values: FormValues) => {
    const patchData = formDataToRoutineModel(values, patientId!);
    return patch(routineId!, patchData)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleDelete = (id: Id) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      {loading && (
        <Backdrop open={loading}>
          {loading && (
            <BackdropCircularProgress data-testid="table-backdrop-spinner" />
          )}
        </Backdrop>
      )}
      <Header
        title="Editar Rotina"
        rightButtonType={isAllowedToDelete ? 'remove' : undefined}
        rightIconClick={() => setDialogStatus(true)}
      />
      {routineData && (
        <Container>
          <StyledFieldset disabled={!isAllowedToUpdate}>
            <RoutinesUpsertForm
              innerRef={(ref) => (formikRef.current = ref)}
              initialValues={routineModelToFormValues(routineData)}
              onSubmit={handleSubmit}
              disabled={!isAllowedToUpdate}
            />
          </StyledFieldset>
          <StyledSimpleDialog
            open={dialogStatus}
            handleNo={() => setDialogStatus(false)}
            handleYes={() => handleDelete(routineId!)}
            title="Excluir Rotina"
            subTitle="Tem certeza que deseja excluir a Rotina?"
          />
        </Container>
      )}
    </>
  );
};

export default RoutinesUpdate;
