import { ShiftAnnullationModel, ShiftExecutionModel } from '@cuidador/database';
import { addDays, endOfMonth, isBefore } from 'date-fns';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AnnullableShiftExecutionModal from '../../components/AnnullableShiftExecutionModal';
import DropdownMenu from '../../components/DropdownMenu';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import {
  isAnnullableShiftExecution,
  isAnnulledShiftExecution,
} from '../../components/ShiftsHistory/utils';
import useCanAccess from '../../hooks/useCanAccess';
import useShift from '../../hooks/useShift';
import ExecutionTime from './ExecutionTime';
import ShiftExecutionTimeline from './ExecutionTimeline';
import ExecutionTitle from './ExecutionTitle';
import ExecutionUserName from './ExecutionUserName';
import {
  Container,
  StyledExecutionCard,
  StyledExecutionContainer,
  StyledHorizontalLine,
} from './styles';

interface RouteParams {
  id: string;
}

export interface ExecutionTimelineSlot {
  status: 'occupied' | 'in_another_shift';
  startsAtPercentage: number;
  durationPercentage: number;
}

export type ExecutionTimeline = ExecutionTimelineSlot[];

const ShiftExecution: React.FC = () => {
  const {
    getById,
    loading,
    ids,
    byId,
    patchAnnulledShiftExecution,
    getTimeline,
  } = useShift();
  const [executionTimeline, setExecutionTimeline] = useState<
    ExecutionTimeline | undefined
  >(undefined);
  const { id } = useParams<RouteParams>();
  const history = useHistory();

  useEffect(() => {
    getById(id);
    getTimeline(Number(id)).then((data) => {
      setExecutionTimeline(data);
    });
  }, [id]);

  const shiftExecutions = ids.map((id) => byId[id].executions).flat(1);

  useEffect(() => {
    getTimeline(Number(id)).then((data) => {
      setExecutionTimeline(data);
    });
  }, [byId]);

  const [
    isAnnullableShiftModalVisible,
    setIsAnnullableShiftModalVisible,
  ] = useState(false);

  const [
    annullableModalExecution,
    setAnullableModalExecution,
  ] = useState<ShiftExecutionModel>({});

  const openAnnulableModal = (execution: ShiftExecutionModel) => {
    setAnullableModalExecution(execution);
    setIsAnnullableShiftModalVisible(true);
  };

  const closeAnnulableModal = () => {
    setAnullableModalExecution({});
    setIsAnnullableShiftModalVisible(false);
  };

  const onSubmitAnnullShiftExecution = async (
    executionId: number,
    data: Partial<ShiftAnnullationModel>
  ): Promise<void> => {
    await patchAnnulledShiftExecution(executionId, data);
    await getById(id);
  };

  const handleRedirectToExecutionHistoryContestation = (
    shiftId: number,
    executionId: number
  ) => {
    history.push(
      `/correcao-de-horarios/historico/plantao/${shiftId}/execucao/${executionId}`
    );
  };

  const { isAllowedToUpdate: isAllowedToAnullShift } = useCanAccess(
    'care/shift/annullation'
  );

  const buildMenuItems = (execution: ShiftExecutionModel) => {
    const menuItems = [
      {
        label: 'Corrigir horários',
        handleClickItem: () =>
          history.push(
            `/correcao-de-horarios/plantao/${execution.shiftId}/execucao/${execution.id}/novo`
          ),
        shouldRender:
          !execution.isAnnulled &&
          !!execution.createdAt &&
          isBefore(
            new Date(),
            addDays(endOfMonth(new Date(execution.createdAt)), 1)
          ),
      },
      {
        label: 'Anular',
        handleClickItem: () => openAnnulableModal(execution),
        shouldRender:
          isAllowedToAnullShift && isAnnullableShiftExecution(execution),
      },
      {
        label: 'Restaurar',
        handleClickItem: () => openAnnulableModal(execution),
        shouldRender:
          isAllowedToAnullShift && isAnnulledShiftExecution(execution),
      },
      {
        label: 'Histórico',
        handleClickItem: () =>
          handleRedirectToExecutionHistoryContestation(
            Number(id),
            Number(execution.id)
          ),
        shouldRender: true,
      },
    ];
    return menuItems.filter(({ shouldRender }) => shouldRender);
  };

  return (
    <>
      <LoadingBackdrop loading={loading} />
      <Header
        title="Correção de horários"
        leftIconClick={() => history.push('/acompanhar/historico/')}
      />
      <Container>
        <ShiftExecutionTimeline shift={byId[id]} timeline={executionTimeline} />
        {sortBy(shiftExecutions, (item) => item?.startedAt).map(
          (execution, index) => (
            <>
              {index !== 0 && <StyledHorizontalLine />}
              <StyledExecutionCard key={index}>
                <div>
                  <ExecutionTitle
                    executionNumber={index + 1}
                    timeContestations={execution?.timeContestations}
                  />
                  <StyledExecutionContainer
                    key={index}
                    data-testid={`execution-info-${execution?.id}`}
                    strikethrough={!!execution?.isAnnulled}
                  >
                    <ExecutionTime execution={execution!} />
                    <ExecutionUserName
                      executionUserName={String(
                        execution?.caregiver?.user?.name
                      )}
                    />
                  </StyledExecutionContainer>
                </div>
                <DropdownMenu
                  menuId={execution?.id}
                  menuItems={buildMenuItems(execution!)}
                />
              </StyledExecutionCard>
            </>
          )
        )}
        <AnnullableShiftExecutionModal
          opened={isAnnullableShiftModalVisible}
          onClose={closeAnnulableModal}
          execution={annullableModalExecution}
          onSubmit={onSubmitAnnullShiftExecution}
        />
      </Container>
    </>
  );
};

export default ShiftExecution;
