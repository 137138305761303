import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CardItem from '../../components/CardItem';
import Fab from '../../components/Fab';
import Header from '../../components/Headers/Header';
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import useCaregiver from '../../hooks/useCaregiver';
import {
  Backdrop,
  BackdropCircularProgress,
  Container,
  StyledCardContainer,
  StyledTypography,
  TypographyContainer,
} from './styles';

const Caregiver: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const patientId = userInfo?.activePatientId;
  const history = useHistory();

  const { isAllowedToInvoke: isAllowedToRelateUser } = useCanAccess(
    'user/relate-to-patient'
  );
  const { isAllowedToCreate } = useCanAccess('user');

  const {
    getAllByPatientId,
    byId,
    ids: caregiversIds,
    loading,
  } = useCaregiver();

  useEffect(() => {
    if (!patientId) return;
    getAllByPatientId(patientId);
  }, [patientId]);

  return (
    <>
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
      <Header
        title="Rede de cuidado"
        rightButtonType="pscList"
        leftIconClick={() => history.push('/cadastros')}
      />
      <PendingRegistrationBanner />
      <Container>
        <TypographyContainer>
          <StyledTypography variant="subtitle1">
            Aqui você pode incluir ou excluir pessoas da rede de cuidados.
          </StyledTypography>
          <StyledTypography variant="subtitle1">
            Para inclusão, clique no botão (+).
          </StyledTypography>
          <StyledTypography variant="subtitle1">
            Para exclusão, clique na pessoa que gostaria de excluir e depois no
            ícone Lixeira no canto superior direito. Importante manter essa
            lista atualizada para que pessoas não autorizadas não tenham acesso
            aos dados.
          </StyledTypography>
        </TypographyContainer>

        {caregiversIds.map((id) => (
          <StyledCardContainer key={id}>
            <CardItem caregiver={byId[id]} id={id} />
          </StyledCardContainer>
        ))}
      </Container>
      {(isAllowedToCreate || isAllowedToRelateUser) && (
        <Fab to="/usuarios/novo?relatePatient=true">
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export default Caregiver;
