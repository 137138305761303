import { Button, Drawer } from '@material-ui/core';
import { AccessTime, ChevronLeft } from '@material-ui/icons';
import MessageIcon from '@material-ui/icons/Message';
import { KeyboardDatePicker } from '@material-ui/pickers';
import styled, { css } from 'styled-components';
import FilterChip from '../../../../components/FilterChip';
import { PureParagraph } from '../styles';

export const StyledDrawer = styled(Drawer)`
  display: flex;

  .MuiDrawer-paper {
    max-width: 300px;
    width: 100%;
  }
`;

export const DrawerHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 72px;
`;

export const DrawerHeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const DrawerBody = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 1)};
`;

export const StyledBackIcon = styled(ChevronLeft)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledFilterIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-left: auto;
`;

export const HeaderTitle = styled(PureParagraph)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const DrawerSection = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const SectionHeader = styled(PureParagraph)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  text-align: right;
  width: 100%;
  border: 0px;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledDatePicker = styled(KeyboardDatePicker)`
  margin: ${({ theme }) => theme.spacing(1, 0, 0)};
`;

export const StyledButton = styled.button<{ isActive?: boolean }>`
  ${({ theme, isActive }) =>
    isActive
      ? css`
          border: 1.5px solid ${theme.palette.primary.main};
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.common.white};
        `
      : css`
          border: 1.5px solid ${theme.palette.primary.main};
          color: ${theme.palette.primary.main};
          background-color: ${theme.palette.common.white};
        `}

  border-radius: ${({ theme }) => theme.spacing(0.75)}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  width: 135px;
`;

export const CommentIcon = styled(MessageIcon)`
  height: 16px;
  width: 16px;
  margin-right: ${({ theme }) => theme.spacing(0.25)}px;
`;

export const ClockIcon = styled(AccessTime)`
  height: 16px;
  width: 16px;
  margin-right: ${({ theme }) => theme.spacing(0.25)}px;
`;

export const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
  flex-wrap: wrap;
  justify-content: end;
`;

export const AccomplishedFilter = styled(FilterChip)`
  background-color: ${({ theme }) => theme.palette.success.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.success.main};
  }
`;

export const NotAccomplishedFilter = styled(FilterChip)`
  background-color: ${({ theme }) => theme.palette.extra.color.red.light};

  &:hover {
    background-color: ${({ theme }) => theme.palette.extra.color.red.light};
  }
`;

export const AwaitingFilter = styled(FilterChip)`
  background-color: ${({ theme }) => theme.palette.grey[600]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[600]};
  }
`;

export const ResetFiltersButton = styled(Button)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;
