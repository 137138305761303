import { useTheme } from '@material-ui/core';
import React from 'react';

const PscIcon: React.FC = () => {
  const theme = useTheme();
  const pscIconColor = theme.palette.primary.main;

  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={pscIconColor}
    >
      <path
        d="M16.3582 18.752L15.7182 13.628C15.6275 12.9022 15.2748 12.2346 14.7264 11.7506C14.178 11.2667 13.4717 10.9997 12.7402 11H10.5362C9.80517 11.0002 9.09932 11.2674 8.55133 11.7513C8.00333 12.2352 7.6509 12.9026 7.56024 13.628L6.91924 18.752C6.88407 19.0335 6.90918 19.3193 6.99292 19.5903C7.07666 19.8614 7.21712 20.1116 7.40495 20.3242C7.59279 20.5368 7.8237 20.707 8.08237 20.8235C8.34104 20.94 8.62154 21.0002 8.90524 21H14.3732C14.6569 21.0001 14.9372 20.9398 15.1958 20.8232C15.4543 20.7066 15.6851 20.5364 15.8729 20.3238C16.0606 20.1112 16.201 19.8611 16.2846 19.5901C16.3683 19.3191 16.3934 19.0334 16.3582 18.752V18.752Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6392 7C13.296 7 14.6392 5.65685 14.6392 4C14.6392 2.34315 13.296 1 11.6392 1C9.98231 1 8.63916 2.34315 8.63916 4C8.63916 5.65685 9.98231 7 11.6392 7Z"
        strokeWidth="2"
      />
      <path
        d="M3.63916 10C4.74373 10 5.63916 9.10457 5.63916 8C5.63916 6.89543 4.74373 6 3.63916 6C2.53459 6 1.63916 6.89543 1.63916 8C1.63916 9.10457 2.53459 10 3.63916 10Z"
        strokeWidth="2"
      />
      <path
        d="M19.6392 10C20.7437 10 21.6392 9.10457 21.6392 8C21.6392 6.89543 20.7437 6 19.6392 6C18.5346 6 17.6392 6.89543 17.6392 8C17.6392 9.10457 18.5346 10 19.6392 10Z"
        strokeWidth="2"
      />
      <path
        d="M19.6392 13H19.9452C20.4187 12.9999 20.8768 13.1678 21.2381 13.4738C21.5993 13.7798 21.8404 14.204 21.9182 14.671L22.2512 16.671C22.299 16.9575 22.2838 17.2511 22.2067 17.5311C22.1295 17.8112 21.9923 18.0711 21.8045 18.2928C21.6167 18.5144 21.3829 18.6925 21.1194 18.8146C20.8558 18.9368 20.5687 19 20.2782 19H16.6392M3.63925 13H3.33325C2.85981 12.9999 2.4017 13.1678 2.04042 13.4738C1.67914 13.7798 1.43813 14.204 1.36025 14.671L1.02725 16.671C0.979458 16.9575 0.994667 17.2511 1.07181 17.5311C1.14896 17.8112 1.2862 18.0711 1.47397 18.2928C1.66175 18.5144 1.89556 18.6925 2.15914 18.8146C2.42272 18.9368 2.70974 19 3.00025 19H6.63925L3.63925 13Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PscIcon;
