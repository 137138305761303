import { Patient } from '@cuidador/database';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  CloudDownload,
  LoadingBackdrop,
  LoadingCircular,
  PictureAsPdf,
  RoundButton,
} from './styles';

interface ReportButtonProps {
  createReport: (patientId: Id) => Promise<{ reportId?: number }>;
  getReportDownloadUrl: (
    patientId: Id,
    reportId: Id
  ) => Promise<{ signedUrl: string }>;
  patient?: Patient;
}

type ButtonState = 'export' | 'loading' | 'download';

export const ReportButton: React.FC<ReportButtonProps> = ({
  createReport,
  getReportDownloadUrl,
  patient,
}) => {
  const [buttonState, setButtonState] = useState<ButtonState>('export');
  const [reportId, setReportId] = useState<number>();
  const [downloadUrl, setDownloadUrl] = useState<string>();

  const filename = useMemo(
    () =>
      `Relatório de Cuidados - ${patient?.name} - ${format(
        new Date(),
        'yyyy-MM-yy_HH:mm'
      )}`,
    [patient, buttonState]
  );

  const onClick = useCallback(async () => {
    if (!patient?.id) return;

    if (buttonState === 'export') {
      setButtonState('loading');
      try {
        const { reportId } = await createReport(patient.id);
        if (!reportId) throw new Error();
        setReportId(reportId);
        const { signedUrl } = await getReportDownloadUrl(patient.id, reportId);
        const blob = await (await fetch(signedUrl)).blob();
        setDownloadUrl(URL.createObjectURL(blob));
        setButtonState('download');
      } catch (e) {
        toast.error(
          'Houve um erro ao tentar gerar o relatório, tente novamente mais tarde ou contate um administrador do sistema.'
        );
        setButtonState('export');
        console.error(e);
      }
      return;
    }

    if (buttonState === 'download' && !!reportId) {
      setButtonState('export');
      setDownloadUrl(undefined);
      return;
    }
  }, [buttonState, reportId, setButtonState, setDownloadUrl]);

  const resolveBackground = useCallback(() => {
    if (buttonState === 'download') return 'green';
    return 'dark';
  }, [buttonState]);

  return (
    <>
      {buttonState === 'loading' && (
        <LoadingBackdrop
          open={true}
          data-testid="care-history-report-loading-backdrop"
        />
      )}
      <RoundButton
        variant="extended"
        background={resolveBackground()}
        onClick={onClick}
        data-testid="care-history-report-button"
      >
        {/* Icon */}
        {buttonState === 'export' && <PictureAsPdf />}
        {buttonState === 'loading' && <LoadingCircular />}
        {buttonState === 'download' && !downloadUrl && <LoadingCircular />}
        {buttonState === 'download' && !!downloadUrl && <CloudDownload />}

        {/* Text/link */}
        {buttonState === 'export' && 'Exportar'}
        {buttonState === 'download' && !!downloadUrl && (
          <a
            href={downloadUrl}
            download={filename}
            target="_blank"
            rel="noreferrer"
            data-testid="care-history-report-download-link"
          >
            Baixar
          </a>
        )}
      </RoundButton>
    </>
  );
};
