import { EventScheduleModel, MedicationModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import { format, subHours } from 'date-fns';
import React from 'react';
import { getLocalWeeklySchedule, weekdayMap } from '../../utils/date';
import { administeredNames } from '../MedicationInsertForm/MedicationSecondStep/utils';

export function getSelectedWeekdaysText(medication: MedicationModel) {
  if (!medication.eventSchedule || !medication.eventSchedule.frequencyStartsAt)
    return '';

  const { mon, tue, wed, thu, fri, sat, sun } = medication.eventSchedule || {};
  const utcSchedule = { mon, tue, wed, thu, fri, sat, sun };
  const date = new Date(medication.eventSchedule.frequencyStartsAt);
  const utcTime = `${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
  const localSchedule = getLocalWeeklySchedule(utcSchedule, utcTime);

  const selectedWeekDays: string[] = [];
  if (localSchedule.mon) selectedWeekDays.push(weekdayMap.mon);
  if (localSchedule.tue) selectedWeekDays.push(weekdayMap.tue);
  if (localSchedule.wed) selectedWeekDays.push(weekdayMap.wed);
  if (localSchedule.thu) selectedWeekDays.push(weekdayMap.thu);
  if (localSchedule.fri) selectedWeekDays.push(weekdayMap.fri);
  if (localSchedule.sat) selectedWeekDays.push(weekdayMap.sat);
  if (localSchedule.sun) selectedWeekDays.push(weekdayMap.sun);

  if (selectedWeekDays.length === 7) return 'todos os dias,';

  const formattingSelectedWeekDays = selectedWeekDays.reduce(
    (accumulatorString, weekday, index) =>
      `${accumulatorString}${index > 0 ? ' - ' : ''}${weekday.toLowerCase()}`,
    ''
  );

  return `${formattingSelectedWeekDays},`;
}

export function getMedicationIntervalText(medication: MedicationModel) {
  const { eventSchedule } = medication;

  if (!eventSchedule) return null;

  const startDate = eventSchedule.frequencyStartsAt;
  const endDate = eventSchedule.frequencyEndsAt;

  if (startDate && endDate) {
    const formatedStartDate = format(new Date(startDate), 'dd/MM/yyyy');
    const formatedEndDate = format(new Date(endDate), 'dd/MM/yyyy');
    return (
      <p>
        <strong>Início:</strong> {formatedStartDate}
        <br /> <strong>Fim:</strong> {formatedEndDate}
      </p>
    );
  } else if (startDate) {
    const formatedStartDate = format(new Date(startDate), 'dd/MM/yyyy');
    return (
      <p>
        <strong>Início:</strong> {formatedStartDate}
      </p>
    );
  }
}

export function getMedicationEndDateText(medication: MedicationModel) {
  if (!medication.eventSchedule?.frequencyEndsAt) return 'continuamente';

  const endDate = format(
    new Date(medication.eventSchedule.frequencyEndsAt),
    'dd/MM/yyyy'
  );
  return `até ${endDate}`;
}

export const renderDoseTimes = (medication: MedicationModel) => {
  const { eventSchedule } = medication;
  if (!eventSchedule) return null;

  if (eventSchedule.customTimes && eventSchedule.customTimes.length > 0) {
    return renderCustomTimes(eventSchedule);
  } else {
    return renderTimes(eventSchedule!);
  }
};

const renderCustomTimes = (eventSchedule: EventScheduleModel) => {
  const times = eventSchedule?.customTimes?.map((time) => {
    const now = new Date();
    const hours = Number(time.happensAt!.split(':')[0]);
    const minutes = Number(time.happensAt!.split(':')[1]);
    now.setHours(hours);
    now.setMinutes(minutes);
    return format(subHours(now, 3), 'HH:mm');
  });
  return renderTimesBox(times);
};

const renderTimes = (eventSchedule: EventScheduleModel) => {
  if (!eventSchedule?.frequencyRule || !eventSchedule.frequencyStartsAt)
    return null;

  const startTime = format(new Date(eventSchedule.frequencyStartsAt), 'HH:mm');
  const doseTimes = handleDoses(startTime, eventSchedule.frequencyRule);

  return renderTimesBox(doseTimes);
};

export const handleDoses = (startTime: string, frequency: string) => {
  const formattedFrequency = Number(frequency.replace('h', ''));
  const [hour, minute] = startTime.split(':');
  const formattedHour = Number(hour);
  const doses: number[] = [];
  const firstMedicationTime = formattedHour % formattedFrequency;
  for (
    let medicationTime = firstMedicationTime;
    medicationTime <= 23;
    medicationTime += formattedFrequency
  ) {
    doses.push(medicationTime);
  }

  const formattedDoses = doses.map((item) => {
    return item.toString().padStart(2, '0') + ':' + minute;
  });

  return formattedDoses;
};

const renderTimesBox = (times?: string[]) => {
  return (
    <>
      {times?.map((time, index) => (
        <Typography key={index} variant="body2">
          <strong>{index + 1}ª medicação</strong>: {time}
        </Typography>
      ))}
    </>
  );
};

export function renderAdministration(medication: MedicationModel) {
  const days = resolveDays(medication);

  const administeredBy = `via ${administeredNames
    .find((elem) => elem.value === medication.administeredBy)
    ?.name.toLowerCase()}`;

  const dosage = resolveDosage(
    medication.dosageQuantity,
    medication.dosageFormat
  );

  return `${days} ${dosage} ${administeredBy}`;
}

const resolveDays = (medication: MedicationModel) => {
  if (medication.ifNecessary) {
    return 'se necessário,';
  } else if (medication.eventSchedule?.frequencyRule === '1d') {
    return getSelectedWeekdaysText(medication);
  } else {
    return `a cada ${medication?.eventSchedule?.frequencyRule?.slice(
      0,
      -1
    )} dias,`;
  }
};

const resolveDosage = (
  dosageQuantity?: number,
  dosageFormat?: MedicationModel['dosageFormat']
) => {
  let grammaticallyFormattedDosage;
  switch (dosageFormat) {
    case 'pill':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'comprimido' : 'comprimidos';
      break;
    case 'capsule':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'cápsula' : 'cápsulas';
      break;
    case 'sachet':
      grammaticallyFormattedDosage = dosageQuantity === 1 ? 'sachê' : 'sachês';
      break;
    case 'drops':
      grammaticallyFormattedDosage =
        Number(dosageQuantity) <= 1 ? 'gota' : 'gotas';
      break;
    case 'ampoule':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'ampola' : 'ampolas';
      break;
    case 'suppository':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'supositório' : 'supositórios';
      break;
    case 'inhaled':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'jato/puff' : 'jatos/puffs';
      break;
    case 'unit':
      grammaticallyFormattedDosage =
        dosageQuantity === 1
          ? 'unidade internacional'
          : 'unidades internacionais';
      break;
    case 'ml':
      grammaticallyFormattedDosage = 'ml';
      break;
    case 'spoon':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'colher' : 'colheres';
      break;
    case 'grams':
      grammaticallyFormattedDosage = dosageQuantity === 1 ? 'grama' : 'gramas';
      break;
    case 'strip':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'placa/tira/fita' : 'placas/tiras/fitas';
      break;
    case 'sticker':
      grammaticallyFormattedDosage =
        dosageQuantity === 1 ? 'adesivo' : 'adesivos';
      break;
  }

  return `${dosageQuantity} ${grammaticallyFormattedDosage},`;
};
