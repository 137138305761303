import type { MedicationModel } from '@cuidador/database';
import axios from 'axios';
import { useCallback, useContext, useState } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { AuthContext } from '../contexts/auth';
import * as useFarmeModule from './useFarme';

interface PostFarmeRequestBody {
  name: string;
  email: string;
  phone: string;
  medicines: string;
  lp_name: string;
  utm_source: string;
}

const useFarme = () => {
  const { userInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const createLead = useCallback(
    async (medications: MedicationModel[]) => {
      try {
        if (
          !userInfo?.user?.name ||
          !userInfo?.user?.email ||
          !userInfo?.user?.phoneNumber
        ) {
          toast.error(
            'Não foi possível solicitar o orçamento dos medicamentos, tente mais tarde.'
          );
          throw new Error('Error on farme request handler');
        }

        if (!medications || medications?.length === 0) {
          toast.error(
            'É necessário ao menos um medicamento em uso para solicitar o orçamento.'
          );
          throw new Error('Error on farme request handler');
        }

        const medicines = medications
          .map((medication) => {
            return `${
              medication.subCategory?.medicationCatalogItem?.name ||
              medication.subCategory?.name
            } ${medication.dosageQuantity} ${medication.dosageFormat}`;
          })
          .join(' ||| ');

        setLoading(true);
        await useFarmeModule
          .postFarmeRequest({
            name: userInfo.user.name,
            email: userInfo.user.email,
            phone: sanitizePhoneNumber(userInfo?.user?.phoneNumber),
            medicines,
            lp_name: 'cuidador-de-confianca',
            utm_source: 'cuidador-de-confianca',
          })
          .finally(() => {
            setLoading(false);
          });

        toast.success('Orçamento solicitado.');
      } catch (err) {
        if (err instanceof Error && err.message === 'Network Error') {
          toast.error('Você está sem internet no momento, tente mais tarde.');
        } else {
          toast.error(
            'Não foi possível solicitar o orçamento dos medicamentos, tente mais tarde.'
          );
          Sentry.captureException(new Error('Error on Far.me request'), {
            extra: {
              error: err,
            },
          });
        }

        throw err;
      }
    },
    [userInfo?.user]
  );

  const sanitizePhoneNumber = (rawPhoneNumber: string): string => {
    let numericOnlyPhone = rawPhoneNumber.replaceAll(/\D/g, '');

    if (numericOnlyPhone.startsWith('55')) {
      // Removes 55 code if exists
      numericOnlyPhone = numericOnlyPhone.substring(2);
    }

    // Picks trailing 11 digits
    numericOnlyPhone = numericOnlyPhone.substring(numericOnlyPhone.length - 11);

    return numericOnlyPhone;
  };

  return {
    createLead,
    loading,
  };
};

export async function postFarmeRequest(
  data: PostFarmeRequestBody
): Promise<void> {
  await axios.post(`${process.env.REACT_APP_FARME_API_URL}/lps/leads`, data, {
    headers: { 'Content-Type': 'application/json' },
  });
}

export default useFarme;
