import styled, { css } from 'styled-components';
import { Avatar, IconButton, Typography } from '@material-ui/core';
import { StatusColor } from './utils';

interface StyledTypographyProps {
  statusColor: string;
}

interface StatusLabelProps {
  statusLabel: string;
  bold?: boolean;
}

export const CardBox = styled.div<{ open: boolean }>`
  padding: ${({ theme }) => theme.spacing(2, 1, 1)};
  /* 25 is the hexadecimal opacity level */
  border-bottom: 2px solid ${({ theme }) => theme.palette.secondary.main}25;
  transition: 0.5s all ease-in-out;
  overflow: hidden;

  ${({ open }) =>
    open
      ? css`
          max-height: 2000px;
          background-color: ${({ theme }) =>
            theme.palette.extra.color.grey.light};
        `
      : css`
          max-height: 70.8px;
          background-color: ${({ theme }) => theme.palette.common.white};
        `}
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClampedText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: clamp(0.95rem, 75%, 2rem);
`;

export const UserInfo = styled(ClampedText)<StatusLabelProps>`
  color: ${(props) =>
    props.statusLabel === 'INATIVA'
      ? ({ theme }) => theme.palette.extra.color.grey.main
      : ({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const PatientName = styled(UserInfo)<StatusLabelProps>`
  font-weight: 600;
`;

export const RoleTitle = styled(Typography)<StatusLabelProps>`
  color: ${(props) =>
    props.statusLabel === 'INATIVA'
      ? ({ theme }) => theme.palette.extra.color.grey.main
      : ({ theme }) => theme.palette.extra.color.grey.dark};
  font-size: clamp(0.85rem, 75%, 2rem);
  display: -webkit-box;
  max-width: 350px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const RoleTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PatientOverview = styled.div`
  display: flex;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledIconBackground = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 10px;
  box-shadow: 0 4px 5px 0.5px
    ${({ theme }) => theme.palette.extra.color.grey.main};
  height: 40px;
  width: 40px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const StyledTypography = styled(Typography)<StyledTypographyProps>`
  color: ${(props) => props.statusColor};
  font-size: clamp(0.8rem, 75%, 2rem);
  text-align: right;
  font-weight: 500;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1.5)}px;
  width: 75px;
`;

export const BorderedAvatar = styled(Avatar)<{
  borderColor: StatusColor;
}>`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 4px solid
    ${({ borderColor }) => {
      switch (borderColor) {
        case 'red':
          return '#f54141';
        case 'yellow':
          return '#fdb62e';
        case 'green':
          return '#1cbc86';
        case 'gray':
          return '#999999';
      }
    }};
`;

export const StatusIndicator = styled(ClampedText)<{ badgeColor: StatusColor }>`
  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    background-color: ${({ badgeColor }) => {
      switch (badgeColor) {
        case 'red':
          return '#f54141';
        case 'yellow':
          return '#fdb62e';
        case 'green':
          return '#1cbc86';
        case 'gray':
          return '#999999';
      }
    }};
  }
`;
